import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Badge,
  Collapse,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminRecord from '../../components/AdminRecord';
import AdminTabs from '../../components/AdminTabs';
import UserProfileTemplate from '../../components/templates/UserProfile';
import { DotWithLabel, IdentityIcon } from '../../utils/icons';
import { trimText } from '../../utils/shared';

const useStyles = makeStyles((theme: Theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const getTrait = (field: string, traits: any) => {
  if (Array.isArray(traits)) {
    for (const trait of traits) {
      if (trait.field === field) {
        return trait.value;
      }
    }
  }
  return '';
};

const getTraits = (field: string, traits: any) => {
  const matchingTraits: any = [];
  if (Array.isArray(traits)) {
    for (const trait of traits) {
      if (trait.field === field) {
        matchingTraits.push(trait);
      }
    }
  }
  return matchingTraits;
};

const getDevices = (devices: any) => {
  const values: any = [];
  for (const device of devices) {
    values.push({
      value: `${device.device.components.useragent.os.family} ${device.device.components.useragent.browser.family} ${device.device.components.clientIp}`,
      user: device.user,
    });
  }
  return values;
};

const getCookies = (users: any) => {
  const values: any = [];
  for (const user of users) {
    values.push({
      value: trimText(user.id),
      user: user.id,
    });
  }
  return values;
};

const UserIdentityDetail = () => {
  const classes = useStyles();
  const { config, adminUserApi } = useBlueprintAdmin();
  const { id } = useParams<{ id: string }>();
  const [identity, setIdentity] = useState<any>();
  const [tabs, setTabs] = useState<Array<any>>([]);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const headerCard = (
    isExpanded,
    priority,
    icon,
    title,
    collapsed,
    expanded
  ) => (
    <Grid xs={3} container alignContent='flex-start'>
      <Grid justifyContent='flex-start' container>
        <Grid item style={{ paddingTop: 5 }}>
          <Badge
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            badgeContent={priority}
            color='primary'
          >
            {icon}
          </Badge>
        </Grid>
        <Grid item>
          <Typography variant='h6'>&nbsp;{title}</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <Collapse in={!isExpanded} timeout={100} unmountOnExit>
          <Grid xs={12} item>
            {collapsed}
          </Grid>
        </Collapse>
        <Collapse in={isExpanded} timeout={200} unmountOnExit>
          <Grid xs={12} item>
            {Array.isArray(expanded) && (
              <>
                {expanded.map((trait, index) => (
                  <Grid xs={12} item key={`${trait.user}-${index}`}>
                    {DotWithLabel(trait.user, trait.value)}
                  </Grid>
                ))}
              </>
            )}
            {!Array.isArray(expanded) && <>{expanded}</>}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );

  const deviceHeaderTemplate = (expanded, devices) => (
    <>
      {headerCard(
        expanded,
        4,
        <IdentityIcon type='fingerprint' fontSize='medium' />,
        'device',
        `${devices.length} fingerprints`,
        getDevices(devices)
      )}
    </>
  );

  const headerTemplate = (title, identity, traits) => (
    <>
      {identity && traits && (
        <AdminCard
          title={title}
          // color="rgb(200, 250, 205)"
          customAction={
            <IconButton
              className={expanded ? classes.expandOpen : classes.expand}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <Grid container>
            {headerCard(
              expanded,
              1,
              <IdentityIcon type='id' fontSize='medium' />,
              identity.idKey,
              identity.idValue,
              <>
                <Grid xs={12} item>
                  {identity.idValue}
                  <p>
                    Master customer record <br />
                    from Demo POS system
                  </p>
                </Grid>
              </>
            )}

            {headerCard(
              expanded,
              2,
              <IdentityIcon type='email' fontSize='medium' />,
              'email',
              getTrait('email', traits),
              getTraits('email', traits)
            )}

            {headerCard(
              expanded,
              3,
              <IdentityIcon type='cookie' fontSize='medium' />,
              'cookie',
              `${identity.users.length} Cookies`,
              getCookies(identity.users)
            )}

            <AdminRecord
              loadingTemplate={
                <Skeleton variant='rectangular' width={225} height={55} />
              }
              form={true}
              url={`/admin/identities/${identity.id}/devices`}
              itemTemplate={deviceHeaderTemplate.bind(null, expanded)}
            />
          </Grid>
        </AdminCard>
      )}
    </>
  );

  // @TODO: 02/23/22 Need to figure out the usage of this /admin/identities/:id endpoint (currently unused) - for now we will call on mount
  useEffect(() => {
    adminUserApi!
      .getIdentity(id)
      .then(({ data }) => {
        // Get all of the users ties to this identity so we can put them in the tabs
        const identity = data;

        if (identity && identity.users) {
          const userArray: Array<any> = [];
          let userCount = 1;
          for (const user of identity.users) {
            userArray.push({
              title: DotWithLabel(user.id, trimText(user.id)),
              template: <UserProfileTemplate id={user.id} />,
            });
            userCount++;
          }
          setIdentity(identity);
          setTabs(userArray);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  // const { fetchData, fetching, errors, reset } = useFetch({
  //   url: `${config.apiRoot}/admin/identities/${id}`,
  //   fetchOnLoad: true,
  //   onSuccess: ({ data }) => {
  //     // Get all of the users ties to this identity so we can put them in the tabs
  //     const identity = data;

  //     if (identity && identity.users) {
  //       const userArray: Array<any> = [];
  //       let userCount = 1;
  //       for (const user of identity.users) {
  //         userArray.push({
  //           title: DotWithLabel(user.id, trimText(user.id)),
  //           template: <UserProfileTemplate id={user.id} />,
  //         });
  //         userCount++;
  //       }
  //       setIdentity(identity);
  //       setTabs(userArray);
  //     }
  //   },
  // });

  const identityPage = (identity, tabs, traits) => (
    <>
      {identity && traits && (
        <AdminPage
          title={''}
          header={headerTemplate(
            `${getTrait('firstName', traits)} ${getTrait('lastName', traits)}`,
            identity,
            traits
          )}
        >
          <AdminTabs title='Cookies' tabs={tabs} />
        </AdminPage>
      )}
    </>
  );

  return (
    <>
      {identity && tabs && (
        <AdminRecord
          loadingTemplate={<Skeleton variant='rectangular' height={800} />}
          form={true}
          url={`/admin/identities/${identity.id}/traits`}
          itemTemplate={identityPage.bind(null, identity, tabs)}
        />
      )}
    </>
  );
};

export default UserIdentityDetail;
