import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';

const uiSchema = {
  value: {
    subject: {
      'ui:widget': 'textarea',
    },
    html: {
      'ui:widget': 'textarea',
    },
    text: {
      'ui:widget': 'textarea',
    },
  },
};

const schema = {
  type: 'object',
  description:
    'This form will guide you in the configuration and creation of a notification template',
  properties: {
    id: {
      title: 'Identifier',
      type: 'string',
      description: 'Used to uniquely identify the template',
    },
    value: {
      type: 'object',
      title: '',
      properties: {
        type: {
          title: 'Type',
          enum: ['sms', 'email'],
          description: 'Type of notification template',
          default: 'sms',
        },
      },
      required: ['type'],
      dependencies: {
        type: {
          oneOf: [
            {
              properties: {
                type: {
                  enum: ['sms'],
                },
                text: {
                  title: 'Text Template',
                  type: 'string',
                  description: 'Text Template',
                },
              },
            },
            {
              properties: {
                type: {
                  enum: ['email'],
                },
                subject: {
                  title: 'Subject Template',
                  type: 'string',
                  description: 'Subject Template',
                },
                html: {
                  title: 'HTML Template',
                  type: 'string',
                  description: 'HTML Template',
                },
                text: {
                  title: 'Text Template',
                  type: 'string',
                  description: 'Text Template',
                },
              },
            },
          ],
        },
      },
    },
  },
};

const ConfigNotificationTemplateNew = () => {
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/config/notification-template`);
  };

  return (
    <>
      <AdminBaseForm
        url='/admin/config/notification-template'
        entityTitle='Notification Template'
        schema={schema}
        uiSchema={uiSchema}
        onSuccess={onSuccess}
        value='value'
      />
    </>
  );
};

export default ConfigNotificationTemplateNew;
