import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Box, Grid, Link as MaterialLink, TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { AdminTableRow } from '../../components/AdminTable';

export const TriggerHistory = ({ id }) => {
  const [lastRun, setLastRun] = useState();
  const { config, adminTriggerApi } = useBlueprintAdmin();
  useEffect(() => {
    adminTriggerApi!
      .getRunHistory(id)
      .then(({ data }) => {
        setLastRun(data[0].createdAt);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      {lastRun} (<Moment fromNow>{lastRun}</Moment>)
    </>
  );
};

const itemTemplate = ({ status, id, name }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <MaterialLink component={Link} to={`/triggers/${id}`}>
        {name}
      </MaterialLink>
    </TableCell>
    <TableCell>
      {status === 'active' && (
        <Grid container alignItems='center'>
          <CheckCircleOutlineIcon
            style={{ color: '#4ABF51', marginRight: '5px' }}
          />
          Active
        </Grid>
      )}
    </TableCell>
    <TableCell>
      <TriggerHistory id={id} />
    </TableCell>
    <TableCell>
      <MaterialLink
        component={Link}
        to={{ pathname: `/triggers/${id}`, state: { detail: 'viewHistory' } }}
      >
        View All
      </MaterialLink>
    </TableCell>
    <TableCell>
      <MaterialLink component={Link} to={`/triggers/${id}`}>
        <EditOutlinedIcon style={{ color: '#0070eb' }} />
      </MaterialLink>
    </TableCell>
  </AdminTableRow>
);

const Triggers = ({ beaconKey }) => {
  return (
    <Box marginTop={2}>
      <Box marginBottom={5}>
        <AdminPaginatedList
          title='Activity Triggers'
          columns={['Name', 'Status', 'Last Run', 'Run History', '']}
          url={`/admin/triggers/lookup/active/activity`}
          filter={`beaconId=${beaconKey}`}
          itemTemplate={itemTemplate}
          showCard={false}
          newAction={'/triggers/new'}
        />
      </Box>
      <Box marginBottom={5}>
        <AdminPaginatedList
          title='Profile Triggers'
          columns={['Name', 'Status', 'Last Run', 'Run History', '']}
          url={`/admin/triggers/lookup/active/profile`}
          filter={`beaconId=${beaconKey}`}
          itemTemplate={itemTemplate}
          showCard={false}
          newAction={'/triggers/new'}
        />
      </Box>
      <Box marginBottom={5}>
        <AdminPaginatedList
          title='Content Triggers'
          columns={['Name', 'Status', 'Last Run', 'Run History', '']}
          url={`/admin/triggers/lookup/active/content`}
          filter={`beaconId=${beaconKey}`}
          itemTemplate={itemTemplate}
          showCard={false}
          newAction={'/triggers/new'}
        />
      </Box>
    </Box>
  );
};

export default Triggers;
