import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

interface IAssetViewer {
  id: string;
  title?: string;
  caption?: string;
  clickable?: boolean; // if true user can click image to see the image in a new tab
  width?: number | string | undefined;
  showLoader?: boolean;
  setImageSrcUrl?: (string) => void;
  assetType?: 'image' | 'video';
}

function AdminAssetViewer({
  id,
  title,
  width = '100%',
  caption,
  clickable = true,
  showLoader = true,
  setImageSrcUrl = undefined,
  assetType = 'image', // default to image for backwards compatibility
}: IAssetViewer) {
  const { config, jwtToken, adminAssetApi } = useBlueprintAdmin();
  const [assetSrc, setAssetSrc] = useState<string | undefined>('');

  useEffect(() => {
    if (!jwtToken || !adminAssetApi) {
      return;
    } else {
      adminAssetApi
        .getAssetSignedUrl(id)
        .then(({ data }) => {
          setAssetSrc(data);
          setImageSrcUrl && setImageSrcUrl(data);
        })
        .catch((e) => console.error(e));
    }
  }, [jwtToken]);

  return (
    <>
      {id && !assetSrc && showLoader && <div style={{ width: width }}></div>}
      {id && assetSrc && assetType === 'image' && (
        <>
          {title && <Typography variant='h6'>{title}</Typography>}
          {clickable ? (
            <a href={assetSrc} target='_blank' rel='noreferrer'>
              <img src={assetSrc} width={width} />
            </a>
          ) : (
            <img
              src={assetSrc}
              width={width}
              height={450}
              style={{ objectFit: 'cover', objectPosition: '0 0' }}
            />
          )}
          {caption && <Typography variant='caption'>{caption}</Typography>}
        </>
      )}
      {id && assetSrc && assetType === 'video' && (
        <>
          {title && <Typography variant='h6'>{title}</Typography>}
          {clickable ? (
            <a href={assetSrc} target='_blank' rel='noreferrer'>
              <video width={width} controls>
                <source src={assetSrc} />
              </video>
            </a>
          ) : (
            <video
              width={width}
              height={450}
              style={{ objectFit: 'cover', objectPosition: '0 0' }}
              controls
            >
              <source src={assetSrc} />
            </video>
          )}
          {caption && <Typography variant='caption'>{caption}</Typography>}
        </>
      )}
    </>
  );
}

export default AdminAssetViewer;
