import { Menu } from 'antd';
import * as PropTypes from 'prop-types';
import { prop, sortBy } from 'ramda';
import React from 'react';
import ButtonDropdown from './ButtonDropdown'; // Can't be a Pure Component due to Dropdown lookups overlay component type to set appropriate styles

const sortByTitle = sortBy(prop('title'));

const memberMenu = (onClick, availableMembers) => {
  const filteredBenchMarkMembers = availableMembers.filter(
    (value) => !value.name.includes('be')
  );
  return (
    <Menu>
      {filteredBenchMarkMembers.length ? (
        sortByTitle(filteredBenchMarkMembers).map((m) => (
          <Menu.Item key={m.name} onClick={() => onClick(m)}>
            {m.title}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No members found</Menu.Item>
      )}
    </Menu>
  );
};

const MemberDropdown = ({ onClick, availableMembers, ...buttonProps }) => (
  <ButtonDropdown
    type={'icon'}
    overlay={memberMenu(onClick, availableMembers)}
    {...buttonProps}
  />
);

MemberDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired,
};
export default MemberDropdown;
