import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import { IBeaconConfig, IBeaconCreate } from '../../../api/interfaces';
import AdminCard from '../../../components/AdminCard';
import AdminModal from '../../../components/AdminModal';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../../components/AdminTable';

const useStyles = makeStyles({
  h5: {
    fontWeight: 'bold',
  },
  table: { marginTop: 10 },
  editBeaconAction: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '30px',
  },
  sourceText: {
    letterSpacing: '.15px',
    marginTop: '5px',
    marginBottom: '10px',
  },
});

const BeaconHeaderCard = ({ beacon, setBeacon, id }) => {
  const classes = useStyles();
  const history = useHistory();
  const { config, jwtToken, adminActivityApi } = useBlueprintAdmin();
  const [isEditingBeacon, setIsEditingBeacon] = useState<boolean>(false);
  const [beaconName, setBeaconName] = useState<string>(beacon.name);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isProdModalOpen, setIsProdModalOpen] = useState<boolean>(false);
  const [prodBeaconTitle, setProdBeaconTitle] = useState<string>('');

  const handleSaveBeaconName = async () => {
    const data: IBeaconConfig = {
      name: beaconName,
      apiRoot: config.apiRoot,
    };
    const response = await adminActivityApi!.updateBeacon(id, data);
    if (response.success) {
      setBeacon(response.data);
      setBeaconName(response.data.name);
    } else {
      console.error('error saving beacon name');
    }
    setIsEditingBeacon(false);
  };

  const handleDeleteBeacon = async () => {
    const response = await adminActivityApi!.delete({
      path: `/admin/beacons/${id}`,
    });
    if (response.success) {
      history.push(`/beacons`);
    } else {
      console.error('error deleting beacon');
    }
  };

  const handleCopyToProduction = async () => {
    const data: IBeaconCreate = {
      name: prodBeaconTitle,
      debug: false,
      apiRoot: beacon.apiRoot,
      trackers: [...beacon.trackers],
      sourceBeacon: {
        name: beacon.name,
        id: beacon.id,
      },
    };
    const response = await adminActivityApi!.createBeacon(data);
    if (response.success) {
      setIsProdModalOpen(false);
      history.push({
        pathname: `/beacons`,
        state: { detail: 'BeaconConverted' },
      });
    } else {
      console.error('error copying beacon to production');
    }
  };

  const { sourceBeacon, name, debug } = beacon;

  return (
    <AdminCard>
      <Grid container>
        <Grid item xs={12}>
          {!isEditingBeacon ? (
            <>
              <Typography variant='h5' className={classes.h5}>
                {name}{' '}
                <IconButton
                  onClick={() => setIsEditingBeacon(true)}
                  aria-label='edit beacon name'
                  data-testid='edit-button'
                  size='small'
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Typography>
              {sourceBeacon?.name && (
                <Typography variant='body2' className={classes.sourceText}>
                  Created from{' '}
                  <UiLink component={Link} to={`/beacons/${sourceBeacon.id}`}>
                    {sourceBeacon.name}
                  </UiLink>
                </Typography>
              )}
            </>
          ) : (
            <>
              <TextField
                onChange={(e) => {
                  setBeaconName(e.target.value);
                }}
                defaultValue={name}
                variant='outlined'
                error={!beaconName}
                helperText={!beaconName ? 'Select a name for the beacon' : ''}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Key</AdminTableHeadCell>
                <AdminTableHeadCell>API Root</AdminTableHeadCell>
                <AdminTableHeadCell>Beacon Mode</AdminTableHeadCell>
                <AdminTableHeadCell>Last Updated</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              <AdminTableRow>
                <TableCell component='th' scope='row'>
                  {beacon.key}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {beacon.apiRoot}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {debug ? (
                    <Chip
                      color='primary'
                      variant='outlined'
                      label='Development'
                    />
                  ) : (
                    <Chip color='primary' variant='filled' label='Production' />
                  )}
                </TableCell>
                <TableCell component='th' scope='row'>
                  <Moment fromNow>{beacon.updatedAt}</Moment>
                </TableCell>
              </AdminTableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {isEditingBeacon ? (
        <Grid item xs={12}>
          <div className={classes.editBeaconAction}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setIsEditingBeacon(false)}
              aria-label='cancel beacon name edit'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              aria-label='save beacon name'
              onClick={handleSaveBeaconName}
              style={{ marginLeft: '20px' }}
              disabled={!beaconName}
            >
              Save
            </Button>
          </div>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <div className={classes.editBeaconAction}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setIsModalOpen(true)}
              aria-label='delete beacon'
            >
              Delete
            </Button>
            {debug && (
              <Button
                variant='outlined'
                color='primary'
                aria-label='copy beacon to production'
                onClick={() => setIsProdModalOpen(true)}
                style={{ marginLeft: '20px' }}
              >
                Copy to Production
              </Button>
            )}
          </div>
        </Grid>
      )}

      <AdminModal
        title={`Delete Beacon?`}
        body={
          <Typography variant='body1'>
            Are you sure you would like to delete this beacon?
          </Typography>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button variant='text' color='primary' onClick={handleDeleteBeacon}>
              DELETE
            </Button>
          </>
        }
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />

      <AdminModal
        title={`Copy Beacon to Production Mode`}
        body={
          <>
            <Typography variant='body1'>
              Create a duplicate of this beacon in production mode with the
              existing trackers and settings.
            </Typography>
            <FormControl>
              <Typography variant='body1' className={classes.label}>
                Enter a new name:
              </Typography>
              <TextField
                error={!prodBeaconTitle}
                helperText={
                  !prodBeaconTitle ? 'Select a name for the beacon' : ''
                }
                type='text'
                variant='outlined'
                value={prodBeaconTitle}
                placeholder={'Beacon Name'}
                onChange={(e) => setProdBeaconTitle(e.target.value)}
              />
            </FormControl>
          </>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsProdModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button
              variant='text'
              color='primary'
              onClick={handleCopyToProduction}
              disabled={!prodBeaconTitle}
            >
              COPY
            </Button>
          </>
        }
        isOpen={isProdModalOpen}
        closeModal={() => setIsProdModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
    </AdminCard>
  );
};

export default BeaconHeaderCard;
