import AdminCard from '../AdminCard';
import ChartRenderer from './ChartRenderer';

const heights = {
  pie: 250,
  line: 250,
  number: 50,
};

interface IChart {
  title?: string;
  vizState?: any;
  height?: any;
  showViewDetail?: boolean;
  showCard?: boolean;
  tableTemplate?: any;
}

const Chart = ({
  title,
  vizState,
  height,
  showViewDetail = false,
  showCard = true,
  tableTemplate = <></>,
}: IChart) => (
  <>
    {!showCard && (
      <>
        <ChartRenderer
          vizState={vizState}
          height={height || heights[vizState.chartType]}
          tableTemplate={tableTemplate}
        />
      </>
    )}
    {showCard && (
      <AdminCard
        title={title}
        viewAction={
          showViewDetail
            ? `/reports/detail/?query=${JSON.stringify(vizState)}`
            : undefined
        }
      >
        <ChartRenderer
          vizState={vizState}
          height={height || heights[vizState.chartType]}
          tableTemplate={tableTemplate}
        />
      </AdminCard>
    )}
  </>
);

export default Chart;
