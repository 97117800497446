import { ConfigUserAPI } from '@deloitte/blueprint-sdk-admin';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../components/AdminProvider';

const NotificationNew = () => {
  const history = useHistory();
  const { config, jwtToken } = useBlueprintAdmin();
  const [templates, setTemplates] = useState<Array<string>>([]);

  const configApi = new ConfigUserAPI(config);

  const uiSchema = {};

  const schema = {
    type: 'object',
    description:
      'This form will guide you in the configuration and creation of a notification template',
    properties: {
      id: {
        title: 'Identifier',
        type: 'string',
        description: 'Used to uniquely identify the template',
      },
      template: {
        title: 'Template',
        enum: templates,
        description: 'Notification template to use',
      },
      audience: {
        title: 'Audience',
        enum: [
          'Invited Users',
          'Active Users',
          '---',
          'Segment A',
          'Segment B',
        ],
        description: 'Who the notification should be sent to',
      },
    },
  };

  const onSuccess = (response) => {
    history.push(`/notifications`);
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    configApi
      .getNewNotificationTemplate()
      .then(({ data }) => {
        const templateIds = Array<string>();
        for (const template of data) {
          templateIds.push(template.id);
        }
        setTemplates(templateIds);
      })
      .catch((e) => console.error(e));
  }, [jwtToken]);

  return (
    <>
      {templates && (
        <AdminBaseForm
          url='/admin/notifications-wont-work-until-we-set-this-up'
          entityTitle='Notification'
          schema={schema}
          uiSchema={uiSchema}
          onSuccess={onSuccess}
          value='value'
        />
      )}
    </>
  );
};

export default NotificationNew;
