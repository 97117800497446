import { Menu } from 'antd';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ButtonDropdown from './ButtonDropdown';
import MemberDropdown from './MemberDropdown';
import MemberGroupTitle from './MemberGroupTitle';
import RemoveButtonGroup from './RemoveButtonGroup';

const DateRanges = [
  {
    title: 'Past 5 minutes',
    value: 'from 5 minutes ago to now',
    granularity: 'minute',
  },
  {
    title: 'Past 30 minutes',
    value: 'from 30 minutes ago to now',
    granularity: 'minute',
  },
  {
    title: 'Today',
    value: 'from 24 hours ago to now',
    granularity: 'hour',
  },
  {
    title: 'This Week',
    value: 'from 7 days ago to now',
    granularity: 'day',
  },
  {
    title: 'This Month',
    value: 'from 1 month ago to now',
    granularity: 'day',
  },
  {
    title: 'This Year',
    value: 'from 1 year ago to now',
    granularity: 'month',
  },
];

const GroupLabel = styled.span`
  font-size: 14px;
  margin: 0 12px;
`;

const TimeGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  title,
}) => {
  const granularityMenu = (member, onClick) => (
    <Menu>
      {member.granularities.length ? (
        member.granularities.map((m) => (
          <Menu.Item key={m.title} onClick={() => onClick(m)}>
            {m.title}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No members found</Menu.Item>
      )}
    </Menu>
  );

  const dateRangeMenu = (onClick) => (
    <Menu>
      {DateRanges.map((m) => (
        <Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title || m.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <MemberGroupTitle title={title} />
      {members.map((m) => [
        <RemoveButtonGroup
          onRemoveClick={() => updateMethods.remove(m)}
          key={`${m.dimension.name}-member`}
        >
          <MemberDropdown
            type='selected'
            onClick={(updateWith) =>
              updateMethods.update(m, { ...m, dimension: updateWith })
            }
            availableMembers={availableMembers}
          >
            {m.dimension.title}
          </MemberDropdown>
        </RemoveButtonGroup>,
        <GroupLabel key={`${m.dimension.name}-for`}>date range</GroupLabel>,
        <ButtonDropdown
          type='selected'
          overlay={dateRangeMenu((dateRange) =>
            updateMethods.update(m, {
              ...m,
              dateRange: dateRange.value,
              granularity: dateRange.granularity,
            })
          )}
          key={`${m.dimension.name}-date-range`}
        >
          {m.dateRange || 'All time'}
        </ButtonDropdown>,
      ])}
      {!members.length && (
        <GroupLabel key={'undefined'}>Please select a measure</GroupLabel>
      )}
    </div>
  );
};

TimeGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default TimeGroup;
