import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { uiSchema } from '../../forms/admins';

const AdminEdit = () => {
  const { adminGroupApi } = useBlueprintAdmin();

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [groups, setGroups] = useState([]);

  const onSuccess = (response) => {
    history.push(`/admins/${response.data.id}`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  useEffect(() => {
    adminGroupApi!
      .getGroups()
      .then(({ data }) => {
        const response = data.map((group) => ({
          type: 'string',
          enum: group,
          enumNames: group.name,
          title: group.name,
        }));
        setGroups(response);
      })
      .catch((e) => console.error(e));
  }, []);

  function validate(formData, errors) {
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword.addError("Passwords don't match");
    }
    return errors;
  }

  const schema = {
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: 'Username',
        description: 'The username (email) used for login',
      },
      password: {
        type: 'string',
        title: 'Password',
        description: 'The password used for login',
      },
      confirmPassword: {
        type: 'string',
        title: 'Confirm Password',
        description: 'The password used for login',
      },
      groups: {
        type: 'array',
        title: 'Groups',
        items: {
          title: 'Groups',
          type: 'string',
          enum: groups.map((group: any) => group.enum.id),
          enumNames: groups.map((group: any) => group.enumNames),
        },
        description: 'Groups to be applied to this administrator account',
      },
    },
  };

  return (
    <>
      {id && groups && (
        <>
          <AdminBaseForm
            id={id}
            url='/admin/admins'
            entityTitle='Admin'
            schema={schema}
            uiSchema={uiSchema}
            onSuccess={onSuccess}
            onFailure={onFailure}
            validate={validate}
          />
        </>
      )}
    </>
  );
};

export default AdminEdit;
