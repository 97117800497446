/* eslint-disable react/display-name */
import { useCubeQuery } from '@cubejs-client/react';
import MonacoEditor from '@monaco-editor/react';
import {
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminModal from '../AdminModal';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../AdminTable';
import AdminTabs from '../AdminTabs';

const numberFormatter = (item) => numeral(item).format('0,0');
const decimalFormatter = (item) => numeral(item).format('0,0.00');
const percentFormatter = (item) => numeral(item / 100.0).format('0.00%');
const timeNumberFormatter = (item) => numeral(item).format('00:00:00');

const dateFormatter = (item, granularity, resultSet) => {
  switch (granularity) {
    case 'minute' || 'hour':
      return moment(item).format('h:mm:ss a');
    case 'day': {
      if (resultSet.chartPivot().length <= 8) {
        return moment(item).format('dddd');
      }
      return moment(item).format('MM-DD');
    }
    // case 'week': {
    //   const prefixes = [1, 2, 3, 4, 5];
    //   return prefixes[0 | (moment(item).date() / 7)];
    // }
    case 'month':
      return moment(item).format('MMM YY');
    default:
      return moment(item).format('h:mm:ss a');
  }
};

const resolveFormatter = (type) => {
  if (type === 'string') {
    return (item) => item;
  } else if (type === 'number') {
    return numberFormatter;
  }
};

const xAxisFormatter = (item, resultSet) => {
  const granularity =
    resultSet.loadResponse.pivotQuery.timeDimensions[0].granularity;
  if (moment(item).isValid()) {
    return dateFormatter(item, granularity, resultSet);
  } else {
    return item;
  }
};

const CartesianChart = ({
  resultSet,
  legend,
  children,
  ChartComponent,
  height,
  showAxis = true,
  showTooltip = true,
}) => {
  return (
    <>
      <ResponsiveContainer width='100%' height={height || 250}>
        <ChartComponent
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 0,
          }}
          data={resultSet.chartPivot()}
        >
          {showAxis && (
            <>
              <XAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => {
                  return xAxisFormatter(value, resultSet);
                }}
                dataKey='x'
                minTickGap={20}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={numberFormatter}
              />
            </>
          )}
          <CartesianGrid vertical={false} />
          {children}
          {/* {legend && <Legend content={renderLegend} />} */}
          {legend && <Legend />}
          {showTooltip && (
            <Tooltip
              labelFormatter={(value) => {
                return xAxisFormatter(value, resultSet);
              }}
              formatter={numberFormatter}
            />
          )}
        </ChartComponent>
      </ResponsiveContainer>
    </>
  );
};

CartesianChart.defaultProps = {
  legend: true,
};

const colors = ['#4791db', '#e33371', '#e57373'];

const durationMeasures = ['Activity.sentAt'];

// eslint-disable-next-line no-unused-vars
const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const TableDisplay = ({ resultSet }) => {
  const { adminActivityApi } = useBlueprintAdmin();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [selectedActivity, setSelectedActivity] = useState<any>(undefined);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const activityModalTemplate = () => (
    <>
      {selectedActivity && (
        <MonacoEditor
          theme='vs-dark'
          defaultLanguage='json'
          value={selectedActivity}
          height='100%'
          width='100%'
        />
      )}
    </>
  );

  const openModal = async (id) => {
    if (adminActivityApi) {
      const data = await adminActivityApi.getUserActivityById(id);
      setSelectedActivity(data.data);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Table size='small'>
        <AdminTableHead>
          <TableRow>
            {resultSet.tableColumns().map((c) => (
              <>
                {c.key === 'Activity.id' && (
                  <AdminTableHeadCell key={c.key}>actions</AdminTableHeadCell>
                )}
                {c.key !== 'Activity.id' && (
                  <AdminTableHeadCell key={c.key}>
                    {c.shortTitle}
                  </AdminTableHeadCell>
                )}
              </>
            ))}
          </TableRow>
        </AdminTableHead>
        <TableBody>
          {resultSet.tablePivot().map((row, index) => (
            <AdminTableRow key={index}>
              {resultSet.tableColumns().map((c) => {
                // If we have an Activity ID and want to open a modal
                if (c.key === 'Activity.id') {
                  return (
                    <TableCell key={c.key}>
                      <UiLink
                        key={c.key}
                        onClick={async () => {
                          await openModal(row[c.key]);
                        }}
                      >
                        View
                      </UiLink>
                    </TableCell>
                  );
                  // If we have a user ID and want to direct to their URL
                } else if (c.key === 'Activity.userId') {
                  return (
                    <TableCell key={c.key}>
                      <UiLink
                        component={Link}
                        key={c.key}
                        to={`/users/${row[c.key]}`}
                      >
                        {row[c.key]}
                      </UiLink>
                    </TableCell>
                  );
                } else {
                  return <TableCell key={c.key}>{row[c.key]}</TableCell>;
                }
              })}
            </AdminTableRow>
          ))}
        </TableBody>
      </Table>
      <AdminModal
        title='Activity Debug'
        body={activityModalTemplate()}
        isOpen={isModalOpen}
        closeModal={closeModal}
        size='xl'
      />
    </>
  );
};

const TypeToChartComponent = {
  tabs: ({ resultSet, ...props }) => {
    const tabs: Array<any> = [];
    for (const item of resultSet.tablePivot()) {
      tabs.push({
        title: 'title',
        template: <p>hey</p>,
      });
    }
    return <AdminTabs title='Users' tabs={tabs} />;
  },
  line: ({ resultSet, ...props }) => {
    return (
      <CartesianChart
        height={200}
        resultSet={resultSet}
        ChartComponent={LineChart}
        {...props}
      >
        {resultSet.seriesNames().map((series, i) => (
          <Line
            key={series.key}
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
          />
        ))}
      </CartesianChart>
    );
  },
  sparkline: ({ resultSet, ...props }) => (
    <CartesianChart
      height={100}
      resultSet={resultSet}
      ChartComponent={LineChart}
      legend={false}
      showAxis={false}
      showTooltip={false}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Line
          type='monotone'
          key={series.key}
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          dot={false}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet, ...props }) => (
    <CartesianChart
      height={200}
      resultSet={resultSet}
      ChartComponent={BarChart}
      {...props}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          key={series.key}
          stackId='a'
          dataKey={series.key}
          name={series.title}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  // area: ({ resultSet }) => (
  //   <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
  //     {resultSet.seriesNames().map((series, i) => (
  //       <Area
  //         key={series.key}
  //         stackId="a"
  //         dataKey={series.key}
  //         name={series.title}
  //         stroke={colors[i]}
  //         fill={colors[i]}
  //       />
  //     ))}
  //   </CartesianChart>
  // ),
  pie: ({ resultSet, legend, height }) => (
    <ResponsiveContainer width='100%' height={height || 250}>
      <PieChart>
        <Pie
          label={(value) => numeral(value.percent).format('0.00%')}
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey='x'
          dataKey={resultSet.seriesNames()[0].key}
          fill='#8884d8'
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {legend && <Legend layout={legend} align='right' />}
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  ),
  number: ({ resultSet, height }) => {
    if (resultSet.seriesNames()[0]) {
      const measureKey = resultSet.seriesNames()[0].key; // Ensure number can only render single measure
      // const format =
      //   resultSet.loadResponse.annotation.measures[measureKey].format;
      const value = resultSet.totalRow()[measureKey];
      let formattedValue;
      // if (format === "percent") {
      // formattedValue = percentFormatter(value);
      // } else
      if (durationMeasures.includes(measureKey)) {
        // special case, since format time is missing
        formattedValue = timeNumberFormatter(value);
      } else if (Math.ceil(value) === value && Math.floor(value) === value) {
        formattedValue = numberFormatter(value);
      } else {
        formattedValue = decimalFormatter(value);
      }
      return (
        <Typography variant='h4' style={{ height: height }}>
          {formattedValue}
        </Typography>
      );
    } else {
      return (
        <Typography variant='h4' style={{ height: height }}>
          -
        </Typography>
      );
    }
  },
  table: ({ resultSet }) => <TableDisplay resultSet={resultSet} />,
  templatedTable: ({ resultSet }) => <TableDisplay resultSet={resultSet} />,
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const Loader = ({ height }) => (
  <>
    <Skeleton
      variant='rectangular'
      style={{ marginTop: 10, height: height, width: '100%' }}
    ></Skeleton>
  </>
);

const renderChart =
  (Component) =>
  ({ resultSet, error, height, ...props }) =>
    (resultSet && (
      <Component resultSet={resultSet} height={height} {...props} />
    )) ||
    (error && error.toString()) || <Loader height={height} />;

interface IChartRenderer {
  vizState: any;
  height: any;
  tableTemplate?: any;
}

const ChartRenderer = ({ vizState, height, tableTemplate }: IChartRenderer) => {
  const { query, chartType, ...options } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return (
    component &&
    renderChart(component)({
      ...options,
      height,
      ...renderProps,
      tableTemplate,
    })
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
