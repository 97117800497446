import { Button, Chip, TableCell, Link as UiLink } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import AdminPage from '../../../components/AdminPage';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminTableRow } from '../../../components/AdminTable';

const BenchmarkClientListInactive = () => {
  const { config, jwtToken, adminBenchmarkApi } = useBlueprintAdmin();
  const history = useHistory();

  const itemTemplate = ({ id, name, active, url }, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}`}>
          {name}
        </UiLink>
      </TableCell>
      <TableCell>{url}</TableCell>
      <TableCell>
        {active && <Chip color='primary' variant='filled' label='Active' />}
        {!active && (
          <Chip color='primary' variant='outlined' label='Inactive' />
        )}
      </TableCell>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}`}>
          View
        </UiLink>{' '}
        |{' '}
        <UiLink component={Link} to={`/benchmarks/clients/${id}/edit`}>
          Edit
        </UiLink>{' '}
        |{' '}
        {active && (
          <UiLink
            component={Button}
            onClick={async () => {
              await adminBenchmarkApi!.deactivateClient(id);
              history.push(`/benchmarks/clients`);
            }}
          >
            Deactivate
          </UiLink>
        )}
        {!active && (
          <UiLink
            component={Button}
            onClick={async () => {
              await adminBenchmarkApi!.activateClient(id);
              history.push(`/benchmarks/clients`);
            }}
          >
            Activate
          </UiLink>
        )}
      </TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage
      animation={true}
      title='Benchmark Clients'
      newAction='/benchmarks/clients/new'
      customActionText='Actions'
      customAction={() => {
        window.location.href = '/benchmarks/actions';
      }}
      breadcrumbs={[
        { href: '/benchmarks/clients', label: 'Benchmarks' },
        { href: '/benchmarks/clients', label: 'Clients' },
      ]}
    >
      <AdminPaginatedList
        columns={['name', 'url', 'status', 'actions']}
        url='/admin/benchmarks/clients/inactive'
        itemTemplate={itemTemplate}
        filterKeys={[]}
      />
    </AdminPage>
  );
};

export default BenchmarkClientListInactive;
