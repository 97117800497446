import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';
import { schema, uiSchema } from '../../forms/jobs';

const JobEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/jobs/${id}`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  return (
    <>
      {id && (
        <>
          <AdminBaseForm
            id={id}
            url='/admin/schedules'
            entityTitle='Jobs'
            schema={schema}
            uiSchema={uiSchema}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </>
      )}
    </>
  );
};

export default JobEdit;
