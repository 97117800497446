import { Grid, Link as UiLink, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ListAlt, Send, Settings } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const ConfigList = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AdminPage
      animation={true}
      title='Config'
      description='These configuration entities allow you to customize your Blueprint installation. They heavily rely on the AdminConfig object, so you can easily add new config items for a specific client instance if needed'
      customAction={() => {
        history.push(`/config/all`);
      }}
      customActionText='View All'
    >
      <Grid container>
        <Grid item xs={4} style={{ padding: 20 }}>
          <AdminCard
            customAction={
              <UiLink component={Link} to={'/setup'} style={{}}>
                Manage
              </UiLink>
            }
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Settings style={{ color: '#000' }} fontSize='large' />
                <span>&nbsp;Admin Capabilities</span>
              </div>
            }
          >
            <Typography>
              Capabilities act as the base decision when using Blueprint. These
              capabilities will automatically enable sidebar configuration and
              other settings when turned on. This will override your existing
              sidebar and capability settings.
            </Typography>
          </AdminCard>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <AdminCard
            customAction={
              <UiLink component={Link} to={'/config/sidebar'} style={{}}>
                Manage
              </UiLink>
            }
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ListAlt style={{ color: '#000' }} fontSize='large' />
                <span>&nbsp;Admin Sidebar</span>
              </div>
            }
          >
            <Typography>
              Sidebar configuration allows you to set <strong>fixed</strong>{' '}
              (visible to all administrators) and <strong>role</strong> (visible
              only to users with approved roles) based sidebar items.
            </Typography>
          </AdminCard>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <AdminCard
            customAction={
              <UiLink
                component={Link}
                to={'/config/notification-template'}
                style={{}}
              >
                Manage
              </UiLink>
            }
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Send style={{ color: '#000' }} fontSize='large' />
                <span>&nbsp;Notification Templates</span>
              </div>
            }
          >
            <Typography>
              Notification templates allow you to define a series of SMS and
              Email notification templates to be used for communicating through
              Blueprint.
            </Typography>
          </AdminCard>
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default ConfigList;
