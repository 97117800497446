import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { Grid } from '@mui/material';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminNumberWithTrend from '../../components/reporting/AdminNumberWithTrend';

const ReportAcquisition = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AdminPage title='General Report'>
        <Grid container xs={12}>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminNumberWithTrend
              title='New Users'
              numberQuery={{
                measures: ['Activity.monthlyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.timestamp',
                    dateRange: 'This month',
                  },
                ],
                order: {},
                filters: [],
                dimensions: [],
              }}
              trendQuery={{
                measures: ['Activity.monthlyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.sentAt',
                    granularity: 'month',
                    dateRange: 'Last 6 months',
                  },
                ],
                order: {
                  'Activity.sentAt': 'asc',
                },
                filters: [],
                dimensions: [],
              }}
            />
          </Grid>
        </Grid>
      </AdminPage>
    </CubeProvider>
  );
};

export default ReportAcquisition;
