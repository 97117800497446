import { useLocation } from 'react-router-dom';
import AdminBenchmarkPerformanceChart from '../../components/AdminBenchmarkPerformanceChart';

const BenchmarkChartsComparison = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const clientValue = query.get('clientValue');
  const competitorAvg = query.get('competitorAvg');
  const min = query.get('min') ? Number.parseFloat(query.get('min')!) : 0;
  const max = query.get('max') ? Number.parseFloat(query.get('max')!) : 100;
  const variant = query.get('variant') ? query.get('variant') : 'competitor';

  return (
    <div
      style={{
        width: 450,
        height: 375,
        paddingTop: 50,
        paddingLeft: 60,
        paddingRight: 20,
      }}
    >
      <AdminBenchmarkPerformanceChart
        clientValue={clientValue}
        competitorAvg={competitorAvg}
        min={min}
        max={max}
        variant={'industry'}
      />
    </div>
  );
};

export default BenchmarkChartsComparison;
