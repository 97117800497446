import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from 'react';
import { AdminToolTip } from './AdminToolTip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  AccordianOuterBox: {
    width: '100%',
    boxShadow: 'none',
  },
  AccordionSummaryHelp: {
    width: '180px',
  },
  tableStatus: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AdminTableCard = ({
  title = undefined,
  toolTip,
  selectable = false,
  id,
  form_data,
  enabled,
  onSelectSpecific,
}: any) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(enabled ?? false);
  }, [enabled]);

  const getTitle = () => (
    <>
      {title}
      {selectable}
    </>
  );

  const onEditClick = () => {
    onSelectSpecific(id);
  };

  const SettingsPreviewAccordian = () => {
    const classes = useStyles();
    return (
      <Accordion
        className={classes.AccordianOuterBox}
        data-testid={`${id}-accordion`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className={classes.AccordionSummaryHelp}
        >
          <Typography variant='body2'>Settings Preview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='body2'>
                <ul>
                  {Object.keys(form_data).map((key, index) => (
                    <li key={index}>
                      <b>{key}</b>: {form_data[key]}
                    </li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const TableView = () => {
    const classes = useStyles();
    return (
      <TableRow style={{ width: '100%', maxHeight: '1px' }}>
        <TableCell width='25%'>
          {getTitle()}{' '}
          {toolTip && (
            <AdminToolTip
              message={<Typography variant='caption'>{toolTip}</Typography>}
            ></AdminToolTip>
          )}
        </TableCell>
        <TableCell width='25%'>
          {checked && (
            <div className={classes.tableStatus} data-testid={`${id}-active`}>
              <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              &nbsp;&nbsp;Active
            </div>
          )}
        </TableCell>
        <TableCell
          style={{
            width: '100%',
            maxWidth: '400px',
            minWidth: '400px',
            wordWrap: 'break-word',
          }}
          width='40%'
          align='left'
        >
          <SettingsPreviewAccordian></SettingsPreviewAccordian>
        </TableCell>
        <TableCell width='10%'>
          <IconButton onClick={onEditClick} data-testid={`${id}-edit-button`}>
            <EditOutlinedIcon style={{ color: '#0070eb' }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return <TableView></TableView>;
};

export default AdminTableCard;
