import { useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styled from 'styled-components';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
const ReactGridLayout = WidthProvider(RGL);

const DragField = styled(ReactGridLayout)`
  margin: 16px 28px 50px 28px;
  ${(props) =>
    props.isDragging
      ? `
    background: url(${'/assets/images/drag-background.svg'});
    background-repeat: repeat-y;
    background-position: 0px -4px;
    background-size: 100% 52px;
  `
      : ''};
`;

const Dashboard = ({ children, dashboardItems, dashboardId }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { config, adminDashboardApi } = useBlueprintAdmin();
  const onLayoutChange = (newLayout) => {
    newLayout.forEach((l) => {
      const item = dashboardItems.find((i) => i.id.toString() === l.i);
      const toUpdate = {
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h,
      };

      if (item && toUpdate !== item.layout) {
        item.layout = toUpdate;
      }
    });
    adminDashboardApi!
      .updateDashboardItem(dashboardId, { items: dashboardItems })
      .then((data) => {
        console.log(data);
      })
      .catch((e) => console.error(e));
  };

  return (
    <DragField
      margin={[12, 12]}
      containerPadding={[0, 0]}
      onDragStart={() => setIsDragging(true)}
      onDragStop={() => setIsDragging(false)}
      onResizeStart={() => setIsDragging(true)}
      onResizeStop={() => setIsDragging(false)}
      cols={24}
      rowHeight={40}
      onLayoutChange={onLayoutChange}
      isDragging={isDragging}
    >
      {children}
    </DragField>
  );
};

export default Dashboard;
