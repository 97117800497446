import cubejs, { ResultSet } from '@cubejs-client/core';
import { IDataLayerTrackerConfig } from '@deloitte/blueprint-js';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from '../../../../../components/AdminProvider';
import { FormInputMultiCheckbox } from '../../form-components/FormInputMultiCheckbox';
import { FormInputText } from '../../form-components/FormInputText';
import { DefaultBeaconOptions } from '../TrackerConstants';

export const setDataLayerTrackerPrevConfig = (savedData, name) => {
  const DataLayerTrackerSettings: IDataLayerTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };

  if (DataLayerTrackerSettings.settings) {
    form_data_obj.customKey = DataLayerTrackerSettings.settings.customKey;
    if (DataLayerTrackerSettings.settings.ignoreEvents) {
      form_data_obj.ignoreEvents =
        DataLayerTrackerSettings.settings.ignoreEvents;
    }
  }
  return form_data_obj;
};

export const userDataLayerSettingFormChange = (form_data) => {
  if (form_data.customKey === '' && form_data.ignoreEvents.length === 0) {
    return null;
  } else {
    if (form_data.ignoreEvents.length === 0) {
      return { customKey: form_data.customKey };
    } else if (form_data.customKey === '') {
      return { ignoreEvents: form_data.ignoreEvents };
    } else {
      return form_data;
    }
  }
};

export const setDataLayerOnSubmit = (form_data, updatedData) => {
  form_data.customKey = updatedData.customKey;
  form_data.ignoreEvents = updatedData.ignoreEvents;
};

export const DataLayerTrackerForm = (
  control,
  setValue,
  onSubmit,
  form_data,
  beacon
) => {
  const [eventValues, setEventValues] = useState<Array<any>>([]);
  const { config, jwtToken } = useBlueprintAdmin();
  const [firstrender, setFirstRender] = useState(true);
  const blockedEvents = eventValues.map((item): any => ({
    label: `${item.x} (Occured ${item['Activity.count']} times)`,
    value: item.x,
  }));

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  useEffect(() => {
    const fetchData = async () => {
      const resultSet: ResultSet<any> = await cubejsApi.load({
        measures: ['Activity.count'],
        timeDimensions: [
          {
            dimension: 'Activity.sentAt',
          },
        ],
        order: {
          'Activity.count': 'desc',
        },
        filters: [
          {
            member: 'Activity.beaconKey',
            operator: 'equals',
            values: [beacon.key],
          },
        ],
        dimensions: ['Activity.event'],
      });

      setEventValues(resultSet.chartPivot());
    };

    if (firstrender) {
      setFirstRender(false);
      fetchData().catch((e) => {
        console.error(e);
      });
    }
  }, []);

  return (
    <Grid container spacing={1}>
      <p>Custom Datalayer Name (leave blank for default)</p>
      <Grid item spacing={1} sm={12}>
        <FormInputText
          name='customKey'
          control={control}
          label='ex: dLayer, gLayer'
          defaultValue={form_data.customKey}
          setValue={setValue}
          submitfunction={onSubmit}
        />
      </Grid>
      <Grid item spacing={1} sm={12}>
        <Typography variant='subtitle1'>Blocked Events</Typography>
        <FormInputMultiCheckbox
          name={'ignoreEvents'}
          control={control}
          label={''}
          setValue={setValue}
          optionsList={blockedEvents}
          defaultValues={form_data.ignoreEvents}
          submitfunction={onSubmit}
        />
      </Grid>
    </Grid>
  );
};
