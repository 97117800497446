import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import SecurityIcon from '@material-ui/icons/Security';

interface LoginFormProps {
  needsMFASetup: boolean;
  handleSubmit: (username: string, password: string, phone: string) => void;
}

const LoginForm = ({ needsMFASetup, handleSubmit }: LoginFormProps) => {
  const formik = useFormik({
    initialValues: {
      phone: '',
      username: '',
      password: '',
    },
    // eslint-disable-next-line
    onSubmit: async (values) => {
      handleSubmit(values.username, values.password, values.phone);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box maxWidth={{ xs: 400 }} my={2}>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='username'
            name='username'
            type='text'
            variant='outlined'
            label='Username'
            autoComplete='username email'
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl margin='dense' fullWidth>
          <TextField
            id='password'
            name='password'
            type='password'
            variant='outlined'
            label='Password'
            autoComplete='password'
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </FormControl>
        {needsMFASetup && (
          <>
            <Typography variant='caption'>
              In order for you to login to your account, we need a phone number
              so that we can provide two-factor authentication
            </Typography>
            <FormControl margin='dense' fullWidth>
              <TextField
                id='phone'
                name='phone'
                type='text'
                variant='outlined'
                label='Phone Number'
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </FormControl>
          </>
        )}
      </Box>
      <Button
        variant='contained'
        color='primary'
        size='large'
        id='submit'
        type='submit'
        startIcon={<SecurityIcon />}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
