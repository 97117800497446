import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useBlueprintAdmin } from '../components/AdminProvider';
import { schema as adminGroupSchema } from '../forms/adminGroups';
import { uiSchema as adminUiSchema } from '../forms/admins';
import {
  schema as inviteSchema,
  uiSchema as inviteUiSchema,
} from '../forms/invites';
import { schema as userGroupSchema } from '../forms/userGroups';
import {
  AdminActivity,
  AdminDetail,
  AdminEdit,
  AdminList,
  AdminNew,
  AdminServiceDetail,
  AdminServiceEdit,
  AdminServiceNew,
} from './admins';
import {
  AdminGroupDetail,
  AdminGroupEdit,
  AdminGroupNew,
} from './admins/groups';
import { AssetCreate, AssetDetail, AssetList } from './assets';
import { BeaconDetail, BeaconList, BeaconNew } from './beacons';
import DestinationsNew from './beacons/destinations/new';
import { BenchmarkActions } from './benchmarks/actions';
import {
  BenchmarkBrowseByIndustry,
  BenchmarkClientDetail,
  BenchmarkClientEdit,
  BenchmarkClientList,
  BenchmarkClientListInactive,
  BenchmarkClientNew,
  BenchmarkClientPageDetail,
  BenchmarkClientPageEdit,
  BenchmarkClientPageNew,
  BenchmarkClientRunDetail,
  BenchmarkClientScorecard,
  BenchmarkProspectNew,
} from './benchmarks/clients';
import { BenchmarkClientCompetitorsNew } from './benchmarks/clients/competitors';

const BenchmarkApproachabilityReport = React.lazy(() =>
  import('./benchmarks/clients/reports/approachability').then((module) => ({
    default: module.BenchmarkApproachabilityReport,
  }))
);

const BenchmarkCompetitorReport = React.lazy(() =>
  import('./benchmarks/clients/reports/competitors/competitorReport').then(
    (module) => ({
      default: module.default,
    })
  )
);

const BenchmarkOwnershipReport = React.lazy(() =>
  import('./benchmarks/clients/reports/ownership').then((module) => ({
    default: module.BenchmarkOwnershipReport,
  }))
);

const BenchmarkPerformanceReport = React.lazy(() =>
  import('./benchmarks/clients/reports/performance').then((module) => ({
    default: module.BenchmarkPerformanceReport,
  }))
);

const BenchmarkSearchReport = React.lazy(() =>
  import('./benchmarks/clients/reports/search/searchReport').then((module) => ({
    default: module.default,
  }))
);

const BenchmarkSlides = React.lazy(() =>
  import('./benchmarks/clients/slides/slides').then((module) => ({
    default: module.default,
  }))
);

import Login from './auth/login';
import SignOut from './auth/signOut';
import {
  BenchmarkIndustryList,
  BenchmarkIndustryNew,
} from './benchmarks/industries';
import {
  BenchmarkSectorsList,
  BenchmarkSectorsNew,
} from './benchmarks/sectors';
import {
  BenchmarkSubSectorsList,
  BenchmarkSubSectorsNew,
} from './benchmarks/subSectors';
import { BenchmarkVendorDetail } from './benchmarks/vendors';
import { ConfigAll, ConfigList, ConfigNew } from './config';
import ConfigNotificationTemplateDetail from './config/notification-template/detail';
import ConfigNotificationTemplateList from './config/notification-template/list';
import ConfigNotificationTemplateNew from './config/notification-template/new';
import ConfigSidebar from './config/sidebar/manage';
import {
  ContentCollectionDelete,
  ContentCollectionDetail,
  ContentCollectionEdit,
  ContentCollectionList,
  ContentCollectionNew,
  ContentRecordDelete,
  ContentRecordDetail,
  ContentRecordEdit,
  ContentRecordNew,
} from './content';
import Dashboard from './dashboard';
import { UserIdentityDetail, UserIdentityList } from './identity';
import { JobDetail, JobEdit, JobList } from './jobs';
import JobNew from './jobs/new';
import { NotificationList, NotificationNew } from './notifications';
import Page404 from './page404';
import Page500 from './page500';
import {
  ProspectDetail,
  ProspectList,
  ProspectMatch,
  ProspectNew,
} from './prospects';
import {
  ConfigCustomDashList,
  CustomDashboard,
  NewDashboard,
  QueryExplorer,
  ReportAcquisition,
  ReportDebug,
  ReportDetail,
  ReportEngagement,
  ReportList,
  ReportOverview,
  ReportRetention,
} from './reports';
import { SegmentDetail, SegmentList, SegmentNew } from './segments';
import Setup from './setup';
import { TriggerList } from './triggers';
import TriggerDetail from './triggers/detail';
import NewTrigger from './triggers/new';
import { UserDetail, UserInviteList, UserInviteNew, UserList } from './users';
import UserExport from './users/export';
import {
  UserGroupAdd,
  UserGroupDetail,
  UserGroupEdit,
  UserGroupList,
  UserGroupNew,
} from './users/groups';
import Welcome from './welcome';

const BaseAdmin = ({ children = <></> }) => {
  const { config, mounted, isAuthenticated, isFirstAdmin } =
    useBlueprintAdmin();

  return (
    <>
      {mounted && (
        <>
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/auth/login' component={Login} />
            <Route exact path='/auth/signout' component={SignOut} />
            <Route exact path='/setup' component={Setup} />
            <Route exact path='/welcome' component={Welcome} />

            {/* Enable passed routes for a custom installation */}
            {children}

            {/* content */}
            <Route path='/content/collections/:collectionId/records/:recordId/edit'>
              <ContentRecordEdit />
            </Route>
            <Route path='/content/collections/:collectionId/records/:recordId/delete'>
              <ContentRecordDelete />
            </Route>
            <Route path='/content/collections/:collectionId/records/new'>
              <ContentRecordNew />
            </Route>
            <Route path='/content/collections/:collectionId/records/:recordId'>
              <ContentRecordDetail />
            </Route>
            <Route path='/content/collections/:collectionId/edit'>
              <ContentCollectionEdit />
            </Route>
            <Route path='/content/collections/:collectionId/delete'>
              <ContentCollectionDelete />
            </Route>
            <Route path='/content/collections/new'>
              <ContentCollectionNew />
            </Route>
            <Route path='/content/collections/:collectionId'>
              <ContentCollectionDetail />
            </Route>
            <Route path='/content'>
              <ContentCollectionList />
            </Route>

            {/* admin group */}
            <Route path='/admins/group/new'>
              <AdminGroupNew formSchema={adminGroupSchema} />
            </Route>
            <Route path='/admins/group/:id/edit'>
              <AdminGroupEdit />
            </Route>
            <Route path='/admins/group/:id'>
              <AdminGroupDetail />
            </Route>

            {/* admin */}
            <Route path='/admins/:id/edit'>
              <AdminEdit />
            </Route>
            <Route path='/admins/service/:id/edit'>
              <AdminServiceEdit />
            </Route>
            <Route path='/admins/activity'>
              <AdminActivity />
            </Route>
            <Route path='/admins/service/new'>
              <AdminServiceNew />
            </Route>
            <Route path='/admins/service/:id'>
              <AdminServiceDetail />
            </Route>
            <Route path='/admins/new'>
              <AdminNew uiSchema={adminUiSchema} />
            </Route>
            <Route path='/admins/:id'>
              <AdminDetail />
            </Route>
            <Route path='/admins'>
              <AdminList />
            </Route>

            {/* user group */}
            <Route path='/users/group/new'>
              <UserGroupNew formSchema={userGroupSchema} />
            </Route>
            <Route path='/users/group/:id/add'>
              <UserGroupAdd />
            </Route>
            <Route path='/users/group/:id/edit'>
              <UserGroupEdit />
            </Route>
            <Route path='/users/group/:id'>
              <UserGroupDetail />
            </Route>
            <Route path='/users/group'>
              <UserGroupList />
            </Route>

            {/* user */}
            <Route path='/identity/:id'>
              <UserIdentityDetail />
            </Route>
            <Route path='/identity'>
              <UserIdentityList />
            </Route>
            <Route path='/users/export'>
              <UserExport />
            </Route>
            <Route path='/users/invites/new'>
              <UserInviteNew
                formSchema={inviteSchema}
                uiSchema={inviteUiSchema}
              />
            </Route>
            <Route path='/users/invites'>
              <UserInviteList />
            </Route>
            <Route exact path='/users/:id'>
              <UserDetail />
            </Route>
            <Route path='/users'>
              <UserList />
            </Route>

            {/* prospects */}
            <Route path='/prospects/new'>
              <ProspectNew />
            </Route>
            <Route path='/prospects/match/:id'>
              <ProspectMatch />
            </Route>
            <Route path='/prospects/:id'>
              <ProspectDetail />
            </Route>
            <Route path='/prospects'>
              <ProspectList />
            </Route>

            {/* triggers */}
            <Route path='/triggers/new'>
              <NewTrigger />
            </Route>
            <Route path='/triggers/:id'>
              <TriggerDetail />
            </Route>
            <Route path='/triggers'>
              <TriggerList />
            </Route>

            {/* assets */}
            <Route path='/assets/new'>
              <AssetCreate />
            </Route>
            <Route path='/assets/:id'>
              <AssetDetail />
            </Route>
            <Route path='/assets'>
              <AssetList />
            </Route>

            {/* jobs */}
            <Route path='/jobs/:id/edit'>
              <JobEdit />
            </Route>
            <Route path='/jobs/new'>
              <JobNew />
            </Route>
            <Route path='/jobs/:id'>
              <JobDetail />
            </Route>
            <Route path='/jobs'>
              <JobList />
            </Route>

            {/* notifications */}
            <Route path='/notifications/new'>
              <NotificationNew />
            </Route>
            <Route path='/notifications'>
              <NotificationList />
            </Route>

            {/* segments */}
            <Route path='/segments/new'>
              <SegmentNew />
            </Route>
            <Route path='/segments/:id'>
              <SegmentDetail />
            </Route>
            <Route path='/segments'>
              <SegmentList />
            </Route>

            {/* notification template configurations */}
            <Route path='/config/notification-template/new'>
              <ConfigNotificationTemplateNew />
            </Route>
            <Route path='/config/notification-template/:id'>
              <ConfigNotificationTemplateDetail />
            </Route>
            <Route path='/config/notification-template'>
              <ConfigNotificationTemplateList />
            </Route>

            {/* config configurations */}
            <Route path='/config/all'>
              <ConfigAll />
            </Route>

            {/* beacon configurations */}
            <Route path='/beacons/new'>
              <BeaconNew />
            </Route>
            <Route path='/beacons/:id/destinations/new'>
              <DestinationsNew />
            </Route>
            <Route path='/beacons/:id'>
              <BeaconDetail />
            </Route>
            <Route path='/beacons'>
              <BeaconList />
            </Route>

            {/* configurations */}
            <Route path='/config/sidebar'>
              <ConfigSidebar />
            </Route>
            <Route path='/config/new'>
              <ConfigNew />
            </Route>
            <Route path='/config'>
              <ConfigList />
            </Route>

            {/* reports */}
            <Route path='/reports/detail'>
              <ReportDetail />
            </Route>
            <Route path='/reports/overview'>
              <ReportOverview />
            </Route>
            <Route path='/reports/debug'>
              <ReportDebug />
            </Route>
            <Route path='/reports/acquisition'>
              <ReportAcquisition />
            </Route>
            <Route path='/reports/engagement'>
              <ReportEngagement />
            </Route>
            <Route path='/reports/retention'>
              <ReportRetention />
            </Route>
            <Route path='/reports/querybuilder/:id'>
              <QueryExplorer />
            </Route>
            <Route path='/reports/queryeditor/:id/:itemid'>
              <QueryExplorer />
            </Route>
            <Route path='/reports/customdashboard/:id'>
              <CustomDashboard />
            </Route>
            <Route path='/reports/newdashboard'>
              <NewDashboard />
            </Route>
            <Route path='/reports/custom/list'>
              <ConfigCustomDashList />
            </Route>
            <Route path='/reports'>
              <ReportList />
            </Route>

            {/* benchmarks */}

            <Route path='/benchmarks/actions'>
              <BenchmarkActions />
            </Route>
            <Route path='/benchmarks/browse/industry/:industryName'>
              <BenchmarkBrowseByIndustry />
            </Route>
            <Route path='/benchmarks/vendors/:vendorId'>
              <BenchmarkVendorDetail />
            </Route>
            <Route path='/benchmarks/industries/new'>
              <BenchmarkIndustryNew />
            </Route>
            <Route path='/benchmarks/industries'>
              <BenchmarkIndustryList />
            </Route>
            <Route path='/benchmarks/sectors/new'>
              <BenchmarkSectorsNew />
            </Route>
            <Route path='/benchmarks/sectors'>
              <BenchmarkSectorsList />
            </Route>
            <Route path='/benchmarks/sub-sectors/new'>
              <BenchmarkSubSectorsNew />
            </Route>
            <Route path='/benchmarks/sub-sectors'>
              <BenchmarkSubSectorsList />
            </Route>
            <Route path='/benchmarks/clients/scorecard'>
              <BenchmarkClientScorecard />
            </Route>
            <Route path='/benchmarks/clients/new'>
              <BenchmarkClientNew />
            </Route>

            <Route path='/benchmarks/clients/:clientId/prospects/new'>
              <BenchmarkProspectNew />
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/slides'>
              <Suspense fallback='loading...'>
                <BenchmarkSlides />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/competitor'>
              <Suspense fallback='loading...'>
                <BenchmarkCompetitorReport />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/performance'>
              <Suspense fallback='loading...'>
                <BenchmarkPerformanceReport />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/approachability'>
              <Suspense fallback='loading...'>
                <BenchmarkApproachabilityReport />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/ownership'>
              <Suspense fallback='loading...'>
                <BenchmarkOwnershipReport />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/report/search'>
              <Suspense fallback='loading...'>
                <BenchmarkSearchReport />
              </Suspense>
            </Route>

            <Route path='/benchmarks/clients/:clientId/pages/new'>
              <BenchmarkClientPageNew />
            </Route>
            <Route path='/benchmarks/clients/:clientId/pages/:pageId/edit'>
              <BenchmarkClientPageEdit />
            </Route>
            <Route path='/benchmarks/clients/:clientId/pages/:pageId'>
              <BenchmarkClientPageDetail />
            </Route>
            <Route path='/benchmarks/clients/:clientId/runs/:runId'>
              <BenchmarkClientRunDetail />
            </Route>
            <Route path='/benchmarks/clients/:clientId/edit'>
              <BenchmarkClientEdit />
            </Route>
            <Route path='/benchmarks/clients/:clientId/competitors/new'>
              <BenchmarkClientCompetitorsNew />
            </Route>
            <Route path='/benchmarks/clients/inactive'>
              <BenchmarkClientListInactive />
            </Route>
            <Route path='/benchmarks/clients/:clientId'>
              <BenchmarkClientDetail />
            </Route>
            <Route path='/benchmarks/clients'>
              <BenchmarkClientList />
            </Route>
            <Route exact path='/500' component={Page500} />
            <Route component={Page404} />
          </Switch>

          {isFirstAdmin && <Redirect to='/welcome' />}
          {!isAuthenticated &&
            !isFirstAdmin &&
            location.pathname !== '/auth/callback' && (
              <Redirect to='/auth/login' />
            )}
        </>
      )}
    </>
  );
};

export default BaseAdmin;
