import { Typography } from '@mui/material';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FIXED_SIDEBAR,
  getDynamicConfig,
  ROLE_SIDEBAR,
} from '../config/dynamicConfig';
import { defaultFixed, defaultRoles } from '../config/sidebar';
import useMountedEffect from '../hooks/useMountedEffect';
import { uuidv4 } from '../utils/uuid';
import { useBlueprintAdmin } from './AdminProvider';
import AdminSidebarItem from './AdminSidebarItem';

export interface ISidebarItem {
  currentUrl: string;
  label: string;
  route?: string;
  role?: string;
  icon: string;
  items: Array<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  header: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  version: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
}));

function sidebarItemExists(array, item) {
  return array.some(function (el) {
    return el.route === item.route;
  });
}

const AdminSidebar = (props: any) => {
  const location = useLocation();
  const classes = useStyles();

  const { adminUser, isAuthenticated, config, jwtToken, adminApi } =
    useBlueprintAdmin();
  const [sidebarLoaded, setSidebarLoaded] = useState<boolean>(false);
  const [validSidebarItems, setValidSidebarItems] = useState<
    Array<ISidebarItem>
  >([]);
  const [dashboardVersion, setDashboardVersion] = useState<string>('');

  useMountedEffect(
    (mounted: boolean) => {
      // eslint-disable-next-line
      (async () => {
        if (adminUser && mounted) {
          adminApi
            ?.getDeployedServiceVersions()
            .then(({ data }) => {
              const blueprintUiAdmin = data.services.find(
                (service) => service.name === 'blueprint-ui-admin'
              );
              if (blueprintUiAdmin) {
                setDashboardVersion(blueprintUiAdmin.image.split(':')[1]);
              }
            })
            .catch((err) => {
              console.error(err);
            });

          config.sidebar.fixed = await getDynamicConfig({
            scope: 'sidebar/fixed',
            key: FIXED_SIDEBAR,
            defaultValue: defaultFixed,
            config,
            jwtToken,
          });
          config.sidebar.roleBased = await getDynamicConfig({
            scope: 'sidebar/role',
            key: ROLE_SIDEBAR,
            defaultValue: defaultRoles,
            config,
            jwtToken,
          });

          const validItems: Array<ISidebarItem> = [];

          // Add fixed items at the top of the page
          for (const fixedRole of config.sidebar.fixed) {
            validItems.push({ ...fixedRole, currentUrl: location.pathname });
          }

          if (adminUser.roles) {
            // Loop through the activeRoles identified first
            for (const activeRole of config.sidebar.roleBased) {
              // Loops through the roles that a user has in their JWT token
              for (const authorizedRoles of adminUser.roles) {
                // takes a authorizedRoles from "admin:user:*" and
                // turns it into a role of "user"
                const [, role] = authorizedRoles.split(':');

                for (const availableRole of config.sidebar.roleBasedOptions) {
                  // If the user has the role, then we'll add it to the validRoles
                  if (
                    availableRole.role === role &&
                    activeRole === availableRole.label
                  ) {
                    if (!sidebarItemExists(validItems, availableRole)) {
                      if (authorizedRoles !== 'admin:admin:base') {
                        validItems.push({
                          ...availableRole,
                          currentUrl: location.pathname,
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          setValidSidebarItems(validItems);
          setSidebarLoaded(true);
        }
      })();

      // eslint-disable-next-line
      return () => (mounted = false);
    },
    [adminUser]
  );

  return (
    <>
      {isAuthenticated && sidebarLoaded && (
        <div>
          {/* <Toolbar>
          <Typography variant="h5" className={classes.header}>Admin</Typography>
        </Toolbar>
        <Divider /> */}
          <Typography className={classes.version}>
            {`Version ${dashboardVersion}`}
          </Typography>
          <List style={{ padding: 0 }}>
            {[...validSidebarItems].map((item) => (
              <AdminSidebarItem {...item} key={uuidv4()} />
            ))}
          </List>
        </div>
      )}
    </>
  );
};

export default AdminSidebar;
