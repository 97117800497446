import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, List, Typography, Link as UiLink } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme, lighten } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ISidebarItem } from './AdminSidebar';
import DynamicIcon from './DynamicIcon';

const useStyles = makeStyles((theme: Theme) => ({
  menuLink: {
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  selectedGroup: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  menuIcon: {
    color: theme.palette.grey[600],
  },
  selectedMenuIcon: {
    color: theme.palette.common.white,
  },
  selectedMenuLink: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: theme.palette.primary.main,
  },
  menuText: {
    marginTop: theme.spacing(0.8),
    fontSize: '0.8rem',
    textTransform: 'uppercase',
  },
  selectedMenuText: {
    marginTop: theme.spacing(0.8),
    fontSize: '0.8rem',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
}));

const AdminSidebarItem = (item: ISidebarItem) => {
  const classes = useStyles();

  const hasChildren = (item) => {
    const { items: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  };

  const isActive = (item) => {
    if (!item.currentUrl) {
      return false;
    }
    if (item.currentUrl === '/') {
      return item.route === '/';
    } else {
      if (item.route === '/') {
        return false;
      } else if (item.currentUrl.includes('users/group')) {
        return item.route.includes('users/group');
      } else {
        return item.currentUrl.startsWith(item.route);
      }
    }
  };

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    const getClass = () => {
      if (isActive(item)) {
        return classes.selectedMenuLink;
      } else {
        return '';
      }
    };

    const getIconClass = () => {
      if (isActive(item)) {
        return classes.selectedMenuIcon;
      } else {
        return classes.menuIcon;
      }
    };

    return (
      <UiLink component={Link} className={getClass()} to={item.route}>
        <ListItem
          button
          key={item.label}
          className={
            isActive(item) ? classes.selectedMenuLink : classes.menuLink
          }
        >
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            style={{ height: 70 }}
          >
            {/* <Icon className={getIconClass()} fontSize="large">{item.icon}</Icon> */}
            <DynamicIcon iconName={item.icon} className={getIconClass()} />
            <Typography
              className={
                isActive(item) ? classes.selectedMenuText : classes.menuText
              }
            >
              {item.label}
            </Typography>
          </Grid>
        </ListItem>
      </UiLink>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const isSelected = isActive(item);
    const [open, setOpen] = useState(isSelected);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const getClass = () => {
      if (isSelected) {
        return classes.selectedMenuLink;
      } else {
        return '';
      }
    };

    return (
      <React.Fragment>
        <ListItem className={getClass()} button onClick={handleClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            className={isSelected ? classes.selectedMenuText : classes.menuText}
            primary={item.label}
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List
            className={isSelected ? classes.selectedGroup : ''}
            component='div'
            disablePadding
          >
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  return <MenuItem item={item} />;
};

export default AdminSidebarItem;
