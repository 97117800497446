import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  pages: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        identifier: Yup.string().required('Classification is required'),
      })
    )
    .min(1, 'Please enter at least one page'),
});

function AdminClientPageForm({ description, handleSubmit, formData }): any {
  const formik = useFormik({
    initialValues: {
      pages: [
        {
          name: formData.name ?? '',
          identifier: formData.identifier ?? '',
          url: formData.url ?? '',
        },
      ],
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  return (
    <Grid>
      <Typography
        pb={2}
        sx={{ fontWeight: 'medium', color: 'rgba(0,0,0, 0.85)' }}
      >
        {description}
      </Typography>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <TextField
                id={`pages.0.name`}
                name={`pages.0.name`}
                type='text'
                variant='outlined'
                label='Page Name*'
                autoComplete='Page Name'
                value={formik.values.pages[0].name}
                onChange={formik.handleChange}
                error={
                  !!formik.errors.pages &&
                  !!(formik.errors.pages[0] as any).name
                }
                helperText={
                  !!formik.errors.pages &&
                  !!(formik.errors.pages[0] as any).name
                }
              />
            </FormControl>
            <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
              This is a user-friendly name for the page. It could be as simple
              as "Home Page" or could be detailed based on how many pages you
              have (e.g. Product Detail Page for Household Supplies)
            </Typography>
          </Box>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <InputLabel id='classification'>Page Classification</InputLabel>
              <Select
                defaultValue=''
                id={`pages.0.identifier`}
                name={`pages.0.identifier`}
                label='Page Classification*'
                value={formik.values.pages[0].identifier}
                onChange={formik.handleChange}
              >
                <MenuItem value='Home'>Home</MenuItem>
                <MenuItem value='Product List Page(for Ecomm)'>
                  Product List Page(for Ecomm)
                </MenuItem>
                <MenuItem value='Product Detail Page (for Ecomm)'>
                  Product Detail Page (for Ecomm)
                </MenuItem>
                <MenuItem value='Content List Page (a listing of content)'>
                  Content List Page (a listing of content)
                </MenuItem>
                <MenuItem value='Content Detail Page (a specific piece of content)'>
                  Content Detail Page (a specific piece of content)
                </MenuItem>
                <MenuItem value='Other (if it doesnt fall into one of the classifications above)'>
                  Other (if it doesnt fall into one of the classifications
                  above)
                </MenuItem>
              </Select>
            </FormControl>
            <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
              This is used to compare page types across scans. The standard
              naming is 'home', 'plp', 'pdp'. If you add a different value, just
              make sure its consistent across page types
            </Typography>
          </Box>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <TextField
                disabled={formData.url}
                id={`pages.0.url`}
                name={`pages.0.url`}
                type='text'
                variant='outlined'
                label='url*'
                autoComplete='url'
                value={formik.values.pages[0].url}
                onChange={formik.handleChange}
              />
            </FormControl>
            <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
              The full URL (e.g. https://www2.deloitte.com/us/en.html)
            </Typography>
          </Box>
          <Button variant='contained' size='large' id='submit' type='submit'>
            Submit
          </Button>
        </form>
      </Box>
    </Grid>
  );
}

export default AdminClientPageForm;
