import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../components/AdminBaseForm';

const SegmentNew = () => {
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/segments/${response.data.id}`);
  };

  return (
    <AdminBaseForm
      url='/admin/segments'
      entityTitle='Segments'
      schema={{
        properties: {
          name: { type: 'string' },
          description: { type: 'string' },
          type: {
            title: 'Type',
            enum: ['user selectable', 'trigger only'],
            description:
              'Do you want it to be something that a user can select or just through triggers/admin activities',
          },
          status: {
            title: 'Status',
            enum: ['active', 'inactive'],
            description: 'Status of the segment',
          },
        },
        type: 'object',
        required: ['name', 'description', 'type', 'status'],
      }}
      uiSchema={{}}
      onSuccess={onSuccess}
    />
  );
};

export default SegmentNew;
