import { useHistory } from 'react-router-dom';
import { AdminContentCollectionEditor } from '../../../components/AdminContentCollectionEditor';
import AdminPage from '../../../components/AdminPage';

const ContentCollectionNew = () => {
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/content/collections/${response.data.id}`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  return (
    <>
      <AdminPage showSidebar={true} title={'Create Collection'}>
        <AdminContentCollectionEditor
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      </AdminPage>
    </>
  );
};

export default ContentCollectionNew;
