import { green, orange, red } from '@mui/material/colors';

export const approachabilityScore = '#6AA84F';
export const performanceScore = '#674EA7';
export const ownershipScore = '#E69138';

export const isOrIsNot = (clientScore, competitorScore) => {
  return clientScore > competitorScore ? 'is' : 'is not';
};

export const isAheadOrBehind = (clientScore, competitorScore) => {
  return clientScore > competitorScore ? 'ahead' : 'behind';
};

export const getRadialColor = (score: number): string => {
  if (score > 80) {
    return green[500];
  } else if (score >= 51 && score <= 80) {
    return orange[500];
  } else {
    return red[500];
  }
};

export const getPerformanceStatus = (
  clientScore: number
): 'needs to improve' | 'is fair' | 'is great' => {
  if (clientScore <= 50) {
    return 'needs to improve';
  } else if (clientScore >= 51 && clientScore <= 79) {
    return 'is fair';
  } else {
    return 'is great';
  }
};
