import AdminBenchmarkPerformanceChart from '../../../../components/AdminBenchmarkPerformanceChart';
import RadialChart from '../../../../components/reporting/RadialChart';
import { approachabilityScore, isOrIsNot } from '../../utils';

const SlideApproachabilityScore = ({
  client,
  clientInfo,
  allClients,
  competitors,
  competitorIds,
  clientIcon,
}) => {
  return (
    <section className='stack'>
      {/* slide 5-1 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 11 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Approachability Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={approachabilityScore}
            data-shape-stretch='true'
            style={{ zIndex: 10 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={approachabilityScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-89169f'
          style={{
            height: 'auto',
            width: '369px',
            left: '104.788px',
            top: '270.39px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgReadabilityScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgReadabilityScore
                    : allClients.avgReadabilityScore
                )}{' '}
                readable
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '355px',
            left: '104.788px',
            top: '504.88px',
          }}
          data-name='text-0983d8'
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgAccessibilityScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgAccessibilityScore
                    : allClients.avgAccessibilityScore
                )}{' '}
                accessible
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-52b277'
          style={{
            width: '500px',
            left: '529.389px',
            top: '215.15px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content'>
            <div
              id='Approachability_Readable'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgReadabilityScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgReadabilityScore
                    : allClients.avgReadabilityScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '500px',
            left: '529.389px',
            top: '450.15px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c030ab'
        >
          <div className='sl-block-content'>
            <div
              id='Approachability_Accessible'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgAccessibilityScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgAccessibilityScore
                    : allClients.avgAccessibilityScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '158px',
            left: '1111.82px',
            top: '589.63px',
          }}
          data-name='text-ad56f9'
        >
          <div
            className='sl-block-content'
            style={{ zIndex: 20, fontSize: '50%' }}
          >
            <p>What does approachability mean?</p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-8caa60'
          style={{
            width: '39px',
            height: '39px',
            left: '1172.5px',
            top: '667.37px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='arrow-down'
            data-shape-fill-color='#000000'
            data-shape-stretch='true'
            style={{ zIndex: 21 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 39 39'
            >
              <polygon
                points='19.5,39 39,19.5 27.299999999999997,19.5 27.299999999999997,0 11.7,0 11.7,19.5 0,19.5 19.5,39'
                className='shape-element'
                fill='#000000'
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Approachability_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgExperienceScore),
                  fill: approachabilityScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '110px',
            height: '110px',
            left: '1115.96px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c6e884'
        >
          <div
            id='Approachability_Detail_Industry'
            className='sl-block-content'
            style={{ zIndex: 26 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    Object.keys(competitors).length !== 0
                      ? competitors.avgExperienceScore
                      : allClients.avgExperienceScore
                  ),
                  fill: approachabilityScore,
                },
              ]}
            />
          </div>
        </div>
      </section>
      {/* slide 5-2 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 10 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Approachability can mean many things
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-a19e67'
          style={{
            height: 'auto',
            width: '1185px',
            left: '47.5px',
            top: '432px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 11 }}>
            <p>
              When we say{' '}
              <span style={{ color: '#008000' }}>
                <strong>Approachable</strong>
              </span>
              , we mean...
            </p>
            <p>
              How
              <strong>
                <span style={{ color: '#008000' }}>
                  &nbsp;easy do you make it{' '}
                </span>
              </strong>
              for the end-user in your digital experience?&nbsp;
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={approachabilityScore}
            data-shape-stretch='true'
            style={{ zIndex: 12 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={approachabilityScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-e0ffda'
          style={{
            height: 'auto',
            width: '600px',
            left: '340px',
            top: '208px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p>
              <em>
                <span style={{ fontSize: '0.5em' }}>
                  Approachability is not often thought about for digital
                  experiences, but it's essential
                </span>
              </em>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '340px',
            top: '249px',
          }}
          data-name='text-fee81b'
        >
          <div className='sl-block-content' style={{ zIndex: 14 }}>
            <p>
              <em>
                <span style={{ fontSize: '0.5em' }}>
                  Experiences need to be easy and comprehendible or your users
                  will become frustrated.
                </span>
              </em>
            </p>
          </div>
        </div>
      </section>
      {/* slide 5-3 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 11 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Our Definition of Approachability
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={approachabilityScore}
            data-shape-stretch='true'
            style={{ zIndex: 10 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={approachabilityScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-235ae9'
          style={{
            height: 'auto',
            width: '600px',
            left: '0px',
            top: '192.5px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p>
              <span style={{ color: '#008000' }}>
                <strong>
                  <span style={{ fontSize: '0.9em' }}>The Experience</span>
                </strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '680px',
            top: '192.5px',
          }}
          data-name='text-8cb6ed'
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p>
              <span style={{ color: '#DAA520' }}>
                <span style={{ fontSize: '0.9em' }}>
                  <strong>The Meaning</strong>
                </span>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-c26bc7'
          style={{
            height: 'auto',
            width: '269px',
            left: '176px',
            top: '292px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 14 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>readable?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '284px',
            left: '177.25px',
            top: '495px',
          }}
          data-name='text-41ecf8'
        >
          <div className='sl-block-content' style={{ zIndex: 15 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>accessible?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-0e831a'
          style={{
            height: 'auto',
            width: '600px',
            left: '644px',
            top: '276.5px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 16 }}>
            <p>
              <span style={{ fontSize: '0.7em' }}>
                Is there too much content to comprehend?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '620px',
            left: '634px',
            top: '462px',
          }}
          data-name='text-5f5729'
        >
          <div className='sl-block-content' style={{ zIndex: 17 }}>
            <p>
              <span style={{ fontSize: '21px' }}>
                &nbsp; Is the website design and developed in an accessible
                manner?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '620px',
            left: '634px',
            top: '537px',
          }}
          data-name='text-a803c7'
        >
          <div className='sl-block-content' style={{ zIndex: 18 }}>
            <p>
              <span style={{ fontSize: '21px' }}>
                How severe are any design / dev mistakes?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '644px',
            top: '315.5px',
          }}
          data-name='text-c56d2a'
        >
          <div className='sl-block-content' style={{ zIndex: 19 }}>
            <p>
              <span style={{ fontSize: '0.7em' }}>
                How complex are the words on the page?
              </span>
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default SlideApproachabilityScore;
