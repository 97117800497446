import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminChart from '../../components/reporting/AdminChart';

const ReportDebug = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AdminPage title='Activity Debug Report'>
        <AdminChart
          title='Activity In The Last Hour'
          showViewDetail={false}
          vizState={{
            chartType: 'line',
            legend: false,
            query: {
              measures: ['Activity.count'],
              timeDimensions: [
                {
                  dimension: 'Activity.sentAt',
                  dateRange: 'from 30 minutes ago to now',
                  granularity: 'minute',
                },
              ],
              filters: [],
              dimensions: ['Activity.sentAt'],
              timezone: 'America/Los_Angeles',
            },
          }}
        />
        <AdminChart
          title='Activity Debugger'
          showViewDetail={false}
          vizState={{
            chartType: 'table',
            legend: false,
            query: {
              measures: [],
              timeDimensions: [],
              order: {
                'Activity.timestamp': 'desc',
              },
              filters: [],
              dimensions: [
                'Activity.event',
                'Activity.timestamp',
                'Activity.hostname',
                'Activity.id',
              ],
              limit: 100,
            },
          }}
        />
      </AdminPage>
    </CubeProvider>
  );
};

export default ReportDebug;
