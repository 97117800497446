import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminForm from '../../../components/AdminForm';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { runPreSaveConditions } from './shared';

const ContentRecordEdit = () => {
  const { collectionId, recordId } =
    useParams<{ collectionId: string; recordId: string }>();
  const { adminAssetApi, adminContentApi } = useBlueprintAdmin();
  const [collection, setCollection] = useState<any>(undefined);
  const history = useHistory();

  const onSubmit = async ({ formData }, e) => {
    // If this collection didnt include a status, we'll just go straight to a published status
    if (!formData.status) {
      formData.status = 'published';
    }

    runPreSaveConditions(formData);

    const response = await adminContentApi!.updateCollectionRecord(
      collectionId,
      recordId,
      formData
    );

    if (response.success) {
      history.push(`/content/collections/${collectionId}`);
    } else {
      console.error(response);
    }
  };

  useEffect(() => {
    if (collectionId && adminContentApi) {
      adminContentApi
        .getCollection(collectionId)
        .then(({ data }) => {
          setCollection(data);
        })
        .catch((err) => console.error(err));
    }
  }, [collectionId]);

  return (
    <>
      {collection && recordId && (
        <AdminPage
          title={`Edit Record: ${recordId}`}
          animation={true}
          deleteAction={`/content/collections/${collectionId}/records/${recordId}/delete`}
        >
          <AdminCard>
            <AdminForm
              url={`/admin/content/collections/${collectionId}/records/${recordId}`}
              id={recordId}
              dataKey='content'
              uiSchema={collection.uiSchema ? collection.uiSchema : {}}
              schema={collection.schema}
              onSubmit={onSubmit}
            />
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};

export default ContentRecordEdit;
