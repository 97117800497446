import { ConfigUserAPI } from '@deloitte/blueprint-sdk-admin';
export const ROLE_SIDEBAR = 'bp-admin-sidebar-role';
export const FIXED_SIDEBAR = 'bp-admin-sidebar-fixed';

// This needs to include all of the configs above
const configs = [ROLE_SIDEBAR, FIXED_SIDEBAR];

export const clearConfigCache = () => {
  for (const config of configs) {
    localStorage.removeItem(config);
  }
};

export interface IDynamicConfig {
  scope: string; // e.g sidebar/fixed
  config: any;
  jwtToken: string | undefined;
  defaultValue: any;
  key: string;
}

export const getDynamicConfig = async ({
  scope,
  config,
  jwtToken,
  defaultValue,
  key,
}: IDynamicConfig): Promise<string> => {
  // Get the value from localStorage first, if it exists
  const cachedValue = localStorage.getItem(key);

  // We have it cached, let's return it back to the calling function
  if (cachedValue) {
    return JSON.parse(cachedValue);
  } else {
    let returnValue = defaultValue;

    try {
      // The config doesnt exist in cache, we'll need to go get it
      const configApi = new ConfigUserAPI(config);
      const valueFromApi = await configApi.requestScope(scope);
      if (valueFromApi.data && valueFromApi.data.value) {
        returnValue = valueFromApi.data.value;
      }
    } catch (error) {
      console.error("config couldn't be set, using default");
    }

    localStorage.setItem(key, JSON.stringify(returnValue));
    return returnValue;
  }
  return '';
};
