export const approachabilityExcelMapping: any = [
  { value: 'id', viewValue: 'ID' },
  { value: 'id', viewValue: 'Run ID' },
  { value: { client: 'id' }, viewValue: 'Client ID' },
  { value: 'accessibilityScore', viewValue: 'Accessibility Score' },
  { value: 'experienceScore', viewValue: 'Experience Score' },
  { value: 'accessibilityErrors', viewValue: 'Accessibility Errors' },
  { value: 'accessibilityWarnings', viewValue: 'Accessibility Warnings' },
  { value: 'accessibilityNotices', viewValue: 'Accessibility Notices' },
  { value: 'pageIdentifier', viewValue: 'Page Identifier' },
  { value: 'readabilityLexiconCount', viewValue: 'Readability Lexicon Count' },
  {
    value: 'readabilityDifficultWordCount',
    viewValue: 'Readability Difficult Word Count',
  },
  { value: 'readabilityDifficulty', viewValue: 'Readability Difficulty' },
  { value: 'readabilityScore', viewValue: 'Readability Score' },
  { value: 'fleschReadingEase', viewValue: 'Flesch Reading Ease' },
];
export const performanceExcelMapping: any = [
  { value: 'id', viewValue: 'ID' },
  { value: 'id', viewValue: 'Run ID' },
  { value: { client: 'id' }, viewValue: 'Client ID' },
  { value: { properties: 'labFcpScore' }, viewValue: 'Lab FCP' },
  { value: { properties: 'cruxFcpScore' }, viewValue: 'Crux FCP' },
  { value: { properties: 'labLcpScore' }, viewValue: 'Lab LCP' },
  { value: { properties: 'cruxLcpScore' }, viewValue: 'Crux LCP' },
  { value: { properties: 'labClsScore' }, viewValue: 'Lab CLS' },
  { value: { properties: 'cruxClsScore' }, viewValue: 'Crux CLS' },
  { value: { properties: 'labTtiScore' }, viewValue: 'Lab TTI' },
  { value: { properties: 'labFidScore' }, viewValue: 'Crux FID' },
  { value: { properties: 'labTbtScore' }, viewValue: 'Lab TBT' },
  { value: { properties: 'labSiScore' }, viewValue: 'Lab SI' },
];
export const ownershipExcelMapping: any = [
  { value: 'id', viewValue: 'ID' },
  { value: 'id', viewValue: 'Run ID' },
  { value: { client: 'id' }, viewValue: 'Client ID' },
  { value: 'listenersMouse', viewValue: 'listenersMouse' },
  { value: 'listenersSensor', viewValue: 'listenersSensor' },
  { value: 'listenersKeyboard', viewValue: 'listenersKeyboard' },
  { value: 'listenersTouch', viewValue: 'listenersTouch' },
  { value: 'fingerprinters', viewValue: 'fingerprinters' },
  {
    value: 'firstPartyDomains',
    viewValue: 'Total firstPartyDomains',
    optionalLength: { properties: 'firstPartyDomains' },
  },
  {
    value: 'firstPartyCookies',
    viewValue: 'Total firstPartyCookies',
    optionalLength: { properties: 'firstPartyCookies' },
  },
  {
    value: 'thirdPartyDomains',
    viewValue: 'Total thirdPartyDomains',
    optionalLength: { properties: 'thirdPartyDomains' },
  },
  {
    value: 'thirdPartyCookies',
    viewValue: 'Total thirdPartyCookies',
    optionalLength: { properties: 'thirdPartyCookies' },
  },
  {
    value: { properties: 'thirdPartyTrackers' },
    viewValue: 'thirdPartyTrackers',
  },
  { value: 'ownershipTrustScore', viewValue: 'ownershipTrustScore' },
  { value: 'ownershipIdentityScore', viewValue: 'ownershipIdentityScore' },
  { value: 'ownershipScore', viewValue: 'ownershipScore' },
  {
    value: { properties: 'firstPartyDomains' },
    viewValue: 'firstPartyDomains',
  },
  {
    value: { properties: 'firstPartyCookies' },
    viewValue: 'firstPartyCookies',
  },
  {
    value: { properties: 'thirdPartyDomains' },
    viewValue: 'thirdPartyDomains',
  },
  {
    value: { properties: 'thirdPartyCookies' },
    viewValue: 'thirdPartyCookies',
  },
  { value: { properties: 'syllableCount' }, viewValue: 'Vendors' },
];
export const searchExcelMapping: any = [
  { value: 'id', viewValue: 'ID' },
  { value: 'id', viewValue: 'Run ID' },
  { value: { client: 'id' }, viewValue: 'Client ID' },
  { value: 'organicTraffic', viewValue: 'Organic Traffic' },
  { value: 'referringDomains', viewValue: '# Referring Domains' },
  { value: 'adwordsTraffic', viewValue: 'Ads Traffic' },
  { value: 'adwordsCost', viewValue: 'Ads Cost' },
];
