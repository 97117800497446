/* eslint-disable react/display-name */
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Moment from 'react-moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminRecord from '../../components/AdminRecord';
import { AdminTableRow } from '../../components/AdminTable';
import { AdminToastTimed } from '../../components/AdminToasts';
import { uuidv4 } from '../../utils/uuid';

const AdminDetail = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location: any = useLocation();

  const getContent = (content: any) => {
    if (typeof content === 'object' && content !== null) {
      if (content.length > 0 && typeof content[0] !== 'object') {
        return content.join('; ');
      } else {
        // admin groups which is an array of objects
        return content.map((x) => x.name).join('; ');
      }
    } else {
      return typeof content === 'boolean' ? (content ? 'Yes' : 'No') : content;
    }
  };

  const itemTemplate = (data) => (
    <AdminCard title='Account'>
      <Table>
        <TableBody>
          {Object.entries(data).map(([key, value]: any) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell data-testid={key}>{getContent(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AdminCard>
  );

  const activityTemplate = (data, index) => (
    <AdminTableRow key={index}>
      <TableCell>
        <Moment fromNow>{data.createdAt}</Moment>
      </TableCell>
      <TableCell>{data.action}</TableCell>
      <TableCell>{data.entityType}</TableCell>
      <TableCell>{data.entityId}</TableCell>
      <TableCell>{JSON.stringify(data.properties)}</TableCell>
    </AdminTableRow>
  );

  return (
    <>
      {location.state?.detail === 'newAdmin' && (
        <AdminToastTimed
          message={'Admin successfully created'}
          trigger={location.state?.detail === 'newAdmin' ? uuidv4() : null}
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}

      <AdminPage title={`Admin Detail`} editAction={`/admins/${id}/edit`}>
        <AdminRecord url={`/admin/admins/${id}`} itemTemplate={itemTemplate} />
        {/* <AdminPaginatedList
          exportEnabled={true}
          title='Recent Activity'
          url={`/admin/activity/admin/${id}`}
          columns={['timestamp', 'action', 'entity', 'id', 'properties']}
          itemTemplate={activityTemplate}
          pageSize={10}
          showCard={true}
          infiniteLoad={false}
        /> */}
      </AdminPage>
    </>
  );
};

export default AdminDetail;
