import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import AdminBenchmarkPerformanceChart from '../../../../components/AdminBenchmarkPerformanceChart';
import RadialChart from '../../../../components/reporting/RadialChart';
import { isOrIsNot, ownershipScore } from '../../utils';

const SlideOwnershipScore = ({
  client,
  allClients,
  vendors,
  competitors,
  clientIcon,
}) => {
  return (
    <section className='stack'>
      {/* slide 7-1 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 12 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Ownership Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={ownershipScore}
            data-shape-stretch='true'
            style={{ zIndex: 11 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={ownershipScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={ownershipScore}
            data-shape-stretch='true'
            style={{ zIndex: 11 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={ownershipScore}
              />
            </svg>
          </div>
        </div>
        {/* Identity Score */}
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-89169f'
          style={{
            height: 'auto',
            width: '369px',
            left: '104.788px',
            top: '290.39px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgOwnershipIdentityScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgOwnershipIdentityScore
                    : allClients.avgOwnershipIdentityScore
                )}{' '}
                strong at identification
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-52b277'
          style={{
            width: '500px',
            left: '529.389px',
            top: '249.15px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content'>
            <div
              id='Approachability_Readable'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
                paddingBottom: '100px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgOwnershipIdentityScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgOwnershipIdentityScore
                    : allClients.avgOwnershipIdentityScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Approachability_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgOwnershipScore),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '110px',
            height: '110px',
            left: '1115.96px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c6e884'
        >
          <div
            id='Approachability_Detail_Industry'
            className='sl-block-content'
            style={{ zIndex: 26 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    Object.keys(competitors).length !== 0
                      ? competitors.avgOwnershipScore
                      : allClients.avgOwnershipScore
                  ),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
        {/* Trust Score */}
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-89169f'
          style={{
            height: 'auto',
            width: '369px',
            left: '104.788px',
            top: '505.39px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgOwnershipTrustScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgOwnershipTrustScore
                    : allClients.avgOwnershipTrustScore
                )}{' '}
                trustworthy
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-52b277'
          style={{
            width: '500px',
            left: '529.389px',
            top: '449.15px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content'>
            <div
              id='Approachability_Readable'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
                paddingBottom: '100px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgOwnershipTrustScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgOwnershipTrustScore
                    : allClients.avgOwnershipTrustScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Approachability_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgOwnershipScore),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
      </section>
      {/* slide 7-2 (Identity) */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 12 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Identity Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={ownershipScore}
            data-shape-stretch='true'
            style={{ zIndex: 11 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={ownershipScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-652a97'
          style={{
            height: 'auto',
            width: '900px',
            left: '94px',
            top: '140px',
          }}
        >
          <div
            className='sl-block-content'
            style={{
              zIndex: 19,
              lineHeight: '0.65',
              fontSize: '75%',
            }}
            data-has-line-height
            dir='ui'
          >
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>First Party Domains</strong>
              </span>
            </p>
            <div
              id='Ownership_1stPartyDomains'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgFirstPartyDomains /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxFirstPartyDomains
                      : allClients.maxFirstPartyDomains)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgFirstPartyDomains
                    : allClients.avgFirstPartyDomains) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxFirstPartyDomains
                      : allClients.maxFirstPartyDomains)) *
                    100
                )}
                clientLabel={client.avgFirstPartyDomains}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgFirstPartyDomains
                    : allClients.avgFirstPartyDomains
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Third Party Domains</strong>
              </span>
            </p>
            <div
              id='Ownership_3rdPartyDomains'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgThirdPartyDomains /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxThirdPartyDomains
                      : allClients.maxThirdPartyDomains)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyDomains
                    : allClients.avgThirdPartyDomains) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxThirdPartyDomains
                      : allClients.maxThirdPartyDomains)) *
                    100
                )}
                clientLabel={client.avgThirdPartyDomains}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyDomains
                    : allClients.avgThirdPartyDomains
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Third Party Cookies</strong>
              </span>
            </p>
            <div
              id='Ownership_3rdPartyCookies'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgThirdPartyCookies /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxThirdPartyCookies
                      : allClients.maxThirdPartyCookies)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyCookies
                    : allClients.avgThirdPartyCookies) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxThirdPartyCookies
                      : allClients.maxThirdPartyCookies)) *
                    100
                )}
                clientLabel={client.avgThirdPartyCookies}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyCookies
                    : allClients.avgThirdPartyCookies
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>3rd Party Trackers</strong>
              </span>
            </p>
            <div
              id='Ownership_3rdPartyTrackers'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgThirdPartyTrackers}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyTrackers
                    : allClients.avgThirdPartyTrackers) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxThirdPartyTrackers
                      : allClients.maxThirdPartyTrackers)) *
                    100
                )}
                clientLabel={client.avgThirdPartyTrackers}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgThirdPartyTrackers
                    : allClients.avgThirdPartyTrackers
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Approachability_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgOwnershipIdentityScore),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '110px',
            height: '110px',
            left: '1115.96px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c6e884'
        >
          <div
            id='Approachability_Detail_Industry'
            className='sl-block-content'
            style={{ zIndex: 26 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    Object.keys(competitors).length !== 0
                      ? competitors.avgOwnershipIdentityScore
                      : allClients.avgOwnershipIdentityScore
                  ),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
      </section>
      {/* slide 7-3 (Trust) */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 12 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Trust Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={ownershipScore}
            data-shape-stretch='true'
            style={{ zIndex: 11 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={ownershipScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '900px',
            left: '94px',
            top: '140px',
          }}
          data-name='text-c7151d'
        >
          <div
            className='sl-block-content'
            style={{
              zIndex: 21,
              lineHeight: '0.65',
              fontSize: '75%',
            }}
            data-has-line-height
            dir='ui'
          >
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Fingerprinters</strong>
              </span>
            </p>
            <div
              id='Ownership_Fingerprinters'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgFingerprinters /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxFingerprinters
                      : allClients.maxFingerprinters)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgFingerprinters
                    : allClients.avgFingerprinters) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxFingerprinters
                      : allClients.maxFingerprinters)) *
                    100
                )}
                clientLabel={client.avgFingerprinters}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgFingerprinters
                    : allClients.avgFingerprinters
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Mouse Listeners</strong>
              </span>
            </p>
            <div
              id='Ownership_MouseListeners'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgListenersMouse /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersMouse
                      : allClients.maxListenersMouse)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgListenersMouse
                    : allClients.avgListenersMouse) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersMouse
                      : allClients.maxListenersMouse)) *
                    100
                )}
                clientLabel={client.avgListenersMouse}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgListenersMouse
                    : allClients.avgListenersMouse
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Keyboard Listeners</strong>
              </span>
            </p>
            <div
              id='Ownership_KeyboardListeners'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgListenersKeyboard /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersKeyboard
                      : allClients.maxListenersKeyboard)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgListenersKeyboard
                    : allClients.avgListenersKeyboard) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersKeyboard
                      : allClients.maxListenersKeyboard)) *
                    100
                )}
                clientLabel={client.avgListenersKeyboard}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgListenersKeyboard
                    : allClients.avgListenersKeyboard
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                <strong>Sensor Listeners</strong>
              </span>
            </p>
            <div
              id='Ownership_SensorListeners'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '65px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={Math.round(
                  (client.avgListenersSensor /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersSensor
                      : allClients.maxListenersSensor)) *
                    100
                )}
                competitorAvg={Math.round(
                  ((Object.keys(competitors).length !== 0
                    ? competitors.avgListenersSensor
                    : allClients.avgListenersSensor) /
                    (Object.keys(competitors).length !== 0
                      ? competitors.maxListenersSensor
                      : allClients.maxListenersSensor)) *
                    100
                )}
                clientLabel={client.avgListenersSensor}
                avgLabel={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgListenersSensor
                    : allClients.avgListenersSensor
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Approachability_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgOwnershipTrustScore),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '110px',
            height: '110px',
            left: '1115.96px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c6e884'
        >
          <div
            id='Approachability_Detail_Industry'
            className='sl-block-content'
            style={{ zIndex: 26 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    Object.keys(competitors).length !== 0
                      ? competitors.avgOwnershipTrustScore
                      : allClients.avgOwnershipTrustScore
                  ),
                  fill: ownershipScore,
                },
              ]}
            />
          </div>
        </div>
      </section>
      {/* slide 7-4 */}
      <section>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color='rgb(230, 145, 56)'
            data-shape-stretch='true'
            style={{ zIndex: 10 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill='rgb(230, 145, 56)'
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-a86377'
          style={{
            height: 'auto',
            width: '462.387px',
            left: '81px',
            top: '180px',
          }}
          data-auto-animate-duration={0}
        >
          <div
            className='sl-block-content'
            style={{
              zIndex: 11,
              textAlign: 'left',
              fontSize: '40%',
            }}
          >
            <Table>
              <TableBody>
                {vendors?.map((vendorCategory, index) => (
                  <TableRow key={index}>
                    <TableCell>{vendorCategory.category}</TableCell>
                    <TableCell>
                      {vendorCategory.vendors?.map((vendor, vendorIndex) => (
                        <div key={vendorIndex}>
                          {vendor.name}
                          <br />
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 12 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Ownership Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '457px',
            left: '81px',
            top: '127.5px',
          }}
          data-name='text-890938'
        >
          <div
            className='sl-block-content'
            style={{ zIndex: 13, fontSize: '65%' }}
          >
            <h3 style={{ textAlign: 'left' }}>VENDORS</h3>
          </div>
        </div>
      </section>
    </section>
  );
};

export default SlideOwnershipScore;
