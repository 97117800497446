import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AdminModal from './AdminModal';
import { decodeJwt } from './AdminProvider';

const SessionTimeoutModal = () => {
  const history = useHistory();
  const location = useLocation();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem(`bp-admin`);

    if (accessToken) {
      const decodedToken = decodeJwt(accessToken);
      const expiry = decodedToken.exp;

      const timer = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const isExpired = expiry < now;
        if (
          isExpired &&
          location.pathname !== '/' &&
          location.pathname !== '/auth/login' &&
          location.pathname !== '/setup' &&
          !location.pathname.startsWith('/public') &&
          location.pathname !== '/welcome'
        ) {
          console.error('Your session has been timed out. Please log back in.');
          localStorage.setItem('bp-timeout-location', location.pathname);
          setTimeoutModalOpen(true);
          clearInterval(timer);
        }
      }, 1000);
    }
  }, []);

  const closeSessionModal = () => {
    setTimeoutModalOpen(false);
    history.push(`/auth/login`);
  };

  const sessionTimeoutBody = () => {
    return (
      <div className='SessionTimout__body' style={{ textAlign: 'center' }}>
        <p>Your session has timed out. Please log back in.</p>
        <Button
          variant='outlined'
          color='primary'
          aria-label='re-route to login page'
          style={{ margin: '10px 0 0' }}
          onClick={closeSessionModal}
        >
          Log In
        </Button>
      </div>
    );
  };

  return (
    <>
      {timeoutModalOpen && (
        <AdminModal
          type='dialog'
          size='sm'
          title='Session Timeout'
          body={sessionTimeoutBody()}
          isOpen={timeoutModalOpen}
          closeModal={closeSessionModal}
        ></AdminModal>
      )}
    </>
  );
};

export default SessionTimeoutModal;
