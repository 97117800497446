import { roles } from '../config/roles';

export const uiSchema = {
  multipleChoicesList: {
    'ui:widget': 'checkboxes',
  },
  password: { 'ui:widget': 'password' },
  confirmPassword: { 'ui:widget': 'password' },
};

export const schema = {
  description: 'Creating an admin',
  type: 'object',
  properties: {
    username: {
      type: 'string',
      title: 'Username',
      description: 'The username (email) used for login',
    },
    password: {
      type: 'string',
      title: 'Password',
      description: 'The password used for login',
    },
    confirmPassword: {
      type: 'string',
      title: 'Confirm Password',
      description: 'Confirm the password used for login',
    },
    roles: {
      type: 'array',
      title: 'Roles',
      items: {
        title: 'Role',
        type: 'string',
        anyOf: roles,
      },
      description: 'Roles to be applied to this administrator account',
    },
  },
};
