import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

const Item = ({ content, itemTemplate }: any) => {
  if (!content.length) {
    return content;
  }

  return content.map((item: any) => itemTemplate(item));
};

function AdminRecord({
  url,
  loadingTemplate,
  itemTemplate,
  form = false,
}: any) {
  const [content, setContent] = useState(null);
  const type = Array.isArray(content) ? 'array' : typeof content;
  const { config, logout } = useBlueprintAdmin();
  const api = new AdminScopedAPI(config, 'AdminRecord');

  const loadItems = async () => {
    const response = await api.get({ path: url });

    if (response.success) {
      setContent(response.data);
    } else {
      console.log(response);
      if (response.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    loadItems().catch((error) => {
      console.log(error);
    });
  }, []);

  const skeleton = () => {
    if (loadingTemplate) {
      return loadingTemplate;
    } else {
      return <Skeleton height={400} width='100%'></Skeleton>;
    }
  };

  if (!content) {
    return skeleton();
  }
  if (form) {
    return itemTemplate(content);
  }

  return type === 'array'
    ? Item({ content, itemTemplate })
    : itemTemplate(content);
}

export default AdminRecord;
