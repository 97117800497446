import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import DashboardPage from './DashboardPage';
const CustomDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [dashboard, SetDashboard] = useState<any>(null);

  const { config, jwtToken, adminDashboardApi } = useBlueprintAdmin();

  useEffect(() => {
    adminDashboardApi!
      .getDashboard(id)
      .then(({ data }) => {
        SetDashboard(data);
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <AdminPage
      animation={true}
      title={dashboard?.name}
      newAction={`/reports/querybuilder/${dashboard ? dashboard?.id : ''}`}
      newActionText='New Dashboard Tile'
    >
      <DashboardPage dashBoard={dashboard}></DashboardPage>
    </AdminPage>
  );
};

export default CustomDashboard;
