const SlideThree = () => {
  return (
    <section>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '408.388px',
          left: '104.612px',
          top: '101.807px',
          height: 'auto',
        }}
        data-name='text-c29943'
      >
        <div
          className='sl-block-content'
          style={{ textAlign: 'left', zIndex: 10 }}
        >
          <h3>
            <span style={{ fontSize: '54px' }}>
              Let's start with the <strong>what</strong>.
            </span>
          </h3>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '347.388px',
          left: '104.612px',
          top: '412px',
          height: 'auto',
        }}
        data-name='text-a342e4'
      >
        <div
          className='sl-block-style'
          style={{ zIndex: 11, transform: 'rotate(0deg)' }}
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 11 }}
          >
            <h3>
              <span style={{ fontSize: '42px' }}>
                These 3 pillars drive differentiation
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-db5b1e'
        style={{
          width: '301.412px',
          height: '144px',
          left: '978.588px',
          top: '0px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 12 }}>
          <img
            style={{}}
            data-natural-width={427}
            data-natural-height={204}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100665/images/9411350/Screen_Shot_2022-03-15_at_1.26.13_PM.png'
          />
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-e860f7'
        style={{
          width: '662.315px',
          height: '532.8px',
          left: '574.842px',
          top: '101.807px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 13 }}>
          <img
            style={{}}
            data-natural-width={629}
            data-natural-height={506}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100717/images/9417891/pasted-from-clipboard.png'
          />
        </div>
      </div>
    </section>
  );
};

export default SlideThree;
