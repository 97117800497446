import { Grid, GridSize, Typography } from '@mui/material';
import AdminCard from './AdminCard';
import { AdminToolTip } from './AdminToolTip';

function AdminBenchmarkNumber({
  title,
  value,
  description,
  caption,
  valueColor,
  size = 4,
  fontSize = 'large',
}: {
  title: string;
  value: string;
  description?: string;
  caption?: string;
  valueColor?: any;
  size?: GridSize;
  fontSize?: string;
}) {
  return (
    <Grid item xs={size}>
      <AdminCard>
        <Typography variant='h6'>
          {title}{' '}
          {caption && (
            <AdminToolTip
              message={<Typography variant='caption'>{caption}</Typography>}
            ></AdminToolTip>
          )}
        </Typography>

        {valueColor && (
          <Typography
            variant={fontSize === 'large' ? 'h3' : 'h4'}
            style={{ color: valueColor }}
          >
            {value}
          </Typography>
        )}
        {!valueColor && (
          <Typography variant={fontSize === 'large' ? 'h4' : 'h6'}>
            {value}
          </Typography>
        )}
        {description && (
          <Typography variant='caption'>{description}</Typography>
        )}
      </AdminCard>
    </Grid>
  );
}

export default AdminBenchmarkNumber;
