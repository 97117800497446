import { useHistory } from 'react-router-dom';
import { Grid, Button, IconButton, Collapse } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@mui/material/Alert';
import { useState, useEffect } from 'react';

export const AdminToastWarning = ({ message, exitLocation, trigger }) => {
  const history = useHistory();
  const [cancelWarningTrigger, setCancelWarningTrigger] = useState(trigger);

  useEffect(() => {
    if (trigger != null) {
      setCancelWarningTrigger(true);
    }
  }, [trigger]);

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ position: 'fixed', zIndex: 99 }}
    >
      <Grid item xs={5}>
        <Collapse in={cancelWarningTrigger}>
          <Alert
            severity='warning'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            action={
              <>
                <Button
                  color='inherit'
                  size='small'
                  onClick={() => {
                    if (exitLocation) {
                      history.push(exitLocation);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  YES, CANCEL
                </Button>
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setCancelWarningTrigger(false);
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              </>
            }
          >
            {message}
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export const AdminToastSuccess = ({ message, trigger }) => {
  const [showToast, setShowToast] = useState(trigger);
  useEffect(() => {
    setShowToast(trigger);
  }, [trigger]);
  return (
    <Grid
      container
      justifyContent='center'
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 99 }}
    >
      <Collapse in={showToast}>
        <Alert
          severity='success'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setShowToast(false);
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </Grid>
  );
};

export const AdminToastTimed = ({ message, trigger, time, severity }) => {
  const [showToast, setShowToast] = useState(trigger);

  useEffect((): any => {
    if (trigger != null) {
      setShowToast(true);
    }
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowToast(false);
      clearTimeout(timeId);
    }, time);
  }, [trigger]);

  return (
    <Grid
      container
      justifyContent='center'
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 99 }}
    >
      <Collapse in={showToast}>
        <Alert severity={severity}>{message}</Alert>
      </Collapse>
    </Grid>
  );
};
