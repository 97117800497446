import { useCallback, useReducer, useEffect } from 'react';
import axios from 'axios';
import { useBlueprintAdmin } from '../components/AdminProvider';
import getEnv from '../utils/getEnv';

export const defaultAxios = axios.create({
  baseURL: getEnv('REACT_APP_API_ROOT'),
  headers: { 'content-type': 'application/json' },
});

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...state, errors: null, fetching: false };
    case 'UPDATE_ERRORS':
      return { ...state, errors: payload };
    case 'UPDATE_FETCHING':
      return { ...state, fetching: payload };
    case 'UPDATE_FETCH_AND_ERRORS':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const initialState = { fetching: false, errors: null };

const useFetch = ({
  url,
  options = {
    method: 'get',
    headers: {},
  },
  onSuccess,
  fetchOnLoad = false,
  retryOption = false,
  refetch = [],
  type = 'admin',
}) => {
  const [fetchState, dispatch] = useReducer(reducer, initialState);
  const { errors, fetching } = fetchState;
  const blueprint = useBlueprintAdmin();
  const { jwtToken } = blueprint;

  useEffect(() => {
    if (!fetchOnLoad) {
      return;
    }
    if (jwtToken) {
      fetchData().catch((e) => {
        console.error(e);
      });
    }
  }, [...refetch, jwtToken]);

  const reset = () => dispatch({ type: 'RESET', payload: {} });

  const fetchData = useCallback(
    async ({ options: additionalOptions } = {}) => {
      let payload = {};

      dispatch({
        type: 'UPDATE_FETCH_AND_ERRORS',
        payload: {
          fetching: true,
          ...(!retryOption && { errors: null }),
        },
      });

      try {
        if (jwtToken) {
          const { data } = await defaultAxios({
            url,
            ...options,
            ...additionalOptions,
            ...(type === 'admin' && {
              headers: {
                ...(additionalOptions?.headers && additionalOptions.headers),
                ...options.headers,
                Authorization: `Bearer ${jwtToken}`,
              },
            }),
          });

          onSuccess && onSuccess(data);
          dispatch({
            type: 'UPDATE_FETCH_AND_ERRORS',
            payload: { fetching: false, errors: null },
          });
          payload = data;
        } else {
          dispatch({
            type: 'UPDATE_FETCH_AND_ERRORS',
            payload: { fetching: false, errors: 'no token found' },
          });
          payload = { errors: 'no token found' };
        }
      } catch ({ message: errors }) {
        dispatch({
          type: 'UPDATE_FETCH_AND_ERRORS',
          payload: { fetching: false, errors },
        });
        payload = { errors };
      } finally {
        return payload;
      }
    },
    [url, options, type === 'admin' && jwtToken]
  );

  return { errors: null, fetchData, fetching, reset };
};

export default useFetch;
