import cubejs from '@cubejs-client/core';
import { CubeProvider, QueryBuilder } from '@cubejs-client/react';
import { Card, Col, Divider, Row } from 'antd';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { uuidv4 } from '../../../utils/uuid';
import ChartRenderer from './ChartRendererTest';
import FilterGroup from './helper/FilterGroup';
import MemberGroup from './helper/MemberGroup';
import SelectChartType from './helper/SelectChartType';
import TimeGroup from './helper/TimeGroup';
import stateChangeHeuristics from './helper/stateChangeHeuristics';
const ControlsRow = styled(Row)`
  background: #ffffff;
  margin-bottom: 12px;
  padding: 18px 28px 10px 28px;
`;

const StyledDivider = styled(Divider)`
  margin: 0 12px;
  height: 4.5em;
  top: 0.5em;
  background: #f4f5f6;
`;

const HorizontalDivider = styled(Divider)`
  padding: 0;
  margin: 0;
  background: #f4f5f6;
`;

const ChartCard = styled(Card)`
  border-radius: 4px;
  border: none;
`;

const ChartRow = styled(Row)`
  padding-left: 28px;
  padding-right: 28px;
`;

export interface DashboardItem {
  id: string;
  name: string;
  vizState: object;
}

const QueryBuildFunction = ({ vizState, setVizState }) => {
  const { config } = useBlueprintAdmin();
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  console.log(vizState);

  return (
    <>
      {config && (
        <QueryBuilder
          vizState={vizState}
          setVizState={setVizState}
          cubejsApi={cubejsApi}
          wrapWithQueryRenderer={false}
          stateChangeHeuristics={stateChangeHeuristics}
          render={({
            measures,
            availableMeasures,
            updateMeasures,
            dimensions,
            availableDimensions,
            updateDimensions,
            segments,
            availableSegments,
            updateSegments,
            filters,
            updateFilters,
            timeDimensions,
            availableTimeDimensions,
            updateTimeDimensions,
            isQueryPresent,
            chartType,
            updateChartType,
            validatedQuery,
          }) => [
            <ControlsRow type='flex' justify='space-around' align='top' key='1'>
              <Col span={24}>
                <Row></Row>
                <Row align='top' style={{ paddingBottom: 23 }}>
                  <MemberGroup
                    title='Measures'
                    members={measures}
                    availableMembers={availableMeasures}
                    addMemberName='Measure'
                    updateMethods={updateMeasures}
                  />
                  <StyledDivider type='vertical' />
                  <MemberGroup
                    title='Dimensions'
                    members={dimensions}
                    availableMembers={availableDimensions}
                    addMemberName='Dimension'
                    updateMethods={updateDimensions}
                  />
                  <StyledDivider type='vertical' />
                  <MemberGroup
                    title='Segments'
                    members={segments}
                    availableMembers={availableSegments}
                    addMemberName='Segment'
                    updateMethods={updateSegments}
                  />
                  <StyledDivider type='vertical' />
                  <TimeGroup
                    title='Time'
                    members={timeDimensions}
                    availableMembers={availableTimeDimensions}
                    addMemberName='Time'
                    updateMethods={updateTimeDimensions}
                  />
                </Row>
                {!!isQueryPresent && [
                  <HorizontalDivider key={uuidv4()} />,
                  <Row
                    key={uuidv4()}
                    justify='space-around'
                    align='top'
                    gutter={24}
                    style={{ marginTop: 10 }}
                  >
                    <Col span={24}>
                      <FilterGroup
                        key={uuidv4()}
                        members={filters}
                        availableMembers={availableDimensions}
                        addMemberName='Filter'
                        updateMethods={updateFilters}
                      />
                    </Col>
                  </Row>,
                ]}
              </Col>
            </ControlsRow>,
            <ChartRow
              type='flex'
              justify='space-around'
              align='top'
              gutter={24}
              key='2'
            >
              <Col span={24}>
                {isQueryPresent ? (
                  [
                    <Row
                      style={{ marginTop: 15, marginBottom: 25 }}
                      key={uuidv4()}
                    >
                      <SelectChartType
                        key={uuidv4()}
                        chartType={chartType ? chartType : 'line'}
                        updateChartType={updateChartType}
                      />
                    </Row>,
                    <ChartCard key={uuidv4()} style={{ minHeight: 420 }}>
                      <CubeProvider cubejsApi={cubejsApi}>
                        <ChartRenderer
                          key={uuidv4()}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          vizState={{ query: vizState?.query, chartType }}
                          cubejsApi={cubejsApi}
                        />
                      </CubeProvider>
                    </ChartCard>,
                  ]
                ) : (
                  <h2
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Choose a measure or dimension to get started
                  </h2>
                )}
              </Col>
            </ChartRow>,
          ]}
        />
      )}
    </>
  );
};
export default QueryBuildFunction;
