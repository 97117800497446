import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminRecord from '../../components/AdminRecord';
import { AdminTableRow } from '../../components/AdminTable';

const JobDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const [trigger, setTrigger] = useState(undefined);
  const cronAPI = new AdminScopedAPI(config, 'AdminSchedules');

  const fetchData = () => {
    cronAPI
      .get({ path: `/admin/schedules/${id}` })
      .then((data) => {
        setTrigger(data.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (id) {
      fetchData();
    }
  }, [id, jwtToken]);

  const itemTemplate = (data) => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>{data.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>{data.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Created At</TableCell>
          <TableCell>{data.createdAt}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Interval</TableCell>
          <TableCell>{data.interval}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cron</TableCell>
          <TableCell>{data.cron}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Job</TableCell>
          <TableCell>{data.job}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>{data.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Config</TableCell>
          <TableCell>{JSON.stringify(data.config)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const recordTemplate = ({ id, createdAt, success, output }) => (
    <AdminTableRow key={id}>
      <TableCell>{new Date(createdAt).toLocaleString()}</TableCell>
      <TableCell>
        {success && <>Success</>}
        {!success && <>Failure</>}
      </TableCell>
      <TableCell>{success}</TableCell>
      <TableCell>
        {' '}
        {output && output}
        {!output && <>No Output</>}
      </TableCell>
    </AdminTableRow>
  );

  return (
    <>
      {id && (
        <AdminPage
          animation={true}
          editAction={`/jobs/${id}/edit`}
          title={`Job: ${id}`}
        >
          <AdminCard title='Detail'>
            <AdminRecord
              form={false}
              url={`/admin/schedules/${id}`}
              itemTemplate={itemTemplate}
            />
          </AdminCard>

          <AdminPaginatedList
            title='Run History'
            columns={['name', 'status', 'actions']}
            url={`/admin/schedules/${id}/history`}
            itemTemplate={recordTemplate}
            filterKeys={['name', 'status']}
          />
        </AdminPage>
      )}
    </>
  );
};

export default JobDetail;
