import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { schema } from '../../../forms/adminGroups';
import { uiSchema } from '../../../forms/admins';

const AdminGroupEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { config, adminGroupApi } = useBlueprintAdmin();
  const [groups, setGroups] = useState([]);

  const onSuccess = (response) => {
    history.push(`/admins/group/${response.data.id}`);
  };

  const onFailure = (data) => {
    console.error(data);
  };

  useEffect(() => {
    adminGroupApi!
      .getGroups()
      .then(({ data }) => {
        if (data.length > 0) {
          const response = data.map((group) => ({
            type: 'string',
            enum: group,
            enumNames: group.name,
            title: group.name,
          }));
          setGroups(response);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <>
      {id && (
        <>
          <AdminBaseForm
            id={id}
            url='/admin/groups'
            entityTitle='Admin Group'
            schema={schema}
            uiSchema={uiSchema}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </>
      )}
    </>
  );
};

export default AdminGroupEdit;
