import Excel from 'exceljs';

export async function csvReport(config, clientId, type, mappingData, fileName) {
  // const { adminBenchmarkApi } = useBlueprintAdmin();
  // const runs = await adminBenchmarkApi!.getClientBenchmarksForType(
  //   clientId,
  //   type
  // );
  // // Get the first record, we'll use this for keys
  // if (runs && runs.data && Array.isArray(runs.data) && runs.data.length) {
  //   const keys = mappingData;
  //   const { workbook, sheet } = setupWorkbook(keys, fileName);
  //   // For each record in the data, add a row
  //   for (const run of runs.data) {
  //     // const item = run.properties;
  //     const row: any = {};
  //     // All of our base keys first
  //     keys.forEach((data, i) => {
  //       const viewValue = data['viewValue'];
  //       row[viewValue] =
  //         typeof data['value'] === 'object' || run.hasOwnProperty(data['value'])
  //           ? getRowValue(run, data, 'value')
  //           : data['optionalLength']
  //           ? getRowValue(run, data, 'optionalLength')
  //           : null;
  //     });
  //     sheet.addRow(row);
  //   }
  //   // Apply general styling all sheets in our workbook
  //   for (const sheet of workbook.worksheets) {
  //     setGeneralStyling(sheet);
  //   }
  //   // write the content using writeBuffer
  //   const buf = await workbook.xlsx.writeBuffer();
  //   // download the processed file
  //   saveAs(new Blob([buf]), `${fileName.toLowerCase()}Detail.xlsx`);
  // }
}

function getRowValue(run, data, type) {
  let result: any;
  result =
    typeof data[type] === 'object'
      ? run[Object.keys(data[type])[0]][Object.values(data[type])[0] as any]
      : run[data[type]];
  if (type === 'optionalLength') {
    result = Array.isArray(result) ? result.length : result;
  } else {
    result = Array.isArray(result) ? result.join(',') : result;
  }
  return result;
}

function setupWorkbook(keys, fileName) {
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet(`${fileName} Detail`);

  const columns: Array<any> = [];

  // All of our base keys first
  keys.forEach((item, i) => {
    columns.push({ header: item.viewValue, key: item.viewValue, width: 20 });
  });

  sheet.columns = columns;

  return {
    workbook: workbook,
    sheet: sheet,
  };
}

function setGeneralStyling(sheet) {
  sheet.getRow(1).font = {
    bold: true,
    name: 'Calibri',
  };
}
