import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../../components/AdminProvider';

const BenchmarkClientCompetitorsNew = () => {
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const { config, adminBenchmarkApi } = useBlueprintAdmin();

  const [competitors, setCompetitors] = useState<Array<any>>([]);

  useEffect(() => {
    adminBenchmarkApi!
      .getClients()
      .then(({ data }) => {
        const response = data.map((client) => ({
          type: 'string',
          enum: client,
          enumNames: client.name,
        }));
        setCompetitors(response);
      })
      .catch((e) => console.error(e));
  }, []);

  const onSuccess = (response) => {
    history.push(`/benchmarks/clients/${clientId}`);
  };

  const uiSchema = {
    competitorId: { 'ui:widget': 'autoComplete' },
  };

  const schema = {
    properties: {
      competitorId: {
        type: 'string',
        title: 'Competitors',
        enum: competitors.map((competitor: any) => competitor.enum.id),
        enumNames: competitors.map((competitor: any) => competitor.enumNames),
        description: '',
      },
    },
    type: 'object',
    required: ['competitorId'],
  };

  return (
    <AdminBaseForm
      url={`/admin/benchmarks/clients/${clientId}/competitors`}
      entityTitle='Client Competitors'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
      breadcrumbs={[
        { href: '/benchmarks/clients', label: 'Benchmarks' },
        { href: '/benchmarks/clients', label: 'Clients' },
        {
          href: `/benchmarks/clients/${clientId}`,
          label: clientId,
          // TODO - Client name not available in scope, need to pull through, using clientId as a fallback
        },
        {
          label: 'New',
        },
      ]}
    />
  );
};

export default BenchmarkClientCompetitorsNew;
