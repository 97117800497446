import { Card, Dropdown, Menu, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const StyledCard = styled(Card)`
  box-shadow: 0px 2px 4px rgba(141, 149, 166, 0.1);
  border-radius: 4px;

  .ant-card-head {
    border: none;
  }
  .ant-card-body {
    padding-top: 12px;
  }
`;

const DashboardItemDropdown = ({ itemId, dashboardId, refresh }) => {
  const history = useHistory();
  const { adminDashboardApi } = useBlueprintAdmin();
  const dashboardItemDropdownMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push({
            pathname: `/reports/queryeditor/${dashboardId}/${itemId}`,
          });
        }}
      >
        <>Edit</>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            onOk() {
              adminDashboardApi!
                .deleteDashboardItem(dashboardId, itemId)
                .then((data) => {
                  console.log(data);
                })
                .catch((e) => console.error(e));
              refresh(true);
            },
          })
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={dashboardItemDropdownMenu}>
      <a onClick={(e) => e.preventDefault()}>...</a>
    </Dropdown>
  );
};

const DashboardItem = ({ itemId, dashboardId, children, title, refresh }) => (
  <StyledCard
    title={title}
    bordered={false}
    style={{
      height: '100%',
      width: '100%',
    }}
    extra={
      <DashboardItemDropdown
        itemId={itemId}
        dashboardId={dashboardId}
        refresh={refresh}
      />
    }
  >
    {children}
  </StyledCard>
);

export default DashboardItem;
