export const uiSchema = {
  config: {},
};

export const schema = {
  properties: {
    name: { type: 'string' },
    url: { type: 'string' },
    industry: { type: 'string' },
    active: { type: 'boolean', default: true },
  },
  type: 'object',
  required: ['name', 'url'],
};
