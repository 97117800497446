import { FormLabel, Grid, TextField } from '@mui/material';

const NameForm = ({ formik }) => {
  return (
    <Grid container spacing={2} xs={8}>
      <Grid item xs={8}>
        <FormLabel component='legend'>
          Dashboard Tile Name: {formik.values.name}
        </FormLabel>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Set Or Update Name'
          value={formik.values.name}
          onChange={formik.handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default NameForm;
