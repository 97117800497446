import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminForm from '../../../components/AdminForm';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { runPostSaveConditions, runPreSaveConditions } from './shared';

const ContentRecordNew = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { adminAssetApi, adminContentApi } = useBlueprintAdmin();
  const [collection, setCollection] = useState<any>(undefined);
  const history = useHistory();

  const onSubmit = async ({ formData }, e) => {
    // If this collection didnt include a status, we'll just go straight to a published status
    if (!formData.status) {
      formData.status = 'published';
    }

    runPreSaveConditions(formData);

    if (adminContentApi && adminAssetApi) {
      const recordCreateResponse = await adminContentApi.createCollectionRecord(
        collectionId,
        formData
      );

      // POST SAVE ACTIONS
      // Only used now to support hooking assets back to the created content object
      for (const field of Object.keys(formData)) {
        console.log(formData[field]);

        // If the field is an object, then its a nested set of components, need to iterate through them
        await runPostSaveConditions(
          formData[field],
          recordCreateResponse,
          adminAssetApi
        );
      }

      if (recordCreateResponse.success) {
        history.push(`/content/collections/${collectionId}`);
      } else {
        console.error(recordCreateResponse);
      }
    }
  };

  useEffect(() => {
    if (collectionId && adminContentApi) {
      adminContentApi
        .getCollection(collectionId)
        .then(({ data }) => {
          setCollection(data);
        })
        .catch((err) => console.error(err));
    }
  }, [collectionId]);

  return (
    <>
      {collection && (
        <AdminPage animation={true} title='New Record'>
          <AdminCard>
            <AdminForm
              url={`/admin/content/collections/${collectionId}/records`}
              id={collectionId}
              dataKey='content'
              uiSchema={collection.uiSchema ? collection.uiSchema : {}}
              schema={collection.schema}
              onSubmit={onSubmit}
            />
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};

export default ContentRecordNew;
