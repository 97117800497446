import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const AdminServiceNew = () => {
  const { config, adminGroupApi } = useBlueprintAdmin();
  const history = useHistory();
  const [groups, setGroups] = useState([]);

  const onSuccess = (response) => {
    history.push({
      pathname: `/admins/service/${response.data.id}`,
      state: { detail: 'newService', token: response.data.apiToken },
    });
  };

  useEffect(() => {
    adminGroupApi!
      .getGroups()
      .then(({ data }) => {
        const response = data.map((group) => ({
          type: 'string',
          enum: group,
          enumNames: group.name,
          title: group.name,
        }));
        setGroups(response);
      })
      .catch((e) => console.error(e));
  }, []);

  const schema = {
    title: 'Create Service Account',
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: 'Username',
        description:
          'The username of the service account. This is recommended that it is not an email address, but an easy to reference username based on usage.',
      },
      type: {
        type: 'string',
        default: 'service',
      },
      groups: {
        type: 'array',
        title: 'Groups',
        items: {
          title: 'Groups',
          type: 'string',
          enum: groups.map((group: any) => group.enum.id),
          enumNames: groups.map((group: any) => group.enumNames),
        },
        description: 'Groups to be applied to this service account',
      },
    },
  };

  return (
    <AdminBaseForm
      url='/admin/admins'
      entityTitle='Admin Service Account'
      schema={schema}
      uiSchema={{
        type: { 'ui:widget': 'hidden' },
      }}
      onSuccess={onSuccess}
    />
  );
};

export default AdminServiceNew;
