const SlideOne = ({ client }) => {
  return (
    <section>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '424px',
          left: '67px',
          top: '180px',
          height: 'auto',
        }}
      >
        <div
          className='sl-block-content'
          style={{
            zIndex: 11,
            fontSize: '158%',
            padding: '20px',
            lineHeight: '1.04',
          }}
          data-has-line-height
          dir='ui'
        >
          <h3 style={{ textAlign: 'left' }}>
            <span style={{ fontSize: '62px' }}>Benchmark Report</span>
          </h3>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='line'
        data-name='line-a5c0c7'
        style={{
          width: 'auto',
          height: 'auto',
          left: '86px',
          top: '503px',
        }}
      >
        <div
          className='sl-block-content'
          data-line-x1={0}
          data-line-y1={0}
          data-line-x2={314}
          data-line-y2={0}
          data-line-color='#000000'
          data-line-start-type='none'
          data-line-end-type='none'
          style={{ zIndex: 12 }}
          data-line-width='4px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            preserveAspectRatio='xMidYMid'
            width={314}
            height={1}
            viewBox='0 0 314 1'
          >
            <line
              stroke='rgba(0,0,0,0)'
              strokeWidth={15}
              x1={0}
              y1={0}
              x2={314}
              y2={0}
            />
            <line
              className='line-element'
              stroke='#000000'
              strokeWidth={4}
              x1={0}
              y1={0}
              x2={314}
              y2={0}
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '314px',
          left: '67px',
          top: '504px',
          height: 'auto',
        }}
        data-name='text-d18e72'
      >
        <div
          className='sl-block-content'
          style={{
            zIndex: 13,
            textAlign: 'left',
            color: 'rgb(0, 0, 0)',
            fontSize: '120%',
            padding: '20px',
          }}
        >
          <p>{client.name}</p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-89c46f'
        style={{
          width: '1205.33px',
          height: '720px',
          left: '74.67px',
          top: '0px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 10 }}>
          <img
            style={{}}
            data-natural-width={904}
            data-natural-height={540}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100665/images/9411334/pasted-from-clipboard.png'
          />
        </div>
      </div>
    </section>
  );
};

export default SlideOne;
