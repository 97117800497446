import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';
// Base Imports
// ------------------------------------------------------------------//
import { AdminProvider } from './components/AdminProvider';
import SessionTimeoutModal from './components/SessionTimeoutModal';
import useMountedEffect from './hooks/useMountedEffect';
import DeloitteLogin from './pages/auth/sso/login';
import BaseAdmin from './pages/BaseAdmin';
import BenchmarkChartsComparison from './pages/public/ComparisonChart';
import BenchmarksRadialChart from './pages/public/RadialChart';
import getEnv from './utils/getEnv';

/** To support Okta Auth when enabled */
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import Dashboard from './pages/dashboard';

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ azureLoginInstance, oktaLoginInstance }) => {
  const [sidebarRolebaseOptions, setSidebarRolebaseOptions] = useState<any>();
  const [sidebarReady, setSidebarReady] = useState<any>(false);

  const queryClient = new QueryClient();

  // To Support OKTA
  const history = useHistory();

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  useMountedEffect((mounted: boolean) => {
    // eslint-disable-next-line
    (async () => {
      const { roleBased } = await import('./config/sidebar');
      setSidebarRolebaseOptions(roleBased);
      setSidebarReady(true);
    })();

    // eslint-disable-next-line no-param-reassign
    return () => (mounted = false);
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Bw Modelica"',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h4: {
        fontWeight: 900,
      },
    },
    palette: {
      background: {
        default: '#fff',
      },
      primary: {
        main: '#0070eb',
        light: '#e0efff',
      },
      secondary: {
        main: '#bbdafd',
      },
    },
  });

  theme.components = {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          textAlign: 'left',
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        selected: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[800],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        style: {
          textDecoration: 'none',
        },
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // square corners
          textTransform: 'none', // removes uppercase transformation
          textDecoration: 'none',
        },
        containedPrimary: {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#bbdafd', // from mui4: theme.palette.secondary.main,
            color: '#0070eb', // from mui4: theme.palette.primary.dark
          },
        },
        containedSecondary: {
          fontWeight: 700, // makes text bold
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
        underline: 'none',
      },
    },
  };

  return (
    <>
      {sidebarReady && (
        <AdminProvider
          config={{
            apiRoot:
              getEnv('REACT_APP_API_ROOT') ||
              'https://admin.hux-staging.sandbox.ddapps.xyz',
            debug: getEnv('REACT_APP_DEBUG') || true,
            startPage: getEnv('REACT_APP_START_PAGE') || '/',
            sidebar: { roleBasedOptions: sidebarRolebaseOptions },
            authMethod: {
              type: getEnv('REACT_APP_AUTH_METHOD') || 'password',
            },
            oktaLoginInstance: oktaLoginInstance,
          }}
        >
          <ThemeProvider theme={theme}>
            <SessionTimeoutModal />
            <CssBaseline />
            <Switch>
              <Route path='/public/chart/comparison'>
                <BenchmarkChartsComparison />
              </Route>
              <Route path='/public/chart/radial'>
                <BenchmarksRadialChart />
              </Route>
              <QueryClientProvider client={queryClient}>
                {azureLoginInstance && (
                  <MsalProvider instance={azureLoginInstance}>
                    <AuthenticatedTemplate>
                      <BaseAdmin>
                        <Route exact path='/' component={Dashboard} />
                      </BaseAdmin>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <DeloitteLogin />
                    </UnauthenticatedTemplate>
                  </MsalProvider>
                )}
                {oktaLoginInstance && (
                  <>
                    <Security
                      oktaAuth={oktaLoginInstance}
                      onAuthRequired={(oktaAuth) => {
                        history.push('/auth/login');
                      }}
                      restoreOriginalUri={restoreOriginalUri}
                    >
                      <BaseAdmin>
                        <Route
                          path='/auth/callback'
                          component={LoginCallback}
                        />
                      </BaseAdmin>
                    </Security>
                  </>
                )}
                {!azureLoginInstance && !oktaLoginInstance && (
                  <BaseAdmin>
                    <Route exact path='/' component={Dashboard} />
                  </BaseAdmin>
                )}
              </QueryClientProvider>
            </Switch>
            <ToastContainer />
          </ThemeProvider>
        </AdminProvider>
      )}
    </>
  );
};

export default App;
