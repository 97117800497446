import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';

const SignOut = () => {
  const { oktaAuth } = useOktaAuth() || {};

  const logout = async () => oktaAuth.signOut();

  useEffect(() => {
    /** clear all local storage */
    localStorage.clear();

    /** if okta is used then use okta sign out */
    if (oktaAuth) {
      logout().catch((err) => console.error(err));
    }
  }, []);
  return <h1>Signed out!</h1>;
};

export default SignOut;
