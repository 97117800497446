import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import AdminChart from '../../../components/reporting/AdminChart';

const BenchmarkScorecard = () => {
  const { config, jwtToken } = useBlueprintAdmin();

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  return (
    <AdminPage
      animation={true}
      title='Benchmark Clients'
      newAction='/benchmarks/clients/new'
    >
      <CubeProvider cubejsApi={cubejsApi}>
        <AdminChart
          title='Activity In The Last Hour'
          showViewDetail={false}
          showCard={false}
          vizState={{
            chartType: 'line',
            legend: false,
            query: {
              measures: ['ExperienceBenchmark.count'],
              // timeDimensions: [
              //   {
              //     dimension: 'ExperienceBenchmark.updatedAt',
              //     dateRange: 'from 1 month ago to now',
              //     granularity: 'day',
              //   },
              // ],
              // ],
              // filters: [
              //   {
              //     member: 'ExperienceBenchmark.clientId',
              //     operator: 'equals',
              //     values: [clientId],
              //   },
              // ],
              dimensions: ['ExperienceBenchmark.clientId'],
              // timezone: 'America/Los_Angeles',
            },
          }}
        />
      </CubeProvider>
    </AdminPage>
  );
};

export default BenchmarkScorecard;
