import * as PropTypes from 'prop-types';
import React from 'react';
import MemberDropdown from './MemberDropdown';
import MemberGroupTitle from './MemberGroupTitle';
import RemoveButtonGroup from './RemoveButtonGroup';
const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  title,
}) => (
  <div>
    <MemberGroupTitle title={title} />
    {members.map((m) => (
      <RemoveButtonGroup
        key={m.index || m.name}
        onRemoveClick={() => updateMethods.remove(m)}
      >
        <MemberDropdown
          type='selected'
          availableMembers={availableMembers}
          onClick={(updateWith) => updateMethods.update(m, updateWith)}
        >
          {m.title}
        </MemberDropdown>
      </RemoveButtonGroup>
    ))}
    <MemberDropdown
      type={members.length > 0 ? 'icon' : 'new'}
      onClick={(m) => updateMethods.add(m)}
      availableMembers={availableMembers}
    >
      {addMemberName}
    </MemberDropdown>
  </div>
);

MemberGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default MemberGroup;
