import { ResultSet } from '@cubejs-client/core';

const getQueryData = async ({
  cubejsApi,
  measures,
  filters,
}: {
  cubejsApi?: any;
  measures?: any;
  filters?: any;
}) => {
  const resultSet: ResultSet<any> = await cubejsApi.load({
    measures: measures,
    filters: filters ? filters : [],
  });

  const rawData = resultSet.rawData()[0];

  const returnValue: any = {};

  for (const key of Object.keys(rawData)) {
    const trimmedKeyName = key.split('.')[1];
    returnValue[trimmedKeyName] = Math.round(rawData[key] * 100) / 100;
  }

  console.error(returnValue);

  // const value = resultSet.rawData()[0];
  return returnValue;
};

export default getQueryData;
