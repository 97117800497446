import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const BenchmarkSubSectorsNew = () => {
  const [sectors, setSectors] = useState<Array<any>>([]);
  const history = useHistory();
  const { config, adminBenchmarkApi } = useBlueprintAdmin();

  useEffect(() => {
    adminBenchmarkApi!
      .getSectors()
      .then(({ data }) => {
        setSectors(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSuccess = (response) => {
    history.push(`/benchmarks/sub-sectors`);
  };

  const uiSchema = {
    sectorId: { 'ui:widget': 'autoComplete' },
  };

  const schema = {
    properties: {
      name: {
        type: 'string',
      },
      sectorId: {
        type: 'string',
        title: 'Sector',
        enum: sectors.map((sector: any) => sector.id),
        enumNames: sectors.map((sector: any) => sector.name),
        description: '',
      },
    },
    type: 'object',
    required: ['name', 'sectorId'],
  };

  return (
    <AdminBaseForm
      url='/admin/benchmarks/sub-sectors'
      entityTitle='Sub Sectors'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default BenchmarkSubSectorsNew;
