import AdminBenchmarkPerformanceChart from '../../../../components/AdminBenchmarkPerformanceChart';
import RadialChart from '../../../../components/reporting/RadialChart';
import { isOrIsNot, performanceScore } from '../../utils';

const SlidePerformanceScore = ({
  client,
  allClients,
  competitors,
  clientIcon,
}) => {
  return (
    <section className='stack'>
      {/* slide 6-1 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 11 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Performance Assessment
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={performanceScore}
            data-shape-stretch='true'
            style={{ zIndex: 10 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={performanceScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-89169f'
          style={{
            height: 'auto',
            width: '369px',
            left: '104.788px',
            top: '210.5px',
          }}
          data-auto-animate-easing='linear'
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgPerformanceHappeningScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceHappeningScore
                    : allClients.avgPerformanceHappeningScore
                )}{' '}
                happening
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '355px',
            left: '104.788px',
            top: '355.5px',
          }}
          data-name='text-0983d8'
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgPerformanceUsefulScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceUsefulScore
                    : allClients.avgPerformanceUsefulScore
                )}{' '}
                useful
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '361px',
            left: '104.788px',
            top: '495.4px',
          }}
          data-name='text-5ae452'
        >
          <div className='sl-block-content' style={{ zIndex: 14 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgPerformanceUsableScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceUsableScore
                    : allClients.avgPerformanceUsableScore
                )}{' '}
                usable
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '381px',
            left: '105.788px',
            top: '625.5px',
          }}
          data-name='text-12fd86'
        >
          <div className='sl-block-content' style={{ zIndex: 15 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.7em' }}>
                Your website{' '}
                {isOrIsNot(
                  client.avgPerformanceDelightfulScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceDelightfulScore
                    : allClients.avgPerformanceDelightfulScore
                )}{' '}
                smooth
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-52b277'
          style={{
            width: '500px',
            left: '529.389px',
            top: '156.26px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content'>
            <div
              id='Performance_Happening'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgPerformanceHappeningScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceHappeningScore
                    : allClients.avgPerformanceHappeningScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '500px',
            left: '529.389px',
            top: '295.76px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c030ab'
        >
          <div className='sl-block-content'>
            <div
              id='Performance_Useful'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgPerformanceUsefulScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceUsefulScore
                    : allClients.avgPerformanceUsefulScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '500px',
            left: '529.389px',
            top: '436.4px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-8d5d8f'
        >
          <div className='sl-block-content'>
            <div
              id='Performance_Usable'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgPerformanceUsableScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceUsableScore
                    : allClients.avgPerformanceUsableScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '500px',
            left: '529.389px',
            top: '570.76px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-409c67'
        >
          <div className='sl-block-content'>
            <div
              id='Performance_Smooth'
              style={{
                zIndex: 14,
                height: '120px',
                paddingTop: '50px',
                paddingBottom: '100px',
                paddingLeft: '75px',
                paddingRight: '75px',
              }}
            >
              <AdminBenchmarkPerformanceChart
                clientValue={client.avgPerformanceDelightfulScore}
                competitorAvg={
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceDelightfulScore
                    : allClients.avgPerformanceDelightfulScore
                }
                variant={
                  Object.keys(competitors).length !== 0
                    ? 'competitor'
                    : 'industry'
                }
                logo={clientIcon}
              />
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-21d13c'
          style={{
            height: 'auto',
            width: '75px',
            left: '977.5px',
            top: '173.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 23 }}>
            <p>
              <span style={{ fontSize: '0.5em' }}>You</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '135px',
            left: '1111.82px',
            top: '173.25px',
          }}
          data-name='text-f80166'
        >
          <div
            className='sl-block-style'
            style={{ zIndex: 24, transform: 'rotate(0deg)' }}
          >
            <div className='sl-block-content' style={{ zIndex: 24 }}>
              <p>
                <span style={{ fontSize: '0.5em' }}>
                  {Object.keys(competitors).length !== 0
                    ? 'Competitor'
                    : 'Industry'}{' '}
                  Avg
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-8c8046'
          style={{
            width: '110px',
            height: '110px',
            left: '960px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div
            id='Performance_Detail_Yours'
            className='sl-block-content'
            style={{ zIndex: 25 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceScore),
                  fill: performanceScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          style={{
            width: '110px',
            height: '110px',
            left: '1115.96px',
            top: '75.75px',
            minWidth: '1px',
            minHeight: '1px',
          }}
          data-name='image-c6e884'
        >
          <div
            id='Performance_Detail_Industry'
            className='sl-block-content'
            style={{ zIndex: 26 }}
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    Object.keys(competitors).length !== 0
                      ? competitors.avgPerformanceScore
                      : allClients.avgPerformanceScore
                  ),
                  fill: performanceScore,
                },
              ]}
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '158px',
            left: '1113px',
            top: '609px',
          }}
          data-name='text-ad56f9'
        >
          <div
            className='sl-block-content'
            style={{ zIndex: 25, fontSize: '50%' }}
          >
            <p>What does performance mean?</p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-8caa60'
          style={{
            width: '39px',
            height: '39px',
            left: '1172.5px',
            top: '667.37px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='arrow-down'
            data-shape-fill-color='#000000'
            data-shape-stretch='true'
            style={{ zIndex: 26 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 39 39'
            >
              <polygon
                points='19.5,39 39,19.5 27.299999999999997,19.5 27.299999999999997,0 11.7,0 11.7,19.5 0,19.5 19.5,39'
                className='shape-element'
                fill='#000000'
              />
            </svg>
          </div>
        </div>
      </section>
      {/* slide 6-2 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 11 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Performance means many things
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          data-name='shape-0df86b'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={performanceScore}
            data-shape-stretch='true'
            style={{ zIndex: 10 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={performanceScore}
              />
            </svg>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-e0ffda'
          style={{
            height: 'auto',
            width: '600px',
            left: '340px',
            top: '208px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p>
              <em>
                <span style={{ fontSize: '0.5em' }}>
                  In a back-end / DevOps context...
                </span>
              </em>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-bc4492'
          style={{
            height: 'auto',
            width: '220px',
            left: '167px',
            top: '264.25px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>Server speed</span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '220px',
            left: '167px',
            top: '317.75px',
          }}
          data-name='text-07b0a1'
        >
          <div className='sl-block-content' style={{ zIndex: 14 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>Scalability</span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '291px',
            left: '494.5px',
            top: '264.25px',
          }}
          data-name='text-66c606'
        >
          <div className='sl-block-content' style={{ zIndex: 15 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>Efficient databases</span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '291px',
            left: '494.5px',
            top: '309.25px',
          }}
          data-name='text-51037f'
        >
          <div className='sl-block-content' style={{ zIndex: 16 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>Consistent uptime</span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '291px',
            left: '884px',
            top: '264.25px',
          }}
          data-name='text-a2c934'
        >
          <div className='sl-block-content' style={{ zIndex: 17 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>Stable infrastructure</span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '369px',
            left: '845px',
            top: '309.25px',
          }}
          data-name='text-6622d5'
        >
          <div className='sl-block-content' style={{ zIndex: 18 }}>
            <p>
              <strong>
                <span style={{ fontSize: '0.9em' }}>
                  Effective load balancing
                </span>
              </strong>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-a19e67'
          style={{
            height: 'auto',
            width: '1208px',
            left: '36px',
            top: '490px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 19 }}>
            <p>
              When we say{' '}
              <strong>
                <span style={{ color: '#800080' }}>Performance</span>
              </strong>
              , we mean...
            </p>
            <p>
              How does the end-user{' '}
              <span style={{ color: '#800080' }}>
                <strong>actually experience interacting</strong>
              </span>{' '}
              with your digital experience?
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='shape'
          style={{
            width: '1280px',
            height: '20px',
            left: '0px',
            top: '0px',
          }}
          data-name='shape-b4a80c'
        >
          <div
            className='sl-block-content'
            data-shape-type='rect'
            data-shape-fill-color={performanceScore}
            data-shape-stretch='true'
            style={{ zIndex: 20 }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              width='100%'
              height='100%'
              preserveAspectRatio='none'
              viewBox='0 0 1280 20'
            >
              <rect
                width={1280}
                height={20}
                rx={0}
                ry={0}
                className='shape-element'
                fill={performanceScore}
              />
            </svg>
          </div>
        </div>
      </section>
      {/* slide 6-5 */}
      <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 10 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Users experience Performance in various ways
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-235ae9'
          style={{
            height: 'auto',
            width: '600px',
            left: '0px',
            top: '167.5px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 11 }}>
            <p>
              <span style={{ color: '#008000' }}>
                <strong>
                  <span style={{ fontSize: '0.9em' }}>The Experience</span>
                </strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '680px',
            top: '167.5px',
          }}
          data-name='text-8cb6ed'
        >
          <div className='sl-block-content' style={{ zIndex: 12 }}>
            <p>
              <span style={{ color: '#DAA520' }}>
                <span style={{ fontSize: '0.9em' }}>
                  <strong>The Meaning</strong>
                </span>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-c26bc7'
          style={{
            height: 'auto',
            width: '238px',
            left: '181px',
            top: '244.5px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 13 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>there?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '220px',
            left: '181px',
            top: '367.5px',
          }}
          data-name='text-41ecf8'
        >
          <div className='sl-block-content' style={{ zIndex: 14 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>useful?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '220px',
            left: '181px',
            top: '494.9px',
          }}
          data-name='text-366c57'
        >
          <div className='sl-block-content' style={{ zIndex: 15 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>usable?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '238px',
            left: '181px',
            top: '610px',
          }}
          data-name='text-665cfc'
        >
          <div className='sl-block-content' style={{ zIndex: 16 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '1.2em' }}>
                Is it <strong>smooth?</strong>
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-0e831a'
          style={{
            height: 'auto',
            width: '600px',
            left: '680px',
            top: '228.5px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 17 }}>
            <p>
              <span style={{ fontSize: '0.7em' }}>
                Does the user see something?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '393px',
            left: '783.5px',
            top: '334.5px',
          }}
          data-name='text-5f5729'
        >
          <div className='sl-block-content' style={{ zIndex: 18 }}>
            <p>
              <span style={{ fontSize: '21px' }}>
                &nbsp; &nbsp; &nbsp; &nbsp;Has enough content rendered
                that&nbsp; &nbsp;users can engage with it?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '393px',
            left: '800px',
            top: '484.4px',
          }}
          data-name='text-e1cbb1'
        >
          <div className='sl-block-content' style={{ zIndex: 19 }}>
            <p>
              <span style={{ fontSize: '21px' }}>
                Can users interact with the page, or is it still loading?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '393px',
            left: '800px',
            top: '594.5px',
          }}
          data-name='text-7ad336'
        >
          <div className='sl-block-content' style={{ zIndex: 20 }}>
            <p>
              <span style={{ fontSize: '21px' }}>
                Are interactions smooth and natural, or broken and lagging?
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '393px',
            left: '783.5px',
            top: '407.5px',
          }}
          data-name='text-a803c7'
        >
          <div className='sl-block-content' style={{ zIndex: 21 }}>
            <p>
              <span style={{ fontSize: '21px' }}>Is it visually complete?</span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '696.5px',
            top: '267.5px',
          }}
          data-name='text-c56d2a'
        >
          <div className='sl-block-content' style={{ zIndex: 22 }}>
            <p>
              <span style={{ fontSize: '0.7em' }}>
                Has the server responded?
              </span>
            </p>
          </div>
        </div>
      </section>
      {/* slide 6-4 */}
      {/* slide 6-6 */}
      {/* <section>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            width: '1120px',
            left: '80px',
            top: '72px',
            height: 'auto',
          }}
          data-name='text-104272'
        >
          <div
            className='sl-block-content'
            style={{ textAlign: 'left', zIndex: 10 }}
          >
            <h3>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
                Let's see it in context
              </span>
            </h3>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          data-name='text-49e81c'
          style={{
            height: 'auto',
            width: '600px',
            left: '-76.2px',
            top: '451.5px',
          }}
        >
          <div
            className='sl-block-content fragment'
            style={{ zIndex: 11 }}
            data-fragment-index={0}
          >
            <p>
              <span style={{ color: 'rgb(0, 0, 0)', textAlign: 'left' }}>
                {'{'}
                {'{'}client.name{'}'}
                {'}'}
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '340px',
            top: '451.5px',
          }}
          data-name='text-27b06d'
        >
          <div
            className='sl-block-content fragment'
            style={{ zIndex: 12 }}
            data-fragment-index={0}
          >
            <p>
              <span style={{ color: 'rgb(0, 0, 0)', textAlign: 'left' }}>
                {'{'}
                {'{'}competitor1.name{'}'}
                {'}'}
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='text'
          style={{
            height: 'auto',
            width: '600px',
            left: '740px',
            top: '451.5px',
          }}
          data-name='text-6422c6'
        >
          <div
            className='sl-block-content fragment'
            style={{ zIndex: 13 }}
            data-fragment-index={1}
          >
            <p>
              <span style={{ color: 'rgb(0, 0, 0)', textAlign: 'left' }}>
                {'{'}
                {'{'}competitor2.name{'}'}
                {'}'}
              </span>
            </p>
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-5ff800'
          style={{
            width: '103.604px',
            height: '57.8px',
            left: '179.718px',
            top: '308.6px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 15 }}>
            <img
              style={{}}
              data-natural-width={95}
              data-natural-height={53}
              data-lazy-loaded
              data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100717/images/9421019/pasted-from-clipboard.png'
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-d2e579'
          style={{
            width: '203.627px',
            height: '132.8px',
            left: '938.187px',
            top: '277.6px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 16 }}>
            <img
              style={{}}
              data-natural-width={161}
              data-natural-height={105}
              data-lazy-loaded
              data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100717/images/9421020/pasted-from-clipboard.png'
            />
          </div>
        </div>
        <div
          className='sl-block'
          data-block-type='image'
          data-name='image-c7be5e'
          style={{
            width: '204.728px',
            height: '35.514px',
            left: '531.436px',
            top: '337.5px',
            minWidth: '1px',
            minHeight: '1px',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 17 }}>
            <img
              style={{}}
              data-natural-width={196}
              data-natural-height={34}
              data-lazy-loaded
              data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100717/images/9421021/pasted-from-clipboard.png'
            />
          </div>
        </div>
      </section> */}
    </section>
  );
};

export default SlidePerformanceScore;
