import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const BenchmarkSectorsNew = () => {
  const [industries, setIndustries] = useState<Array<any>>([]);
  const history = useHistory();
  const { config, adminBenchmarkApi } = useBlueprintAdmin();

  useEffect(() => {
    adminBenchmarkApi!
      .getIndustries()
      .then(({ data }) => {
        setIndustries(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSuccess = (response) => {
    history.push(`/benchmarks/sectors`);
  };

  const uiSchema = {
    industryId: { 'ui:widget': 'autoComplete' },
  };

  const schema = {
    properties: {
      name: {
        type: 'string',
      },
      industryId: {
        type: 'string',
        title: 'Industry',
        enum: industries.map((industry: any) => industry.id),
        enumNames: industries.map((industry: any) => industry.name),
        description: '',
      },
    },
    type: 'object',
    required: ['name', 'industryId'],
  };

  return (
    <AdminBaseForm
      url='/admin/benchmarks/sectors'
      entityTitle='Sectors'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default BenchmarkSectorsNew;
