import Excel from 'exceljs';
import { saveAs } from 'file-saver';

async function getAllBenchmarks(config, jwtToken) {
  const response = await fetch(
    `${config.apiRoot}/admin/benchmarks/benchmarks?limit=9999`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

  const data = await response.json();
  return data.data;
}

async function getAllVendors(config, jwtToken) {
  const response = await fetch(
    `${config.apiRoot}/admin/benchmarks/vendors/all`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

  const data = await response.json();
  return data.data;
}

async function getAllClientsAllData(config, jwtToken) {
  const response = await fetch(
    `${config.apiRoot}/admin/benchmarks/clients/allData?limit=9999`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

  const data = await response.json();
  return data.data;
}

async function getAllClients(config, jwtToken) {
  const response = await fetch(
    `${config.apiRoot}/admin/benchmarks/clients?limit=9999`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

  const data = await response.json();
  return data.data;
}

function getExperienceBenchmarkColumns() {
  return [
    { header: 'ID', key: 'id', width: 20, outlineLevel: 0 },
    { header: 'Is Latest?', key: 'latest', width: 20, outlineLevel: 0 },
    { header: 'Created', key: 'createdAt', width: 20 },
    { header: 'Client', key: 'client', width: 20, outlineLevel: 0 },
    {
      header: 'Page Type',
      key: 'pageIdentifier',
      width: 15,
    },
    {
      header: 'Experience Score',
      key: 'experienceScore',
      width: 15,
    },
    {
      header: 'Accessibility Score',
      key: 'accessibilityScore',
      width: 15,
    },
    {
      header: 'Warnings',
      key: 'accessibilityWarnings',

      width: 10,
    },
    {
      header: 'Errors',
      key: 'accessibilityErrors',

      width: 10,
    },
    {
      header: 'Notices',
      key: 'accessibilityNotices',

      width: 10,
    },
    {
      header: 'Readability Score',
      key: 'readabilityScore',
      width: 15,
    },
    {
      header: 'Word Count',
      key: 'readabilityLexiconCount',

      width: 15,
    },
    {
      header: 'Difficult Word Count',
      key: 'readabilityDifficultWordCount',

      width: 15,
    },
    {
      header: 'Difficulty',
      key: 'readabilityDifficulty',

      width: 15,
    },
    {
      header: 'Difficulty PageAvg',
      key: 'readabilityDifficultyFromPageAvg',

      width: 15,
    },
    {
      header: 'Words From PageAvg',
      key: 'readabilityWordsFromPageAvg',
      width: 15,
    },
    {
      header: '-',
      key: 'rawData',
      width: 5,
    },
    {
      header: 'Readability - Avg. Difficulty For Page',
      key: 'avgDifficultyForPage',
      outlineLevel: 1,
      width: 25,
    },
    {
      header: 'Readability - Avg. Words For Page',
      key: 'avgWordsForPage',
      outlineLevel: 1,
      width: 25,
    },
    {
      header: 'Readability - Avg. Words For Page',
      key: 'validateDifficultyPageAvg',
      outlineLevel: 1,
      width: 25,
    },
    {
      header: 'Readability - Avg. Words For Page',
      key: 'validateWordsFromPageAvg',
      outlineLevel: 1,
      width: 25,
    },
    {
      header: '-',
      key: 'calculations',
      outlineLevel: 1,
      width: 5,
    },
    {
      header: 'Validate - Accessibility Score',
      key: 'validateAccessibilityScore',
      width: 25,
      outlineLevel: 1,
    },
    {
      header: 'Validate - Readability Score',
      key: 'validateReadabilityScore',
      width: 25,
      outlineLevel: 1,
    },
  ];
}

function getStructuralColumns() {
  return [
    { header: 'Client Id', key: 'clientId', width: 20, outlineLevel: 0 },
    { header: 'Client Name', key: 'clientName', width: 20, outlineLevel: 0 },
    { header: 'Created At', key: 'createdAt', width: 20, outlineLevel: 0 },
    { header: 'Is Active', key: 'active', width: 20, outlineLevel: 0 },
    {
      header: 'Industry',
      key: 'industry',
      width: 20,
    },
    {
      header: 'Sector',
      key: 'sector',
      width: 20,
    },
    {
      header: 'Subsector',
      key: 'subsector',
      width: 20,
    },
    {
      header: 'Domain',
      key: 'domain',
      width: 20,
    },
    {
      header: 'Number of Pages',
      key: 'pages',
      width: 20,
    },
    {
      header: 'Number of Runs',
      key: 'runs',
      width: 20,
    },
    {
      header: 'Latest Report',
      key: 'report',
      width: 20,
    },
  ];
}

function saveToStructuralSheet(sheet: any, client: any) {
  if (sheet) {
    sheet.addRow({
      clientId: client.id,
      clientName: client.name,
      createdAt: client.createdAt,
      active: client.active,
      industry: client.clientIndustry ? client.clientIndustry.name : '',
      sector: client.clientSector ? client.clientSector.name : '',
      subSector: client.clientSubSector ? client.clientSubSector.name : '',
      domain: client.url,
      runs: client.runs.length,
      pages: client.pages.length,
      report: client.latestReport ? client.latestReport : '',
    });
  }
}

function saveToExperienceBenchmarkSheet(sheet: any, benchmark: any) {
  if (sheet) {
    const rowIndex = Number(sheet.actualRowCount) + 1;

    sheet.addRow({
      id: benchmark.id,
      latest: benchmark.latest,
      createdAt: benchmark.createdAt,
      client: benchmark.client.name,
      pageIdentifier: benchmark.pageIdentifier,
      experienceScore: benchmark.experienceScore,
      accessibilityScore: benchmark.accessibilityScore,
      readabilityScore: benchmark.readabilityScore,
      accessibilityErrors: benchmark.accessibilityErrors,
      accessibilityWarnings: benchmark.accessibilityWarnings,
      accessibilityNotices: benchmark.accessibilityNotices,
      readabilityLexiconCount: benchmark.readabilityLexiconCount,
      readabilityDifficultWordCount: benchmark.readabilityDifficultWordCount,
      readabilityDifficulty: benchmark.readabilityDifficulty,
      readabilityDifficultyFromPageAvg:
        benchmark.readabilityDifficultyFromPageAvg,
      readabilityWordsFromPageAvg: benchmark.readabilityWordsFromPageAvg,

      avgDifficultyForPage: benchmark.properties.avgDifficultyForPage,
      avgWordsForPage: benchmark.properties.avgWordsForPage,

      validateAccessibilityScore: {
        formula: `=ROUND(((1009-H${rowIndex} - 2 * I${rowIndex})/1009)* 100, 2)`,
      },
      validateReadabilityScore: {
        formula: `=ROUND(((5-ABS((P${rowIndex}*0.0013))-O${rowIndex}*20)/5)*100, 2)`,
      },
      validateDifficultyPageAvg: {
        formula: `=ROUND(IF(N${rowIndex}-R${rowIndex}<0,0,N${rowIndex}-R${rowIndex}), 2)`,
      },
      validateWordsFromPageAvg: {
        formula: `=ROUND(IF(L${rowIndex}-S${rowIndex}<0,0,L${rowIndex}-S${rowIndex}), 2)`,
      },
    });

    sheet.addConditionalFormatting({
      ref: `W${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.accessibilityScore,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });

    sheet.addConditionalFormatting({
      ref: `X${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: Math.round(benchmark.readabilityScore * 10) / 10,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });

    sheet.addConditionalFormatting({
      ref: `T${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.readabilityDifficultyFromPageAvg,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });

    sheet.addConditionalFormatting({
      ref: `U${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.readabilityWordsFromPageAvg,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });
  }
}

function saveToPerformanceBenchmarkSheet(sheet: any, benchmark: any) {
  // const rowIndex = sheet.actualRowCount + 1;
  if (sheet) {
    sheet.addRow({
      id: benchmark.id,
      latest: benchmark.latest,
      createdAt: benchmark.createdAt,
      client: benchmark.client.name,
      pageIdentifier: benchmark.pageIdentifier,
      deviceType: benchmark.deviceType,
      performanceScore: benchmark.performanceScore,
      performanceHappeningScore: benchmark.performanceHappeningScore,
      performanceUsefulScore: benchmark.performanceUsefulScore,
      performanceUsableScore: benchmark.performanceUsableScore,
      performanceDelightfulScore: benchmark.performanceDelightfulScore,

      labFcp: benchmark.labFirstContentfulPaint,
      cruxFcp: benchmark.cruxFirstContentfulPaint,
      labTbt: benchmark.labTotalBlockingTime,

      labLcp: benchmark.labLargestContentfulPaint,
      cruxLcp: benchmark.cruxLargestContentfulPaint,

      labTti: benchmark.labTimeToInteractive,
      labFid: benchmark.labFirstInputDelay,
      cruxInp: benchmark.cruxInteractionToNextPaint,

      labCls: benchmark.labCumulativeLayoutShift,
      cruxCls: benchmark.cruxCumulativeLayoutShift,
      labSi: benchmark.labSpeedIndex,

      lighthouseScore: benchmark.lighthouseScore,

      labFcpScore: benchmark.properties.labFcpScore
        ? benchmark.properties.labFcpScore
        : '',
      cruxFcpScore: benchmark.properties.cruxFcpScore
        ? benchmark.properties.cruxFcpScore
        : '',
      labTbtScore: benchmark.properties.labTbtScore
        ? benchmark.properties.labTbtScore
        : '',
      labLcpScore: benchmark.properties.labLcpScore
        ? benchmark.properties.labLcpScore
        : '',
      cruxLcpScore: benchmark.properties.cruxLcpScore
        ? benchmark.properties.cruxLcpScore
        : '',
      labTtiScore: benchmark.properties.labTtiScore
        ? benchmark.properties.labTtiScore
        : '',
      labFidScore: benchmark.properties.labFidScore
        ? benchmark.properties.labFidScore
        : '',
      cruxInpScore: benchmark.properties.cruxInpScore
        ? benchmark.properties.cruxInpScore
        : '',
      labClsScore: benchmark.properties.labClsScore
        ? benchmark.properties.labClsScore
        : '',
      cruxClsScore: benchmark.properties.cruxClsScore
        ? benchmark.properties.cruxClsScore
        : '',
      labSiScore: benchmark.properties.labSiScore
        ? benchmark.properties.labSiScore
        : '',
    });
  }
}

function saveToTechnologyBenchmarkSheet(sheet: any, benchmark: any) {
  if (sheet) {
    const rowIndex = Number(sheet.actualRowCount) + 1;

    sheet.addRow({
      id: benchmark.id,
      latest: benchmark.latest,
      client: benchmark.client.name,
      createdAt: benchmark.createdAt,
      ownershipScore: benchmark.ownershipScore,
      identityScore: benchmark.ownershipIdentityScore,
      trustScore: benchmark.ownershipTrustScore,
      firstPartyDomains: benchmark.firstPartyDomains,
      thirdPartyDomains: benchmark.thirdPartyDomains,
      thirdPartyCookies: benchmark.thirdPartyCookies,
      thirdPartyTrackers: benchmark.thirdPartyTrackers,
      listenersMouse: benchmark.listenersMouse,
      listenersSensor: benchmark.listenersSensor,
      listenersKeyboard: benchmark.listenersKeyboard,
      listenersTouch: benchmark.listenersTouch,
      fingerprinters: benchmark.fingerprinters,
      validateIdentity: {
        formula: `=IF(G${rowIndex}+H${rowIndex}>65,0,IF(G${rowIndex}+H${rowIndex}<15,5,5-(G${rowIndex}+H${rowIndex}-15)*0.1))`,
      },

      //=MAX(0,5-L2-(K2+J2+I2)*0.06)
      validateTrust: {
        formula: `=MAX(0,5-M${rowIndex}-(J${rowIndex}+K${rowIndex}+L${rowIndex})*0.06)`,
      },
      // =N2*0.7+O2*0.3
      validateOwnership: {
        formula: `=(S${rowIndex}*0.7+T${rowIndex}*0.3)*20`,
      },
    });

    sheet.addConditionalFormatting({
      ref: `S${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.ownershipIdentityScore,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });

    sheet.addConditionalFormatting({
      ref: `T${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.ownershipTrustScore,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });

    sheet.addConditionalFormatting({
      ref: `U${rowIndex}`,
      rules: [
        {
          type: 'containsText',
          operator: 'containsText',
          text: benchmark.ownershipScore,
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FFDEF9C9' },
            },
          },
        },
      ],
    });
  }
}

export async function exportVendors(config, jwtToken) {
  // For now, assume client = costco
  // Call API to get the costco data
  const vendorCategories = await getAllVendors(config, jwtToken);

  const { workbook, vendorSheet } = await setupVendorWorkbook(config, jwtToken);

  for (const vendorCategory of vendorCategories) {
    for (const vendor of vendorCategory.vendors) {
      const item = {
        category: vendorCategory.name,
        vendor: vendor.name,
      };
      for (const client of vendor.clients) {
        item[client.name] = 'x';
      }

      vendorSheet.addRow(item);
    }
  }

  // write the content using writeBuffer
  const buf = await workbook.xlsx.writeBuffer();

  // download the processed file
  saveAs(new Blob([buf]), `export.xlsx`);
}

export async function exportBenchmarks(config, jwtToken) {
  // For now, assume client = costco
  // Call API to get the costco data
  const benchmarks = await getAllBenchmarks(config, jwtToken);

  const { workbook, experienceSheet, performanceSheet, technologySheet } =
    setupBenchmarkWorkbook(config, jwtToken);

  // Save to the allPerformanceSheet
  const allPerformanceSheets = performanceSheet;

  // Save to the allExperienceSheet
  const allExperienceSheets = experienceSheet;

  // Save to the allTechnologySheet
  const allTechnologySheets = technologySheet;

  const pageIdentifiers: Array<string> = [];
  const experienceSheetMap: Map<string, any> = new Map();
  const performanceSheetMap: Map<string, any> = new Map();

  // Go get all of the unique page identifiers we are using (e.g. home, plp)
  for (const item of ['home', 'plp', 'pdp']) {
    const pageIdentifier = item;

    const { experienceSheet, performanceSheet } = createSheetForPageIdentifier(
      workbook,
      item
    );

    experienceSheetMap.set(item, experienceSheet);
    performanceSheetMap.set(item, performanceSheet);
  }

  for (const item of benchmarks.experience.data) {
    const pageIdentifier = item.pageIdentifier;

    saveToExperienceBenchmarkSheet(allExperienceSheets, item);

    if (pageIdentifier) {
      saveToExperienceBenchmarkSheet(
        experienceSheetMap.get(pageIdentifier),
        item
      );
    }
  }

  for (const item of benchmarks.performance.data) {
    const pageIdentifier = item.pageIdentifier;

    saveToPerformanceBenchmarkSheet(allPerformanceSheets, item);

    if (pageIdentifier) {
      saveToPerformanceBenchmarkSheet(
        performanceSheetMap.get(pageIdentifier),
        item
      );
    }
  }

  for (const item of benchmarks.technology.data) {
    saveToTechnologyBenchmarkSheet(allTechnologySheets, item);
  }

  for (const sheet of workbook.worksheets) {
    setGeneralStyling(sheet);
  }

  setExperienceSheetStyling(allExperienceSheets);
  setPerformanceSheetStyling(allPerformanceSheets);
  setTechnologySheetStyling(allTechnologySheets);

  // write the content using writeBuffer
  const buf = await workbook.xlsx.writeBuffer();

  // download the processed file
  saveAs(new Blob([buf]), `benchmarkExport.xlsx`);
}

export async function exportStructuralData(config, jwtToken) {
  const clients = await getAllClientsAllData(config, jwtToken);

  const { workbook, structuralSheet } = setupStructuralWorkbook(
    config,
    jwtToken
  );

  setStructuralStyling(structuralSheet);

  for (const client of clients) {
    // Create a row for each client
    if (client.name && client.name !== '') {
      saveToStructuralSheet(structuralSheet, client);
    }
  }

  // write the content using writeBuffer
  const buf = await workbook.xlsx.writeBuffer();

  // download the processed file
  saveAs(new Blob([buf]), `benchmarkStructuralData.xlsx`);
}

const darkGreen = '00e6b9';
const darkPurple = 'b982c1';
const lightPurple = 'e9d4ed';
const darkBlue = '83baef';
const lightBlue = 'd5e8fa';
const darkOrange = 'fe8d63';
const lightOrange = 'ffcdbb';
const darkRed = 'ff6e6a';
const lightRed = 'ffbbb9';
const darkGrey = 'cccccc';
const lightGrey = 'e5e5e5';

function setPerformanceSheetStyling(sheet) {
  // Final Performance Score
  setTotalColumn(sheet, 'G', darkGreen);

  // Happening Score
  setTotalColumn(sheet, 'H', darkPurple);

  // Happening Score Detail Columns
  for (const item of ['I', 'J', 'K']) {
    setTotalColumn(sheet, item, lightPurple);
  }

  // Useful Score
  setTotalColumn(sheet, 'L', darkBlue);

  // Useful Score Detail Columns
  for (const item of ['M', 'N']) {
    setTotalColumn(sheet, item, lightBlue);
  }

  // Usable Score
  setTotalColumn(sheet, 'O', darkOrange);

  // Useful Score Detail Columns
  for (const item of ['P', 'Q', 'R']) {
    setTotalColumn(sheet, item, lightOrange);
  }

  // Delightful Score
  setTotalColumn(sheet, 'S', darkRed);

  // Useful Score Detail Columns
  for (const item of ['T', 'U', 'V']) {
    setTotalColumn(sheet, item, lightRed);
  }
}

function setExperienceSheetStyling(sheet) {
  // Final Experience Score Main Color (Darker)
  setTotalColumn(sheet, 'F', darkGreen); // Dark Green

  sheet.getCell('F1').note = '= (accessibilityScore + readabilityScore) /2';

  // Accessibilty Score
  setTotalColumn(sheet, 'G', darkPurple); // Dark Purple

  // Accessibility Detail Columns
  for (const item of ['H', 'I', 'J']) {
    setTotalColumn(sheet, item, lightPurple); // Light Purple
  }

  // Readability Score
  setTotalColumn(sheet, 'K', darkBlue); // Dark Blue

  // Readability Detail Columns
  for (const item of ['L', 'M', 'N', 'O', 'P']) {
    setTotalColumn(sheet, item, lightBlue); // Light Blue
  }
}

function setTechnologySheetStyling(sheet) {
  // Final Score Main Color (Darker)
  setTotalColumn(sheet, 'E', darkGreen); // Dark Green

  // Identity Score
  setTotalColumn(sheet, 'F', darkPurple); // Dark Purple

  // Identity Detail Columns
  for (const item of ['G', 'H']) {
    setTotalColumn(sheet, item, lightPurple); // Light Purple
  }

  // Ownership Score
  setTotalColumn(sheet, 'I', darkBlue); // Dark Blue

  // Ownership Detail Columns
  for (const item of ['J', 'K', 'L', 'M']) {
    setTotalColumn(sheet, item, lightBlue); // Light Blue
  }

  // Other Score
  setTotalColumn(sheet, 'N', darkGrey);

  // Ownership Detail Columns
  for (const item of ['O', 'P', 'Q']) {
    setTotalColumn(sheet, item, lightGrey);
  }

  // Other Score
  setTotalColumn(sheet, 'R', `ffffff`);
}

function setStructuralStyling(sheet) {
  sheet.properties.outlineLevelCol = 1;

  sheet.views = [{ state: 'frozen', xSplit: 4, ySplit: 1, activeCell: 'A1' }];

  sheet.getRow(1).font = {
    bold: true,
    name: 'Calibri',
  };
}

function setGeneralStyling(sheet) {
  sheet.properties.outlineLevelCol = 1;

  sheet.views = [{ state: 'frozen', xSplit: 4, ySplit: 1, activeCell: 'A1' }];

  sheet.getColumn('A').hidden = true;
  sheet.getColumn('B').hidden = true;

  sheet.getRow(1).font = {
    bold: true,
    name: 'Calibri',
  };
}

function setTotalColumn(sheet, column, bgColor) {
  sheet.getColumn(column).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: bgColor }, // Purple
  };
}

export async function exportClient(config, jwtToken, clientId) {
  // TO DO - need to add
}

function createSheetForPageIdentifier(workbook, pageType) {
  const experienceSheet = workbook.addWorksheet(`Experience - ${pageType}`);
  const performanceSheet = workbook.addWorksheet(`Performance - ${pageType}`);

  experienceSheet.columns = getExperienceBenchmarkColumns();
  performanceSheet.columns = getPerformanceBenchmarkColumns();

  return {
    experienceSheet,
    performanceSheet,
  };
}

async function setupVendorWorkbook(config, jwtToken) {
  const workbook = new Excel.Workbook();
  const vendorSheet = workbook.addWorksheet('Vendors');

  vendorSheet.columns = await getVendorColumns(config, jwtToken);

  return {
    workbook: workbook,
    vendorSheet: vendorSheet,
  };
}

function setupStructuralWorkbook(config, jwtToken) {
  const workbook = new Excel.Workbook();
  const structuralSheet = workbook.addWorksheet('Structural Data');

  structuralSheet.columns = getStructuralColumns();

  return {
    workbook: workbook,
    structuralSheet: structuralSheet,
  };
}

function setupBenchmarkWorkbook(config, jwtToken) {
  const workbook = new Excel.Workbook();
  const experienceAllSheet = workbook.addWorksheet('Experience - All');
  const performanceAllSheet = workbook.addWorksheet('Performance - All');
  const technologyAllSheet = workbook.addWorksheet('Technology - All');

  experienceAllSheet.columns = getExperienceBenchmarkColumns();
  performanceAllSheet.columns = getPerformanceBenchmarkColumns();
  technologyAllSheet.columns = getTechnologyBenchmarkColumns();

  return {
    workbook: workbook,
    experienceSheet: experienceAllSheet,
    performanceSheet: performanceAllSheet,
    technologySheet: technologyAllSheet,
  };
}

async function getVendorColumns(config, jwtToken) {
  const clients = await getAllClients(config, jwtToken);
  const headers: Array<any> = [];

  headers.push({
    header: 'category',
    key: 'category',
    width: 20,
  });

  headers.push({
    header: 'vendor',
    key: 'vendor',
    width: 20,
  });

  for (const client of clients) {
    headers.push({
      header: client.name,
      key: client.name,
      width: 20,
    });
  }

  return headers;
}

function getPerformanceBenchmarkColumns() {
  const highlighted = {
    width: 25,
  };

  return [
    { header: 'ID', key: 'id', width: 20 },
    { header: 'Is Latest?', key: 'latest', width: 20 },
    { header: 'Created', key: 'createdAt', width: 20 },
    { header: 'Client', key: 'client', width: 20 },
    { header: 'Page Type', key: 'pageIdentifier', width: 15 },
    { header: 'Device Type', key: 'deviceType', width: 15 },
    {
      header: 'Performance Score',
      key: 'performanceScore',
      width: 20,
    },
    {
      header: '"Happening" Score',
      key: 'performanceHappeningScore',
      width: 20,
    },

    {
      header: 'labFcp',
      key: 'labFcp',
      width: 10,
    },
    {
      header: 'cruxFcp',
      key: 'cruxFcp',
      width: 10,
    },
    {
      header: 'labTbt',
      key: 'labTbt',
      width: 10,
    },

    {
      header: '"Useful" Score',
      key: 'performanceUsefulScore',
      width: 20,
    },
    {
      header: 'labLcp',
      key: 'labLcp',
      width: 10,
    },
    {
      header: 'cruxLcp',
      key: 'cruxLcp',
      width: 10,
    },

    {
      header: 'performanceUsableScore',
      key: 'performanceUsableScore',
      width: 20,
    },
    {
      header: 'labTti',
      key: 'labTti',
      width: 10,
    },
    {
      header: 'labFid',
      key: 'labFid',
      width: 10,
    },
    {
      header: 'cruxInp',
      key: 'cruxInp',
      width: 10,
    },

    {
      header: 'performanceDelightfulScore',
      key: 'performanceDelightfulScore',
      width: 20,
    },
    {
      header: 'labCls',
      key: 'labCls',
      width: 10,
    },
    {
      header: 'cruxCls',
      key: 'cruxCls',
      width: 10,
    },
    {
      header: 'labSi',
      key: 'labSi',
      width: 20,
    },
    {
      header: '-',
      key: 'perfSpacer',
      width: 20,
    },
    {
      header: 'lighthouseScore',
      key: 'lighthouseScore',
      width: 20,
    },
    {
      header: 'labFcpScore',
      key: 'labFcpScore',
      width: 20,
    },
    {
      header: 'cruxFcpScore',
      key: 'cruxFcpScore',
      width: 20,
    },
    {
      header: 'labTbtScore',
      key: 'labTbtScore',
      width: 20,
    },
    {
      header: 'labLcpScore',
      key: 'labLcpScore',
      width: 20,
    },
    {
      header: 'cruxLcpScore',
      key: 'cruxLcpScore',
      width: 20,
    },
    {
      header: 'labTtiScore',
      key: 'labTtiScore',
      width: 20,
    },
    {
      header: 'labFidScore',
      key: 'labFidScore',
      width: 20,
    },
    {
      header: 'cruxInpScore',
      key: 'cruxInpScore',
      width: 20,
    },
    {
      header: 'labClsScore',
      key: 'labClsScore',
      width: 20,
    },
    {
      header: 'cruxClsScore',
      key: 'cruxClsScore',
      width: 20,
    },
    {
      header: 'labSiScore',
      key: 'labSiScore',
      width: 20,
    },
  ];
}

function getTechnologyBenchmarkColumns() {
  const highlighted = {
    width: 25,
  };

  return [
    { header: 'ID', key: 'id', width: 20 },
    { header: 'Is Latest?', key: 'latest', width: 20 },
    { header: 'Created', key: 'createdAt', width: 20 },
    { header: 'Client', key: 'client', width: 20 },
    {
      header: 'Ownership Score',
      key: 'ownershipScore',
      width: 20,
    },
    {
      header: 'Identity Score',
      key: 'identityScore',
      width: 20,
    },

    {
      header: 'thirdPartyCookies',
      key: 'thirdPartyCookies',
      width: 20,
    },
    {
      header: 'thirdPartyTrackers',
      key: 'thirdPartyTrackers',
      width: 20,
    },

    {
      header: 'Trust Score',
      key: 'trustScore',
      width: 20,
    },

    {
      header: 'listenersSensor',
      key: 'listenersSensor',
      width: 20,
    },
    {
      header: 'listenersKeyboard',
      key: 'listenersKeyboard',
      width: 20,
    },
    {
      header: 'listenersTouch',
      key: 'listenersTouch',
      width: 20,
    },
    {
      header: 'fingerprinters',
      key: 'fingerprinters',
      width: 20,
    },

    {
      header: 'Other',
      key: 'other',
      width: 20,
    },
    {
      header: 'listenersMouse',
      key: 'listenersMouse',
      width: 20,
    },
    {
      header: 'firstPartyDomains',
      key: 'firstPartyDomains',
      width: 20,
    },
    {
      header: 'thirdPartyDomains',
      key: 'thirdPartyDomains',
      width: 20,
    },
    {
      header: '-',
      key: 'spacer',
      width: 20,
    },
    {
      header: 'Validate Identity',
      key: 'validateIdentity',
      width: 20,
    },
    {
      header: 'Validate Trust',
      key: 'validateTrust',
      width: 20,
    },
    {
      header: 'Validate Ownership',
      key: 'validateOwnership',
      width: 20,
    },
  ];
}

export function getPerformanceCruxColumns(metric) {
  const categories = ['good', 'improve', 'poor'];
  const fields = ['start', 'end', 'density'];
  const columns: Array<any> = [];

  for (const category of categories) {
    for (const field of fields) {
      columns.push({
        header: `${metric}_${category}_${field}`,
        key: `${metric}_${category}_${field}`,
        width: 30,
        style: {},
      });
    }
  }
  return columns;
}

export function getPerformanceCruxValues(metric, data) {
  if (data && data.crux) {
    const values: any = {};

    const categories = ['good', 'improve', 'poor'];
    const fields = ['start', 'end', 'density'];
    const columns: Array<any> = [];

    let columnIndex = 0;

    for (const category of categories) {
      for (const field of fields) {
        if (data.crux[metric]) {
          values[`${metric}_${category}_${field}`] =
            data.crux[metric].histogram[columnIndex][field];
        }
      }
      columnIndex = columnIndex + 1;
    }

    if (data.crux[metric]) {
      values[`${metric}_metric`] = data.crux[metric].percentiles['p75'];
    } else {
      values[`${metric}_metric`] = 0;
    }

    return values;
  } else {
    return undefined;
  }
}
