import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { uiSchema } from '../../../forms/admins';

const AdminServiceEdit = () => {
  const { adminGroupApi } = useBlueprintAdmin();

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [groups, setGroups] = useState<undefined | Array<any>>(undefined);

  const onSuccess = (response) => {
    history.push({
      pathname: `/admins/service/${response.data.id}`,
      state: { detail: 'newService', token: response.data.apiToken },
    });
  };
  const onFailure = (data) => {
    console.error(data);
  };

  useEffect(() => {
    adminGroupApi!
      .getGroups()
      .then(({ data }) => {
        const response = data.map((group) => ({
          type: 'string',
          enum: group,
          enumNames: group.name,
          title: group.name,
        }));
        setGroups(response);
      })
      .catch((e) => console.error(e));
  }, []);

  const schema = {
    title: 'Edit Service Account',
    description:
      'By editing this service account, please be aware that the API Token associated with it will change and will be required to update in systems interacting with blueprint to allow for any group/permissions changes',
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: 'Username',
        description:
          'The username of the service account. This is recommended that it is not an email address, but an easy to reference username based on usage.',
      },
      groups: {
        type: 'array',
        title: 'Groups',
        items: {
          title: 'Groups',
          type: 'string',
          enum: groups?.map((group: any) => group.enum.id),
          enumNames: groups?.map((group: any) => group.enumNames),
        },
        description: 'Groups to be applied to this service account',
      },
    },
  };

  return (
    <>
      {id && groups && (
        <>
          <AdminBaseForm
            id={id}
            url='/admin/admins'
            entityTitle='Admin Service Account'
            schema={groups ? schema : {}}
            uiSchema={uiSchema}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </>
      )}
    </>
  );
};

export default AdminServiceEdit;
