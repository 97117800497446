const SlideTwo = () => {
  return (
    <section data-auto-animate>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-ee6ac0'
        style={{
          width: '438.065px',
          height: '605px',
          left: '67px',
          top: '57.5px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 14 }}>
          <img
            style={{}}
            data-natural-width={391}
            data-natural-height={540}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100665/images/9411106/pasted-from-clipboard.png'
          />
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-bfa7b2'
        style={{
          width: '1280px',
          height: '169.333px',
          left: '0px',
          top: '0px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 13 }}>
          <img
            style={{}}
            data-natural-width={960}
            data-natural-height={127}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2100665/images/9411341/pasted-from-clipboard.png'
          />
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='shape'
        data-name='shape-8b97e2'
        style={{
          width: '640px',
          height: '122px',
          left: '560px',
          top: '191px',
        }}
      >
        <div
          className='sl-block-content top1'
          data-shape-type='rect'
          data-shape-fill-color='rgb(255, 255, 255)'
          data-shape-stretch='true'
          style={{ zIndex: 10 }}
          data-shape-stroke-color='#ffd966'
          data-shape-stroke-width='2px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            width='100%'
            height='100%'
            preserveAspectRatio='none'
            viewBox='0 0 640 122'
          >
            <defs>
              <clipPath id='shape-mask-1-1652365553175'>
                <rect
                  width={640}
                  height={122}
                  rx={0}
                  ry={0}
                  fill='rgb(255, 255, 255)'
                  stroke='#ffd966'
                  strokeWidth={4}
                />
              </clipPath>
            </defs>
            <rect
              width={640}
              height={122}
              rx={0}
              ry={0}
              className='shape-element'
              fill='rgb(255, 255, 255)'
              stroke='#ffd966'
              strokeWidth={4}
              clipPath='url(#shape-mask-1-1652365553175)'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='shape'
        style={{
          width: '640px',
          height: '122px',
          left: '560px',
          top: '339px',
        }}
        data-name='shape-c78e5d'
      >
        <div
          className='sl-block-content'
          data-shape-type='rect'
          data-shape-fill-color='rgb(255, 255, 255)'
          data-shape-stretch='true'
          style={{ zIndex: 11 }}
          data-shape-stroke-color='#6d9eeb'
          data-shape-stroke-width='2px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            width='100%'
            height='100%'
            preserveAspectRatio='none'
            viewBox='0 0 640 122'
          >
            <defs>
              <clipPath id='shape-mask-2-1652365553175'>
                <rect
                  width={640}
                  height={122}
                  rx={0}
                  ry={0}
                  fill='rgb(255, 255, 255)'
                  stroke='#6d9eeb'
                  strokeWidth={4}
                />
              </clipPath>
            </defs>
            <rect
              width={640}
              height={122}
              rx={0}
              ry={0}
              className='shape-element'
              fill='rgb(255, 255, 255)'
              stroke='#6d9eeb'
              strokeWidth={4}
              clipPath='url(#shape-mask-2-1652365553175)'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='shape'
        style={{
          width: '640px',
          height: '122px',
          left: '560px',
          top: '484.5px',
        }}
        data-name='shape-8dcbf4'
      >
        <div
          className='sl-block-content'
          data-shape-type='rect'
          data-shape-fill-color='rgb(255, 255, 255)'
          data-shape-stretch='true'
          style={{ zIndex: 12 }}
          data-shape-stroke-color='#93c47d'
          data-shape-stroke-width='2px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            width='100%'
            height='100%'
            preserveAspectRatio='none'
            viewBox='0 0 640 122'
          >
            <defs>
              <clipPath id='shape-mask-3-1652365553175'>
                <rect
                  width={640}
                  height={122}
                  rx={0}
                  ry={0}
                  fill='rgb(255, 255, 255)'
                  stroke='#93c47d'
                  strokeWidth={4}
                />
              </clipPath>
            </defs>
            <rect
              width={640}
              height={122}
              rx={0}
              ry={0}
              className='shape-element'
              fill='rgb(255, 255, 255)'
              stroke='#93c47d'
              strokeWidth={4}
              clipPath='url(#shape-mask-3-1652365553175)'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '615.055px',
          left: '567.5px',
          top: '201px',
          height: 'auto',
        }}
        data-name='text-c5947b'
      >
        <div
          className='sl-block-content'
          style={{
            textAlign: 'left',
            zIndex: 15,
            fontSize: '90%',
            borderWidth: '4px',
            lineHeight: '1.17',
            borderColor: 'rgb(0, 0, 0)',
            padding: '10px',
            color: 'rgb(0, 0, 0)',
          }}
          data-has-line-height
          dir='ui'
        >
          <h3>
            <span style={{ fontSize: '24.57px' }}>
              We've noticed leading digital experiences are becoming more and
              more <strong>differentiated</strong>.
            </span>
          </h3>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '606.055px',
          left: '572px',
          top: '349px',
          height: 'auto',
        }}
        data-name='text-792d35'
      >
        <div
          className='sl-block-content'
          style={{
            textAlign: 'left',
            zIndex: 16,
            fontSize: '90%',
            borderWidth: '4px',
            lineHeight: '1.17',
            borderColor: 'rgb(0, 0, 0)',
            padding: '10px',
            color: 'rgb(0, 0, 0)',
          }}
          data-has-line-height
          dir='ui'
        >
          <h3>
            <span style={{ fontSize: '24.57px' }}>
              You need to <strong>invest in and own </strong>the drivers of
              differentiation.
            </span>
          </h3>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '606.055px',
          left: '572px',
          top: '515px',
          height: 'auto',
        }}
        data-name='text-9e842c'
      >
        <div
          className='sl-block-content'
          style={{
            textAlign: 'left',
            zIndex: 17,
            fontSize: '90%',
            borderWidth: '4px',
            lineHeight: '1.17',
            borderColor: 'rgb(0, 0, 0)',
            padding: '10px',
            color: 'rgb(0, 0, 0)',
          }}
          data-has-line-height
          dir='ui'
        >
          <h3>
            <span style={{ fontSize: '24.57px' }}>
              The question is <strong>where</strong> and <strong>how</strong>.
            </span>
          </h3>
        </div>
      </div>
    </section>
  );
};

export default SlideTwo;
