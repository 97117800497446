import { ConfigUserAPI } from '@deloitte/blueprint-sdk-admin';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import AdminCard from '../../../components/AdminCard';
import AdminPage from '../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const ConfigNotificationTemplateDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const [content, setContent] = useState<any>();
  const [contentValue, setContentValue] = useState<any>();
  const configureUserAPI = new ConfigUserAPI(config);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (id) {
      configureUserAPI
        .getNotificationsTemplate(id)
        .then(({ data }) => {
          setContent(data);
          setContentValue(data.value);
        })
        .catch((e) => console.error(e));
    }
  }, [id, jwtToken]);

  return (
    <>
      {id && content && contentValue && (
        <AdminPage animation={true} title={`Notification Template`}>
          <Grid container justifyContent='space-between' direction='row'>
            <Grid xs={6} item>
              <AdminCard title='Manage'>
                <h5>Identifier </h5> {content.id}
                <h5>Type </h5> {contentValue.type}
                {contentValue.subject && (
                  <>
                    <h5>Subject Template</h5>
                    <div
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                      style={{
                        overflow: 'scroll',
                        height: 200,
                        width: 500,
                        padding: 10,
                        borderColor: '#ccc',
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    >
                      {contentValue.subject}
                    </div>
                  </>
                )}
                {contentValue.html && (
                  <>
                    <h5>HTML Template</h5>
                    <div
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                      style={{
                        overflow: 'scroll',
                        height: 200,
                        width: 500,
                        padding: 10,
                        borderColor: '#ccc',
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    >
                      {contentValue.html}
                    </div>
                  </>
                )}
                {contentValue.text && (
                  <>
                    <h5>Text Template</h5>
                    <div
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                      style={{
                        overflow: 'scroll',
                        height: 200,
                        width: 400,
                        padding: 10,
                        borderColor: '#ccc',
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    >
                      {contentValue.text}
                    </div>
                  </>
                )}
              </AdminCard>
            </Grid>
            <Grid xs={6} item>
              <AdminBaseForm
                url='/admin/notification-test-needs-hooked-up'
                schema={{
                  type: 'object',
                  title: 'Test this template',
                  description:
                    'You can send this template to an address to validate it prior to usage',
                  properties: {
                    to: {
                      title: 'Send To',
                      type: 'string',
                      description: 'Email or Phone number to send to',
                    },
                  },
                }}
                uiSchema={{}}
                // eslint-disable-next-line
                onSuccess={() => {}}
              />
            </Grid>
          </Grid>
        </AdminPage>
      )}
    </>
  );
};

export default ConfigNotificationTemplateDetail;
