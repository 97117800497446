export const uiSchema = {
  config: {},
};

export const schema = {
  properties: {
    name: { type: 'string' },
  },
  type: 'object',
  required: ['name'],
};
