import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';

const UserGroupNew = (formSchema: any = {}) => {
  const history = useHistory();

  const onSuccess = () => {
    history.push({
      pathname: `/users/group`,
      state: { detail: 'newUserGroup' },
    });
  };

  return (
    <AdminBaseForm
      url='/admin/user-groups'
      entityTitle='User Group'
      schema={formSchema.formSchema}
      onSuccess={onSuccess}
    />
  );
};

export default UserGroupNew;
