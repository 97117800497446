import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const UserGroupAdd = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { adminUserApi } = useBlueprintAdmin();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    adminUserApi
      ?.getUsers({ limit: 9999, page: 1 })
      .then(({ data }) => {
        const response = data.map((user) => ({
          type: 'string',
          enum: user,
          enumNames: user.username,
        }));
        setUsers(response);
      })
      .catch((err) => console.error(err));
  }, []);

  const onSuccess = () => {
    history.push({
      pathname: `/users/group/${id}`,
    });
  };

  const schema = {
    description: 'Add user to group',
    type: 'object',
    properties: {
      userId: {
        type: 'string',
        title: 'User',
        enum: users.map((user: any) => user.enum.id),
        enumNames: users.map((user: any) => user.enumNames),
        description: 'The user we are adding to the group',
      },
      groupId: {
        type: 'string',
        default: id,
      },
      relationship: {
        type: 'string',
        title: 'Relationship',
        description: 'The type of relationship associated with the user',
      },
    },
    required: ['userId'],
  };

  const uiSchema = {
    userId: { 'ui:widget': 'autoComplete' },
    groupId: { 'ui:widget': 'hidden' },
  };

  return (
    <AdminBaseForm
      url='/admin/user-groups/add'
      entityTitle='User Group'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default UserGroupAdd;
