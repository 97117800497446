import { ConfigUserAPI, IFixedSidebar } from '@deloitte/blueprint-sdk-admin';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import AdminCard from '../components/AdminCard';
import AdminFlow from '../components/AdminFlow';
import AdminPage from '../components/AdminPage';
import { useBlueprintAdmin } from '../components/AdminProvider';
import { AdminToastTimed } from '../components/AdminToasts';
import AdminToggleGrid from '../components/AdminToggleGrid';
import { clearConfigCache } from '../config/dynamicConfig';
import { uuidv4 } from '../utils/uuid';
const Setup = () => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [toastController, setToastController]: any = useState(null);
  const { config } = useBlueprintAdmin();
  const history = useHistory();
  const configApi = new ConfigUserAPI(config);
  const alertTimeoutTime = 5000;
  const flow: Array<{
    title: string;
    description: string;
    template: React.ReactNode;
    onSubmit: any;
  }> = [
    {
      title: 'Select Capabilities',
      description: '',
      template: (
        <AdminCard title='Select capabilities that you want to setup'>
          <AdminToggleGrid
            onChange={(selected) => {
              if (selected != undefined) {
                setSelectedOptions(selected);
              }
            }}
          />
        </AdminCard>
      ),
      onSubmit: async () => {
        // move core sidebar items to the bottom of the sidebar
        if (selectedOptions.length > 0) {
          selectedOptions.push(
            selectedOptions.splice(
              selectedOptions.findIndex((option) => option == 'core'),
              1
            )[0]
          );
        }

        clearConfigCache();

        const fixedSidebarItems = [] as IFixedSidebar[];
        const roleSidebarItems: Array<string> = [];
        fixedSidebarItems.push({
          label: 'Dashboard',
          route: '/',
          icon: 'HomeOutlined',
        });

        for (const option of selectedOptions) {
          if (option === 'core') {
            // Core includes Admins/Users/Config
            roleSidebarItems.push('Admins');
            roleSidebarItems.push('Users');
            roleSidebarItems.push('Config');
          }
          if (option === 'activity') {
            roleSidebarItems.push('Beacons');
            roleSidebarItems.push('Triggers');
          }

          if (option === 'content') {
            fixedSidebarItems.push({
              label: 'Content',
              route: '/content',
              icon: 'AssignmentOutlined',
            });
          }

          if (option === 'notifications') {
            roleSidebarItems.push('Notifications');
          }

          await configApi.updateSideBar('fixed', fixedSidebarItems);
          await configApi.updateSideBar('role', roleSidebarItems);
          await configApi.updateCoreCapabilities(selectedOptions);
        }
        if (selectedOptions.length === 0) {
          setToastController('emptySetupOptions'.concat(uuidv4()));
          return false;
        } else {
          setToastController(null);
          return true;
        }
      },
    },
    {
      title: 'Invite Users',
      description: '',
      template: (
        <AdminCard title=''>
          <div style={{ width: '100%', height: '500px' }}></div>
        </AdminCard>
      ),
      onSubmit: () => {
        history.push({
          pathname: `/`,
          state: { detail: 'SetupComplete'.concat(uuidv4()) },
        });
        return true;
      },
    },
  ];

  useEffect(() => {
    const timeId = setTimeout(() => {
      setToastController(null);
      clearTimeout(timeId);
    }, alertTimeoutTime);
  }, [toastController]);

  return (
    <>
      <AdminToastTimed
        message={'Please Select At Least One Capability'}
        trigger={
          toastController?.includes('emptySetupOptions') ? uuidv4() : null
        }
        time={alertTimeoutTime}
        severity={'error'}
      ></AdminToastTimed>
      <Grid container>
        <Grid container style={{ marginLeft: '20px', marginTop: '20px' }}>
          <Grid item>
            <SVG src={'/assets/images/logo.svg'}></SVG>
          </Grid>
          <Grid item>
            <Typography
              variant='h5'
              style={{
                fontSize: '35px',
                marginTop: '0px',
                paddingTop: '0px',
                paddingLeft: '16px',
              }}
            >
              Blueprint
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AdminPage title='Get Started' showSidebar={false}>
            <AdminFlow flow={flow}></AdminFlow>
          </AdminPage>
        </Grid>
      </Grid>
    </>
  );
};

export default Setup;
