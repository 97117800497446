import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link, useHistory } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminRecord from '../../components/AdminRecord';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../components/AdminTable';
import { useBlueprintAdmin } from '../AdminProvider';
import UserDetailForm from './UserDetailForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  profileTable: {},
  profileCard: {
    paddingBottom: theme.spacing(2),
  },
  tableHeader: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[100],
  },
}));

const UserGroupTemplate = ({ id }) => {
  const { config } = useBlueprintAdmin();

  const api = new AdminScopedAPI(config, 'GroupDetail');

  const history = useHistory();

  const classes = useStyles();

  const getValidFields = (user) => {
    const fields = Object.entries(user);
    const validFields: Array<any> = [];
    for (const field of fields) {
      if (field[0] !== 'users') {
        validFields.push(field);
      }
    }

    return validFields;
  };

  const groupTemplate = (title, group) => (
    <Grid className={classes.profileCard}>
      <AdminCard title={title}>
        {group && (
          <>
            <Table className={classes.profileTable}>
              <TableBody>
                {getValidFields(group).map(([key, value]: any, i) => (
                  <AdminTableRow key={i}>
                    <AdminTableHeadCell className={classes.tableHeader}>
                      {key}
                    </AdminTableHeadCell>
                    <TableCell>{value}</TableCell>
                  </AdminTableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!group && (
          <>
            <Skeleton variant='rectangular' height={370} width='100%' />
          </>
        )}
      </AdminCard>
    </Grid>
  );

  const traitTemplate = (title, profile) => (
    <>
      <Grid className={classes.profileCard}>
        <UserDetailForm user={profile} id={id} title={title} />
      </Grid>
    </>
  );

  const usersTemplate = (title, group) => (
    <>
      {/* {group && group.users && group.users.length > 0 && (
        <Grid className={classes.profileCard}>
          <AdminCard title={title}>
            <Table>
              <TableBody>
                {group.users.map((user: any) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      )} */}

      <AdminCard title={title} newAction={`/users/group/${id}/add`}>
        {group && group.users && group.users.length > 0 && (
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>email</AdminTableHeadCell>
                <AdminTableHeadCell>status</AdminTableHeadCell>
                <AdminTableHeadCell></AdminTableHeadCell>
                <AdminTableHeadCell></AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {group.users.map((user: any) => (
                <TableRow key={user.id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    <Button to={`/users/${user.id}`} component={Link}>
                      View
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      style={{ backgroundColor: '#d32f2f', color: '#fff' }}
                      onClick={() => {
                        api
                          .delete({
                            path: '/admin/user-groups/remove',
                            body: {
                              groupId: id,
                              userId: user.id,
                            },
                          })
                          .then(() => {
                            history.push(`/users/group/`);
                          })
                          .catch((reason) => {
                            console.error(reason);
                          });
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </AdminCard>
    </>
  );

  return (
    <>
      {id && (
        <Grid container>
          <Grid item sm={12} md={6}>
            <AdminRecord
              loadingTemplate={groupTemplate('Group', undefined)}
              form={true}
              url={`/admin/user-groups/${id}`}
              itemTemplate={groupTemplate.bind(null, 'Group')}
            />
            {/* user list */}
            <AdminRecord
              loadingTemplate={usersTemplate('Users', undefined)}
              form={true}
              url={`/admin/user-groups/${id}`}
              itemTemplate={usersTemplate.bind(null, 'Users')}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            {/* @TODO log user group activities - added user to group etc */}
            {/* <UserActivity id={id} /> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserGroupTemplate;
