import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import BenchmarkNumber from './AdminBenchmarkNumber';
import AdminCard from './AdminCard';

function AdminBenchmarkPerformanceOverview({ data }) {
  return (
    <>
      <AdminCard
        title={data.page.name}
        customAction={
          data.properties.metadata.jsonUrl && (
            <Link
              href={data.properties.metadata.jsonUrl}
              target='_blank'
              rel='noreferrer'
            >
              Download Raw WPT Data
            </Link>
          )
        }
      >
        <Typography variant='caption'>{data.page.url}</Typography>
        <Grid container item xs={12} spacing={5} style={{ paddingTop: 40 }}>
          <Grid container item xs={12}>
            <Grid container item xs={12} style={{ paddingBottom: 10 }}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  What we found in our 'lab' environment
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  Synthetic data is collected in a 'lab' environment where each
                  scan is run under the same network and device circumstances.
                  Each run, performs this scan 3 times and calculates the
                  median. This number is critical to create an apples to apples
                  comparison of performance data.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={5}>
              <BenchmarkNumber
                size={6}
                title='Is it happening?'
                description='First Contentful Paint'
                value={data.properties.results.labFirstContentfulPaint}
                caption='This is based on the web vital First Contentful Paint, which is when the browser renders the first peices of content on your website, providing the first feedback to the user that the page is actually loading'
              />
              <BenchmarkNumber
                size={6}
                title='Is it useful?'
                description='Largest Contentful Paint'
                value={data.properties.results.labLargestContentfulPaint}
                caption='This is based on the web vital Largest Contentful Paint, which measures the time a website takes to show the user the largest content on the screen, complete and ready for interaction'
              />
              <BenchmarkNumber
                size={6}
                title='Is it useable?'
                description='Cumulative Layout Shift'
                value={data.properties.results.labCumulativeLayoutShift}
                caption='This is based on the web vital Cumulative Layout Shift, which measures the amount of distance that your content adjusts for your users as it loads'
              />
              <BenchmarkNumber
                size={6}
                title='Is it useable?'
                description='Cumulative Layout Shift'
                value={data.properties.results.maxFID}
                caption='This is based on the web vital First Input Delay, which measures how quickly a user can interact with an element on the webpage'
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} style={{ paddingBottom: 10 }}>
              <Grid item xs={12}>
                <Typography variant='h4'>
                  What a majority of your visitors experience
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  We use CrUX metrics, which is available from Chrome's opted in
                  users based on their real interaction with this website. The
                  numbers below are a number of the 75% percentile of usage
                  across all of these users. This is helpful to represent what
                  users might be really experiencing.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={5}>
              <BenchmarkNumber
                size={6}
                title='Is it happening?'
                description='First Contentful Paint'
                value={
                  data.properties.results.crux
                    ? data.properties.results.crux.first_contentful_paint
                      ? data.properties.results.crux.first_contentful_paint
                          .percentiles.p75
                      : '-'
                    : '-'
                }
                caption='This is based on the web vital First Contentful Paint, which is when the browser renders the first peices of content on your website, providing the first feedback to the user that the page is actually loading'
              />
              <BenchmarkNumber
                size={6}
                title='Is it useful?'
                description='Largest Contentful Paint'
                value={
                  data.properties.results.crux
                    ? data.properties.results.largest_contentful_paint
                      ? data.properties.results.largest_contentful_paint
                          .percentiles.p75
                      : '-'
                    : '-'
                }
                caption='This is based on the web vital Largest Contentful Paint, which measures the time a website takes to show the user the largest content on the screen, complete and ready for interaction'
              />
              <BenchmarkNumber
                size={6}
                title='Is it useable?'
                description='Cumulative Layout Shift'
                value={
                  data.properties.results.crux
                    ? data.properties.results.crux.cumulative_layout_shift
                      ? data.properties.results.crux.cumulative_layout_shift
                          .percentiles.p75
                      : '-'
                    : '-'
                }
                caption='This is based on the web vital Cumulative Layout Shift, which measures the amount of distance that your content adjusts for your users as it loads'
              />
              <BenchmarkNumber
                size={6}
                title='Is it Delightful?'
                description='First Input Delay'
                value={
                  data.properties.results.crux
                    ? data.properties.results.crux.first_input_delay
                      ? data.properties.results.crux.first_input_delay
                          .percentiles.p75
                      : '-'
                    : '-'
                }
                caption='This is based on the web vital First Input Delay, which measures how quickly a user can interact with an element on the webpage'
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AdminCard title='Vendors'>
              {/* <AdminBenchmarkVendorTable
                data={data.properties.results.detectedApps}
              /> */}
              <Table>
                {/* {JSON.stringify(data.properties.results.detectedApps, null, 2)} */}
                {data.properties.results.detectedApps && (
                  <TableBody>
                    {Object.keys(data.properties.results.detectedApps).map(
                      (vendorCategory, index) => (
                        <TableRow key={index}>
                          <TableCell>{vendorCategory}</TableCell>
                          <TableCell>
                            {
                              data.properties.results.detectedApps[
                                vendorCategory
                              ]
                            }
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
            </AdminCard>
          </Grid>
        </Grid>
      </AdminCard>
    </>
  );
}

export default AdminBenchmarkPerformanceOverview;
