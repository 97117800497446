import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminCard from '../../../components/AdminCard';
import AdminModal from '../../../components/AdminModal';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../../components/AdminTable';

const TriggerHeader = ({ trigger }: any) => {
  const { config, adminTriggerApi } = useBlueprintAdmin();
  const history = useHistory();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [triggerName, setTriggerName] = useState<string>(trigger.name);

  const handleSave = async () => {
    const response = await adminTriggerApi!.updateTrigger(trigger.id, {
      name: triggerName,
    });
    if (response.success) {
      setTriggerName(response.data.name);
    } else {
      console.error(response.message);
    }
    setIsEditing(false);
  };

  const handleDelete = async () => {
    const response = await adminTriggerApi!.delete({
      path: `/admin/triggers/${trigger.id}`,
    });
    if (response.success) {
      history.push(`/triggers`);
    } else {
      console.error(response.message);
    }
  };

  return (
    <AdminCard>
      <Typography variant='h5'>
        <Box paddingBottom={2}>
          {!isEditing ? (
            <Box fontWeight={'bold'}>
              {triggerName}{' '}
              <IconButton
                onClick={() => setIsEditing(true)}
                aria-label='edit trigger name'
                size='small'
                data-testid='edit-button'
              >
                <EditOutlinedIcon />
              </IconButton>
            </Box>
          ) : (
            <TextField
              onChange={(e) => {
                setTriggerName(e.target.value);
              }}
              defaultValue={trigger.name}
              value={triggerName}
              variant='outlined'
              error={!triggerName}
              helperText={!triggerName ? 'Select a name for the trigger' : ''}
            />
          )}
        </Box>
      </Typography>
      <Grid container>
        <Table>
          <AdminTableHead>
            <TableRow>
              <AdminTableHeadCell>ID</AdminTableHeadCell>
              <AdminTableHeadCell>Type</AdminTableHeadCell>
              <AdminTableHeadCell>Beacon</AdminTableHeadCell>
            </TableRow>
          </AdminTableHead>
          <TableBody>
            <AdminTableRow>
              <TableCell component='th' scope='row'>
                {trigger.id}
              </TableCell>
              <TableCell component='th' scope='row'>
                {trigger.type}
              </TableCell>
              <TableCell component='th' scope='row'>
                {trigger.beaconId}
              </TableCell>
            </AdminTableRow>
          </TableBody>
        </Table>
        <Grid container justifyContent='flex-end'>
          {isEditing ? (
            <>
              <Box marginTop={2} marginRight={3}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => setIsEditing(false)}
                  aria-label='cancel trigger name edit'
                >
                  Cancel
                </Button>
              </Box>
              <Box marginTop={2}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSave}
                  aria-label='cancel trigger name edit'
                  disabled={!triggerName}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <Box marginTop={2}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => setIsModalOpen(true)}
                aria-label='delete trigger'
              >
                Delete
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <AdminModal
        title={`Delete Trigger?`}
        body={
          <Typography variant='body1'>
            Are you sure you would like to delete this trigger?
          </Typography>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button variant='text' color='primary' onClick={handleDelete}>
              DELETE
            </Button>
          </>
        }
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
    </AdminCard>
  );
};

export default TriggerHeader;
