import { AdminAPI } from '@deloitte/blueprint-sdk-admin';
import { AuthState } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { toastSuccess } from '../../config/toast';

const AdminOktaAuth = () => {
  const { mounted, config } = useBlueprintAdmin();

  const { authState, oktaAuth } = useOktaAuth();

  const adminApi = new AdminAPI({
    apiRoot: config.apiRoot,
    debug: config.debug,
  });

  const handleLoginResponse = (response) => {
    switch (response.state) {
      case 'loggedIn':
        toastSuccess('You are now logged in');
        const lastPage = localStorage.getItem('bp-timeout-location');
        window.location.replace(lastPage ?? config.startPage);
        localStorage.removeItem('bp-timeout-location');
        break;
    }
  };

  // Handle the Callback from authentication in okta
  useEffect(() => {
    async function authenticate() {
      if (!authState) return;

      if (!authState.isAuthenticated) {
        await oktaAuth.signInWithRedirect();
      }
    }

    async function providerLogin(authState: AuthState) {
      if (authState && authState.idToken) {
        const result = await adminApi.providerLogin({
          service: 'okta',
          token: authState.idToken?.idToken,
        });
        handleLoginResponse(result);
      }
    }

    if (config.authMethod.type === 'okta') {
      // If someone lands here with okta enabled
      // and the user is already authenticated
      // then we now need to actually do provider login and we're good to go
      if (authState && authState?.isAuthenticated) {
        providerLogin(authState).catch((e) => {
          console.error(e);
        });
      } else {
        authenticate().catch((e) => {
          console.error(e);
        });
      }
    }
  }, [authState, oktaAuth]);

  return <p>Logging In</p>;
};

export default AdminOktaAuth;
