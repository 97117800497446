import cubejs, { ResultSet } from '@cubejs-client/core';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import QueryBuilder, { ValueEditorType } from 'react-querybuilder';
import { useHistory } from 'react-router-dom';
import { uuidv4 } from '../../utils/uuid';
import AdminCard from '../AdminCard';
import { useBlueprintAdmin } from '../AdminProvider';
import { AdminToolTip } from '../AdminToolTip';
import {
  MaterialActionElement,
  MaterialNotToggle,
  MaterialValueEditor,
  MaterialValueSelector,
} from './MaterialConditions';
const useStyles = makeStyles((theme: Theme) => ({
  addGroup: {
    margin: 20,
    color: 'white',
    borderColor: 'green',
    background: 'green',
    textTransform: 'uppercase',
    borderRadius: '4px',
  },
  addRule: {
    color: 'white',
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,
    textTransform: 'uppercase',
    borderRadius: '4px',
  },
  removeGroup: {
    color: 'red',
    borderColor: 'red',
  },
  removeRule: {
    marginTop: 20,
    color: 'red',
    borderColor: 'red',
  },
}));

const TriggerActivityConditions = ({
  trigger,
  customSave = undefined,
}: {
  trigger: any;
  customSave?: any | undefined;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useState(undefined);
  const { config, adminTriggerApi } = useBlueprintAdmin();
  const [eventValues, setEventValues] = useState<Array<any>>([]);

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  useEffect(() => {
    const fetchData = async () => {
      const resultSet: ResultSet<any> = await cubejsApi.load({
        measures: ['Activity.count'],
        timeDimensions: [
          {
            dimension: 'Activity.sentAt',
          },
        ],
        order: {
          'Activity.count': 'desc',
        },
        filters: [
          {
            member: 'Activity.beaconKey',
            operator: 'equals',
            // this is technically supposed to be the beacon key, but our naming is wrong.
            values: [trigger.beaconId],
          },
        ],
        dimensions: ['Activity.event'],
      });

      const eventOptions: Array<any> = [];
      const values = resultSet.chartPivot();

      for (const item of values) {
        eventOptions.push({
          label: `${item.x} (Occured ${item['Activity.count']} times)`,
          name: item.x,
        });
      }
      setQuery(trigger.match);
      setEventValues(eventOptions);
    };

    fetchData().catch((e) => {
      console.error(e);
    });
  }, []);

  const st: ValueEditorType = 'select';

  const fields = [
    {
      name: 'event',
      label: 'Event',
      valueEditorType: st,
      values: eventValues,
      defaultValue: trigger.match && trigger.match.event,
    },
    {
      name: 'count',
      label: 'Count',
      value: trigger.match && trigger.match.count,
    },
    {
      name: 'url',
      label: 'Context URL',
      value: trigger.match && trigger.match.url,
    },
  ];

  const submitQuery = async () => {
    const response = await adminTriggerApi!.updateTrigger(trigger.id, {
      match: query,
    });
    if (response.success) {
      history.push({
        state: { detail: 'savedTrigger'.concat(uuidv4()) },
      });
    }
  };

  const handleOnQueryChange = (result) => {
    setQuery(result);
    if (customSave) {
      customSave(result);
    }
  };

  const saveAction = () => (
    <Button
      variant='outlined'
      color='primary'
      onClick={() => submitQuery()}
      className='btn btn-primary btn-sm float-right'
    >
      Save
    </Button>
  );

  return (
    <>
      {customSave && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <QueryBuilder
              fields={fields}
              showCombinatorsBetweenRules={true}
              controlClassnames={{
                addGroup: classes.addGroup,
                addRule: classes.addRule,
                removeGroup: classes.removeGroup,
                removeRule: classes.removeRule,
                // combinators: "form-control-m",
                // fields: "form-control form-control-m",
                // operators: "form-control-m",
                // value: "form-control-m",
              }}
              controlElements={{
                addGroupAction: MaterialActionElement,
                addRuleAction: MaterialActionElement,
                combinatorSelector: MaterialValueSelector,
                fieldSelector: MaterialValueSelector,
                notToggle: MaterialNotToggle,
                operatorSelector: MaterialValueSelector,
                removeGroupAction: MaterialActionElement,
                removeRuleAction: MaterialActionElement,
                valueEditor: MaterialValueEditor,
              }}
              query={query}
              onQueryChange={handleOnQueryChange}
            />
          </Grid>
        </Grid>
      )}
      {!customSave && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <AdminCard
              title={
                <>
                  Conditions
                  <AdminToolTip
                    message={
                      <Typography variant='caption'>
                        {
                          'Conditions are the rules that determine whether a trigger will perform a certain action. Conditions can be events, counts, and urls.'
                        }
                      </Typography>
                    }
                  ></AdminToolTip>
                </>
              }
              customAction={saveAction()}
            >
              <QueryBuilder
                fields={fields}
                showCombinatorsBetweenRules={true}
                controlClassnames={{
                  addGroup: classes.addGroup,
                  addRule: classes.addRule,
                  removeGroup: classes.removeGroup,
                  removeRule: classes.removeRule,
                }}
                controlElements={{
                  addGroupAction: MaterialActionElement,
                  addRuleAction: MaterialActionElement,
                  combinatorSelector: MaterialValueSelector,
                  fieldSelector: MaterialValueSelector,
                  notToggle: MaterialNotToggle,
                  operatorSelector: MaterialValueSelector,
                  removeGroupAction: MaterialActionElement,
                  removeRuleAction: MaterialActionElement,
                  valueEditor: MaterialValueEditor,
                }}
                query={query}
                onQueryChange={handleOnQueryChange}
              />
            </AdminCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TriggerActivityConditions;
