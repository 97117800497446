import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
export interface IAdminModal {
  size?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
  isOpen: boolean;
  closeModal: any;
  title: string;
  body: any;
  type?: string;
  fullWidth?: boolean;
  actions?: any;
}
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #777',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogClose: {
    padding: '0',
  },
}));

const AdminModal = ({
  size,
  isOpen = false,
  closeModal,
  title,
  body,
  type,
  fullWidth,
  actions,
}: IAdminModal) => {
  const classes = useStyles();
  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    let style = {};
    switch (size) {
      case 'sm':
        style = {
          top: '50%',
          height: 'auto',
          transform: 'translateY(-50%)',
        };
        break;
      default:
        style = {
          top: `1%`,
          height: `98%`,
        };
        break;
    }
    setModalStyle({
      overflow: 'auto',
      width: '60%',
      margin: 'auto',
      left: '0',
      right: '0',
      ...style,
    });
  }, []);

  return type === 'dialog' ? (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby='admin-modal-dialog-title'
      aria-describedby='admin-modal-dialog-description'
      maxWidth={size}
      fullWidth={fullWidth}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant='h6'>{title}</Typography>
        {closeModal ? (
          <IconButton
            aria-label='close modal'
            onClick={closeModal}
            className={classes.dialogClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  ) : (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby='admin-modal-title'
      aria-describedby='admin-modal-description'
    >
      <div style={modalStyle} className={classes.paper}>
        <Toolbar>
          <Typography variant='h4' style={{ flex: 1 }}>
            {title}
          </Typography>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Exit
          </Button>
        </Toolbar>
        <Container>{body}</Container>
      </div>
    </Modal>
  );
};

export default AdminModal;
