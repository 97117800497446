import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import AdminCard from '../../components/AdminCard';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import ActivityDebuggerChart from './activityDebugger/ActivityDebuggerChart';

const ActivityDebugger = ({ beacon }) => {
  const { config } = useBlueprintAdmin();
  const [refreshDebug, setRefreshDebug] = useState(true);
  const [timeZone, setTimeZone] = useState<any>('Etc/UCT');
  const [timeWindow, setTimeWindow] = useState<any>('Past 30 minutes');
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const timeWindows = [
    {
      label: 'Past 5 minutes',
    },
    {
      label: 'Past 30 minutes',
    },
    {
      label: 'Today',
    },
    {
      label: 'This Week',
    },
    {
      label: 'This Month',
    },
    {
      label: 'This Year',
    },
  ];

  const timeZoneOptions = [
    {
      label: 'UTC',
      value: 'Etc/UCT',
    },
    {
      label: 'Pacific',
      value: 'America/Los_Angeles',
    },
    {
      label: 'Mountain',
      value: 'US/Mountain',
    },
    {
      label: 'Eastern',
      value: 'America/New_York',
    },
  ];

  const ActivityDebuggerPage = () => (
    <Grid container style={{ marginTop: 15 }} spacing={3}>
      <AdminCard>
        <CubeProvider cubejsApi={cubejsApi}>
          <FormControl margin='dense' fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormLabel component='legend' style={{ paddingBottom: 20 }}>
                  Time Range
                </FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel component='legend' style={{ paddingBottom: 20 }}>
                  Time Zone
                </FormLabel>
              </Grid>
            </Grid>
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Select
                  fullWidth
                  variant='outlined'
                  value={timeWindow}
                  onChange={(e) => {
                    const val = e.target.value;
                    setTimeWindow(val);
                  }}
                >
                  {timeWindows.map((item, i) => (
                    <MenuItem key={`${item.label}-${i}`} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Select
                  fullWidth
                  variant='outlined'
                  value={timeZone}
                  onChange={(e) => {
                    const val = e.target.value;
                    setTimeZone(val);
                  }}
                >
                  {timeZoneOptions.map((item, i) => (
                    <MenuItem key={`${item.label}-${i}`} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Box paddingX={3}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    setRefreshDebug(!refreshDebug);
                  }}
                >
                  REFRESH ACTIVITY
                </Button>
              </Box>
            </Grid>
          </FormControl>
          <ActivityDebuggerChart
            title='Activity Debugger'
            beacon={beacon}
            timeWindow={timeWindow}
            timeZone={timeZone}
            refreshWindow={refreshDebug}
          />
        </CubeProvider>
      </AdminCard>
    </Grid>
  );

  return <ActivityDebuggerPage />;
};

export default ActivityDebugger;
