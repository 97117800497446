import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import { Box, Card, Container, Typography } from '@mui/material';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminRegister from '../../components/AdminRegister';
import { toastError, toastSuccess } from '../../config/toast';

const Register = () => {
  const { config, adminApi } = useBlueprintAdmin();

  const api = new AdminScopedAPI(config, 'Register');

  const handleRegister = async (username: string, password: string) => {
    // TODO, come back and add register call instead of direct API call
    await api
      .post({
        path: '/admin/signup',
        body: {
          username: username,
          password: password,
        },
      })
      .then(() => {
        return adminApi!.login({ username, password });
      })
      .then(({ state }) => {
        if (state === 'loggedIn') {
          toastSuccess('You have successfully created an account');
          window.location.replace('/setup');
        }
      })
      .catch((err) => {
        toastError('Something went wrong :(');
        console.error(err);
      });
  };

  return (
    <>
      <Container component='main' maxWidth='sm'>
        <Box m={4}>
          <Card>
            <Box p={4}>
              <Box py={2}>
                <>
                  <Typography
                    variant='h4'
                    component='h1'
                    paragraph
                    align='center'
                  >
                    Create your first admin account
                  </Typography>
                  <Typography variant='body1' gutterBottom align='center'>
                    To begin setup, you must create an admin account. You will
                    be logged in automatically.
                  </Typography>

                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <AdminRegister handleSubmit={handleRegister} />
                  </Box>
                </>
              </Box>
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default Register;
