import { useHistory, useParams } from 'react-router-dom';
import AdminClientBaseForm from '../../../../components/AdminClientBaseForm';

const BenchmarkClientPageNew = () => {
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const onSuccess = (response) => {
    history.push(`/benchmarks/clients/${clientId}`);
  };

  return (
    <AdminClientBaseForm
      url={`/admin/benchmarks/clients/${clientId}/pages`}
      entityTitle={'Client Pages'}
      onSuccess={onSuccess}
      description={undefined}
      breadcrumbs={[
        { href: '/benchmarks/clients', label: 'Benchmarks' },
        { href: '/benchmarks/clients', label: 'Clients' },
        {
          href: `/benchmarks/clients/${clientId}`,
          label: clientId,
          // TODO - Client name not available in scope, need to pull through, using clientId as a fallback
        },
        {
          label: 'New',
        },
      ]}
      adminClientPage={true}
    />
  );
};

export default BenchmarkClientPageNew;
