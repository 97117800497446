import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from 'recharts';

const RadialChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RadialBarChart
        startAngle={90}
        endAngle={-270}
        cx='50%'
        cy='50%'
        innerRadius='100%'
        barSize={10}
        data={data}
      >
        <PolarAngleAxis
          type='number'
          domain={[0, 100]}
          dataKey={'pct'}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          isAnimationActive={false}
          label={{ position: 'center', fill: '#000', fontSize: '1.5rem' }}
          background
          dataKey='pct'
          angleAxisId={0}
          data={data}
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default RadialChart;
