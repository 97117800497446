import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

function AdminClientForm({ description, handleSubmit, formData }): any {
  const { config, adminBenchmarkApi } = useBlueprintAdmin();
  const [industries, setIndustries] = useState<Array<any>>([]);
  const [sectors, setSectors] = useState<Array<any>>([]);
  const [subSectors, setSubSectors] = useState<Array<any>>([]);
  const [advancedFieldsShown, setAdvancedFieldsShown] =
    useState<boolean>(false);

  useEffect(() => {
    adminBenchmarkApi!
      .getIndustries()
      .then(({ data }) => {
        setIndustries(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Please enter a name';
    }
    const url: string = !values.url ? '' : values.url;
    if (!/^(http|https):\//.test(url) && !formData.url) {
      errors.url = 'Please enter a valid URL';
    }

    if (!values.industry) {
      errors.industry = 'Please choose an Industry';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: formData.name ?? '',
      url: formData.url ?? '',
      industry: formData.industry ?? '',
      sector: formData.sector ?? '',
      subSector: formData.subSector ?? '',
      optionalMdr: formData.optionalMdr ?? '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.industry) {
      adminBenchmarkApi!
        .getIndustrySectors(formik.values.industry)
        .then(({ data }) => {
          setSectors(data.sectors);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [formik.values.industry]);

  useEffect(() => {
    if (formik.values.sector) {
      adminBenchmarkApi!
        .getSectorSubSectors(formik.values.sector)
        .then(({ data }) => {
          setSubSectors(data.subSectors);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [formik.values.sector]);

  return (
    <Grid>
      <Typography
        pb={2}
        sx={{ fontWeight: 'medium', color: 'rgba(0,0,0, 0.85)' }}
      >
        {description}
      </Typography>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <TextField
                id='name'
                name='name'
                type='text'
                variant='outlined'
                label='name*'
                autoComplete='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name !== undefined}
                helperText={formik.errors.name}
              />
            </FormControl>
          </Box>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <TextField
                disabled={formData.url}
                id='url'
                name='url'
                type='text'
                variant='outlined'
                label='url*'
                autoComplete='url'
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.errors.url !== undefined}
                helperText={formik.errors.url}
              />
            </FormControl>
            <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
              This is just used to make sure we dont have duplicates, enter it
              in the format of just the root of the domain (e.g.
              https://www.deloitte.com)
            </Typography>
          </Box>
          <Box pb={2}>
            <FormControl margin='dense' fullWidth>
              <InputLabel
                error={formik.errors.industry !== undefined ? true : false}
                id='industry'
              >
                Industry
              </InputLabel>
              <Select
                defaultValue=''
                id='industry'
                name='industry'
                label='industry'
                value={formik.values.industry}
                error={formik.errors.industry !== undefined}
                onChange={formik.handleChange}
              >
                {industries?.map((industry, key) => {
                  return (
                    <MenuItem value={industry.id} key={key}>
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>

              {formik.errors.industry == undefined ? (
                <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
                  Select the industry that this client belongs to
                </Typography>
              ) : (
                <Typography
                  sx={{
                    pl: 2,
                    color: '#d32f2f',
                    fontSize: 12,
                  }}
                >
                  Please select an industry for this client
                </Typography>
              )}
            </FormControl>
          </Box>
          {sectors.length > 0 && (
            <Box pb={2}>
              <FormControl margin='dense' fullWidth>
                <InputLabel id='sector'>Sector</InputLabel>
                <Select
                  defaultValue=''
                  id='sector'
                  name='sector'
                  label='sector'
                  value={formik.values.sector}
                  onChange={formik.handleChange}
                >
                  {sectors?.map((sector, key) => {
                    return (
                      <MenuItem value={sector.id} key={key}>
                        {sector.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
                  (Optional) Select the sector that this client belongs to
                </Typography>
              </FormControl>
            </Box>
          )}
          {subSectors.length > 0 && (
            <Box pb={2}>
              <FormControl margin='dense' fullWidth>
                <InputLabel id='subSector'>Sub Sector</InputLabel>
                <Select
                  defaultValue=''
                  id='subSector'
                  name='subSector'
                  label='subSector'
                  value={formik.values.subSector}
                  onChange={formik.handleChange}
                >
                  {subSectors?.map((subsector, key) => {
                    return (
                      <MenuItem value={subsector.id} key={key}>
                        {subsector?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography sx={{ fontSize: 12, color: 'rgba(0,0,0, 0.54)' }}>
                  (Optional) Select the sub-sector that this client belongs to
                </Typography>
              </FormControl>
            </Box>
          )}
          <Box pb={2}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={false}
                  onChange={(event, checked) => {
                    setAdvancedFieldsShown((value) => !value);
                  }}
                />
              }
              label='Existing Deloitte Engagement'
            />
          </Box>
          {advancedFieldsShown && (
            <Grid>
              <Typography
                pb={2}
                sx={{
                  fontWeight: 'bold',
                  color: 'rgba(0,0,0, 0.85)',
                  fontSize: '24',
                }}
              >
                Advanced
              </Typography>
              <Box pb={2}>
                <FormControl margin='dense' fullWidth>
                  <TextField
                    id='optionalMDR'
                    name='optionalMDR'
                    type='text'
                    variant='outlined'
                    label='Optional MDR'
                    value={formik.values.optionalMdr}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
            </Grid>
          )}
          <Button variant='contained' size='large' id='submit' type='submit'>
            Submit
          </Button>
        </form>
      </Box>
    </Grid>
  );
}

export default AdminClientForm;
