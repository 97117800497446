import { Slider, SliderProps, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

function AdminBenchmarkPerformanceChart({
  clientValue,
  competitorAvg,
  clientLabel,
  avgLabel,
  logo,
  min = 0,
  max = 100,
  variant = 'industry',
}: {
  clientValue: any;
  competitorAvg: any;
  clientLabel?: string;
  avgLabel?: string;
  logo?: string;
  min?: number;
  max?: number;
  variant?: 'industry' | 'competitor';
}) {
  // add +- of 20 to average values to get the orange width in slider
  const minValue = ((competitorAvg - min) / (max - min)) * 100 - 20;
  const maxValue = ((competitorAvg - min) / (max - min)) * 100 + 20;
  const value = ((clientValue - min) / (max - min)) * 100; // thumb
  const marks = [
    {
      value: minValue > min * 0.1 ? minValue : min * 0.1,
      label: (
        <>
          <p>Industry</p>
          <p>Lagging</p>
        </>
      ),
    },
    {
      value: ((competitorAvg - min) / (max - min)) * 100,
      label: (
        <>
          <p>{avgLabel !== undefined ? avgLabel : competitorAvg}</p>
          <p style={{ textTransform: 'capitalize' }}>{variant}</p>
          <p>Avg</p>
        </>
      ),
    },
    {
      value: max * 0.9, // 90-100 is always green
      label: (
        <>
          <p>Industry</p>
          <p>Leading</p>
        </>
      ),
    },
  ];

  const BenchmarkSlider = styled(Slider)<SliderProps>(({ theme }) => ({
    backgroundColor: green[500], // third bar
    height: '10px',
    padding: 0,
    borderRadius: '0px',
    '& .MuiSlider-markLabel': {
      fontSize: '10px !important',
      fontFamily: 'Open Sans',
      textAlign: 'center',
      color: '#000',
      lineHeight: '1.25em',
      left: `${marks[1].value}% !important`,
      '&[data-index="0"]': {
        // third label
        left: '-10% !important',
        top: '-100%',
      },
      '&[data-index="2"]': {
        // third label
        left: '110% !important',
        top: '-100%',
      },
    },
    '& .Mui-disabled': {
      color: '#737373',
    },
    '& .MuiSlider-rail': {
      color: orange[500], // second bar
      opacity: 1,
      width: `${marks[2].value}%`,
      height: '10px',
      borderRadius: '0px',
    },
    '& .MuiSlider-track': {
      width: `${marks[0].value}% !important`,
      height: '10px',
      borderRadius: '0px',
      backgroundColor: red[500], // first bar
    },
    '& .MuiSlider-valueLabel::before': {
      // backgroundColor: 'red',
      bottom: '1px',
    },
    '& .MuiSlider-valueLabel': {
      left: 'auto',
      fontSize: '10px !important',
      fontFamily: 'Open Sans',
      textAlign: 'center',
      height: '35px',
      width: '35px',
      borderRadius: '50%',
      ...(logo
        ? {
            color: 'transparent',
            backgroundImage: `url('${logo}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '32px 32px',
            '& > span > span': {
              color: '#000', // font color for label
              paddingBottom: '60px',
            },
          }
        : {}),
    },
    '& .MuiSlider-thumb': {
      left: `${value <= 100 ? value : 100}% !important`,
      backgroundColor: 'transparent',
      width: 0,
    },
    '& .MuiSlider-mark': {
      height: '100%',
      color: 'transparent !important',
    },
    '& .MuiSlider-markActive': {
      backgroundColor: 'transparent',
      '&[data-index="0"]': {
        backgroundColor: '#000000',
        left: `${((competitorAvg - min) / (max - min)) * 100}% !important`,
      },
    },
    '& .MuiSlider-markLabelActive': {
      '&[data-index="0"]': {
        // first label
        left: '-10% !important',
        top: '-100%',
      },
    },
  }));

  return (
    <>
      <BenchmarkSlider
        defaultValue={((clientValue - min) / (max - min)) * 100}
        marks={marks}
        valueLabelDisplay='on'
        valueLabelFormat={clientLabel ? clientLabel : clientValue}
        disabled
        min={min}
        max={max}
      />
    </>
  );
}

export default AdminBenchmarkPerformanceChart;
