import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';

// @TODO: 02/23/22 Need to test the get api call is working as expected inside of /src/pages/content/collections/list.tsx
function AdminCardList({ url, itemTemplate }: any) {
  const { config } = useBlueprintAdmin();
  const api = new AdminScopedAPI(config, 'AdminCardList');
  const [content, setContent] = useState<any>(null);

  const loadItems = async () => {
    const response = await api.get({ path: url });
    setContent(response.data);
  };

  useEffect(() => {
    loadItems().catch((error) => {
      console.log(error);
    });
  }, []);

  // const { errors } = useFetch({
  //   url,
  //   fetchOnLoad: true,
  //   onSuccess: ({ data }) => setContent(data),
  //   retryOption: true,
  // });

  const renderContent = () => content.map((c) => itemTemplate(c));

  // if (errors) {
  //   return errors;
  // }
  if (!content) {
    return <></>;
  }

  return (
    <Grid container spacing={5}>
      {renderContent()}
    </Grid>
  );
}

export default AdminCardList;
