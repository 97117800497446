import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import ChartRenderer from './ChartRendererTest';
import Dashboard from './Dashboard';
import DashboardItem from './DashboardItem';

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8,
});

const DashboardPage = ({ dashBoard }) => {
  const [loading, setLoading] = useState(true);
  const [tiles, setTiles] = useState(dashBoard ? dashBoard.items : []);
  const [refreshDash, setRefreshDash] = useState(false);
  const { config, adminDashboardApi } = useBlueprintAdmin();

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  useEffect(() => {
    if (!dashBoard) {
      setLoading(true);
    } else {
      setTiles(dashBoard.items);
      setLoading(false);
    }
  }, [dashBoard]);

  useEffect(() => {
    if (refreshDash) {
      setRefreshDash(false);
      adminDashboardApi!
        .getDashboard(dashBoard.id)
        .then(({ data }) => {
          setTiles(data.items);
        })
        .catch((e) => console.error(e));
    }
  }, [refreshDash]);

  if (loading) {
    return <Spin />;
  }

  //   if (error) {
  //     return (
  //       <Alert
  //         message='Error occured while loading your query'
  //         description={error.toString()}
  //         type='error'
  //       />
  //     );
  //   }

  const dashboardItem = (item) => {
    return (
      <div key={item.id} data-grid={defaultLayout(item)}>
        <DashboardItem
          key={item.id}
          itemId={item.id}
          dashboardId={dashBoard?.id}
          title={item.name}
          refresh={setRefreshDash}
        >
          <CubeProvider cubejsApi={cubejsApi}>
            <ChartRenderer vizState={item.vizState} />
          </CubeProvider>
        </DashboardItem>
      </div>
    );
  };

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <h2>There are no charts on this dashboard</h2>
    </div>
  );

  return !tiles || tiles?.length ? (
    <Dashboard dashboardItems={tiles && tiles} dashboardId={dashBoard?.id}>
      {tiles && tiles.map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
