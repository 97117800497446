import { Link as UiLink, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import AdminPage from '../../../components/AdminPage';
import AdminPaginatedList from '../../../components/AdminPaginatedList';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminTableRow } from '../../../components/AdminTable';

const BenchmarkSectorsList = () => {
  const { config, jwtToken } = useBlueprintAdmin();

  return (
    <AdminPage
      animation={true}
      title='Sectors'
      newAction='/benchmarks/sectors/new'
    >
      <AdminPaginatedList
        columns={['name', 'url', 'actions']}
        url='/admin/benchmarks/sectors'
        itemTemplate={itemTemplate}
        filterKeys={[]}
      />
    </AdminPage>
  );
};

const itemTemplate = ({ id, name, url }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/benchmarks/sectors/${id}`}>
        {name}
      </UiLink>
    </TableCell>
    <TableCell>{url}</TableCell>
    <TableCell>
      <UiLink component={Link} to={`/benchmarks/sectors/${id}`}>
        View
      </UiLink>
    </TableCell>
  </AdminTableRow>
);

export default BenchmarkSectorsList;
