import { Container, Link as UiLink } from '@mui/material';

const Page500 = () => (
  <div role='main'>
    <Container>
      <h1>500</h1>
    </Container>
  </div>
);

export default Page500;
