// import AdminClientBaseForm from 'components/AdminClientBaseForm';
import { useHistory } from 'react-router-dom';
import AdminClientBaseForm from '../../../components/AdminClientBaseForm';

const BenchmarkClientNew = () => {
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/benchmarks/clients/${response.data.id}`);
  };

  return (
    <AdminClientBaseForm
      url='/admin/benchmarks/clients/'
      entityTitle='Clients'
      onSuccess={onSuccess}
      breadcrumbs={[
        { href: '/benchmarks/clients', label: 'Benchmarks' },
        { href: '/benchmarks/clients', label: 'Clients' },
        {
          label: 'New Client',
        },
      ]}
      description={`Creating a benchmark client`}
      adminClientPage={false}
    />
  );
};

export default BenchmarkClientNew;
