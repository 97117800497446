import { AdminScopedAPI } from '@deloitte/blueprint-sdk-core';
import MonacoEditor from '@monaco-editor/react';
import MuiForm from '@rjsf/mui';
import { RegistryFieldsType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from './AdminProvider';
import { AdminAssetWidget } from './forms/AdminAssetWidget';
import { AdminMarkdownWidget } from './forms/AdminMarkdownWidget';
import AutoCompleteWidget from './forms/AutoCompleteWidget';

const JSONField = (props) => {
  const handleValue = (value: any) => {
    if (value) {
      try {
        if (typeof value === 'object') {
          return JSON.stringify(value, null, 2);
        } else {
          return JSON.parse(value);
        }
      } catch (error) {
        return value;
      }
    } else {
      return '';
    }
  };

  return (
    <>
      {props && (
        <>
          <label className='form-label'>{props.label}</label>
          <MonacoEditor
            height='300px'
            theme='vs-dark'
            defaultLanguage='json'
            value={handleValue(props.value)}
            onChange={(value, event) => {
              if (value) {
                try {
                  props.onChange(JSON.parse(value));
                } catch (error) {
                  props.onChange(value);
                }
              }
            }}
            options={{
              minimap: {
                enabled: false,
              },
              automaticLayout: true,
            }}
          />
        </>
      )}
    </>
  );
};

const widgets = {
  markdown: AdminMarkdownWidget,
  jsonField: JSONField,
  autoComplete: AutoCompleteWidget,
};
const fields: RegistryFieldsType = {
  asset: AdminAssetWidget,
};

function AdminForm({
  url = undefined,
  schema,
  uiSchema = {},
  dataKey = undefined,
  formData = undefined,
  onSubmit,
  validate = true,
}: any) {
  const { config } = useBlueprintAdmin();
  const [content, setContent] = useState(undefined);
  const api = new AdminScopedAPI(config, 'AdminForm');

  useEffect(() => {
    if (url) {
      api
        .get({
          path: url,
        })
        .then(({ data }) => {
          if (data?.groups) {
            data.groups = data.groups.map((group) => group.id); // change array of group objects to just array of group ids for schema
          }
          dataKey !== undefined ? setContent(data[dataKey]) : setContent(data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  return (
    <>
      {validate && (
        <MuiForm
          schema={schema}
          formData={formData ? formData : content}
          uiSchema={uiSchema}
          onSubmit={onSubmit}
          widgets={widgets}
          fields={fields}
          validator={validator}
        />
      )}
      {!validate && (
        <MuiForm
          schema={schema}
          formData={formData ? formData : content}
          uiSchema={uiSchema}
          onSubmit={onSubmit}
          widgets={widgets}
          fields={fields}
          validator={validator}
          noValidate={true}
        />
      )}
    </>
  );
}

export default AdminForm;
