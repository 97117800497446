import { Container, Link as UiLink } from '@mui/material';

const Page404 = () => (
  <div role='main'>
    <Container>
      <h1>404</h1>
    </Container>
  </div>
);

export default Page404;
