import { useMsal } from '@azure/msal-react';
import { Box, Card, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AdminAPI, LoginResponse } from '@deloitte/blueprint-sdk-admin';

import AdminLogin from '../../components/AdminLogin';
import AdminMFA from '../../components/AdminMFA';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import { clearConfigCache } from '../../config/dynamicConfig';
import { toastError, toastSuccess, toastWarning } from '../../config/toast';
import AdminOktaAuth from './okta';

const Login = () => {
  const { accounts, instance } = useMsal();
  const { mounted, config } = useBlueprintAdmin();
  const [needsMFASetup, setNeedsMFASetup] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [showCodeEntry, setShowCodeEntry] = useState<boolean>(false);

  const adminApi = new AdminAPI({
    apiRoot: config.apiRoot,
    debug: config.debug,
  });

  async function requestAzureAccessToken() {
    const request = {
      scopes: ['email', 'offline_access', 'openid', 'profile', 'User.Read'],
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    try {
      const { accessToken } = await instance.acquireTokenSilent(request);
      /** set deloitte jwt in cookies */
      return accessToken;
    } catch (error) {
      const req = await instance.acquireTokenPopup(request);
      return req.accessToken;
    }
  }

  useEffect(() => {
    const authenticate = async () => {
      if (
        config.authMethod.type === 'provider' ||
        config.authMethod.type === 'azure'
      ) {
        // If we are logged in, then let's proceed to get a token
        if (accounts[0] && accounts[0].idTokenClaims) {
          // Get the accessToken from azure
          const token = await requestAzureAccessToken();
          if (token) {
            const result = await adminApi.providerLogin({
              service: 'azure',
              token: token,
            });
            handleLoginResponse(result);
          } else {
            console.error(accounts[0]);
            console.error(token);
          }
        }
      }
    };

    authenticate().catch((e) => {
      console.error(e);
    });
  }, []);

  const handleLoginResponse = (response) => {
    switch (response.state) {
      case 'loggedIn':
        toastSuccess('You are now logged in');
        const lastPage = localStorage.getItem('bp-timeout-location');
        window.location.replace(lastPage ?? config.startPage);
        localStorage.removeItem('bp-timeout-location');
        break;
      case 'mfaRequired':
        toastWarning('You need to add your phone number to enable MFA');
        setNeedsMFASetup(true);
        break;
      case 'mfaPrompt':
        setShowCodeEntry(true);
        break;
      default:
        toastError('Something went wrong :(');
        break;
    }
  };

  const handleMFA = async (username: string, token: string) => {
    const response = await adminApi.mfaVerify({
      username,
      token,
    });
    handleLoginResponse(response);
  };

  const handleLogin = async (
    username: string,
    password: string,
    phone: string
  ) => {
    setUsername(username);

    clearConfigCache();

    let response: LoginResponse;
    // If we dont think we need MFA, then let's try to login directly
    if (!needsMFASetup) {
      response = await adminApi.login({ username, password });

      handleLoginResponse(response);
    } else {
      // We received a notice we need to enable MFA, so let's do that
      response = await adminApi.mfaEnable({ username, password, phone });
      if (response.state === 'mfaEnabled') {
        setShowCodeEntry(true);
      }
    }
  };

  return (
    <>
      {mounted && (
        <>
          {config.authMethod.type === 'password' && (
            <>
              <Container component='main' maxWidth='sm'>
                <Box m={4} minHeight='100vh'>
                  <Card>
                    <Box p={4}>
                      <Box py={2}>
                        <>
                          {!showCodeEntry && (
                            <>
                              <Typography variant='h4' component='h1' paragraph>
                                Login
                              </Typography>
                              <Typography
                                variant='body1'
                                color='textSecondary'
                                gutterBottom
                              >
                                Please provide your credentials to get started.
                              </Typography>

                              <Box
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                              >
                                <AdminLogin
                                  handleSubmit={handleLogin}
                                  needsMFASetup={needsMFASetup}
                                />
                              </Box>
                            </>
                          )}
                          {showCodeEntry && (
                            <>
                              <Typography variant='h4' component='h1' paragraph>
                                Enter your one time password
                              </Typography>
                              <Typography
                                variant='body1'
                                color='textSecondary'
                                gutterBottom
                              >
                                You will recieve a text message to the provided
                                phone number. Enter that code below to continue.
                              </Typography>
                              <AdminMFA
                                username={username}
                                handleSubmit={handleMFA}
                              />
                            </>
                          )}
                        </>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Container>
            </>
          )}

          {config.authMethod.type === 'okta' && <AdminOktaAuth />}
        </>
      )}
    </>
  );
};

export default Login;
