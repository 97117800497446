import { schema, uiSchema } from '../../../forms/benchmarkClientIndustry';
import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';

const BenchmarkIndustryNew = () => {
  const history = useHistory();

  const onSuccess = (response) => {
    history.push(`/benchmarks/industries/${response.data.id}`);
  };

  return (
    <AdminBaseForm
      url='/admin/benchmarks/industries'
      entityTitle='Industries'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default BenchmarkIndustryNew;
