import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  Chip,
  Grid,
  TableCell,
  Typography,
  Link as UiLink,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import AdminPage from '../../components/AdminPage';
import AdminPaginatedList from '../../components/AdminPaginatedList';
import { AdminTableRow } from '../../components/AdminTable';
import { AdminToastTimed } from '../../components/AdminToasts';
import { uuidv4 } from '../../utils/uuid';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
  sourceText: {
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '.15px',
    marginTop: '5px',
  },
}));

const ConfigBeaconList = () => {
  const [toastController, setToastController]: any = useState(null);
  const classes = useStyles();
  const location: any = useLocation();

  useEffect(() => {
    setToastController(location.state?.detail ?? '');
  }, [location.state?.detail]);

  const configTemplate = (
    { id, debug, name, updatedAt, sourceBeacon, key },
    index
  ) => {
    return (
      <AdminTableRow key={index}>
        <TableCell>
          <UiLink component={Link} to={`/beacons/${id}`}>
            {name}
          </UiLink>
          {sourceBeacon?.name && (
            <Typography variant='body2' className={classes.sourceText}>
              Created from{' '}
              <UiLink component={Link} to={`/beacons/${sourceBeacon.id}`}>
                {sourceBeacon.name}
              </UiLink>
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant='body2'>{key}</Typography>
        </TableCell>
        <TableCell>
          {debug && (
            <Chip color='primary' variant='outlined' label='Development' />
          )}
          {!debug && (
            <Chip color='primary' variant='filled' label='Production' />
          )}
        </TableCell>
        <TableCell>
          <Moment fromNow>{updatedAt}</Moment>
        </TableCell>
        <TableCell>
          <UiLink
            color='primary'
            component={Link}
            to={`/beacons/${id}`}
            style={{ marginLeft: '40px' }}
          >
            <EditOutlinedIcon />
          </UiLink>
        </TableCell>
      </AdminTableRow>
    );
  };

  return (
    <>
      {toastController?.includes('BeaconConverted') && (
        <AdminToastTimed
          message={
            'Beacon successfully Converted, Install the beacon to start collecting data'
          }
          trigger={
            toastController?.includes('BeaconConverted') ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      <AdminPage animation={true} title='Beacons' newAction='/beacons/new'>
        <Grid className={classes.card}>
          <AdminPaginatedList
            columns={[
              'Beacon Name',
              'Beacon Key',
              'Beacon Mode',
              'Last Updated',
              '',
            ]}
            url='/admin/beacons'
            itemTemplate={configTemplate}
          />
        </Grid>
      </AdminPage>
    </>
  );
};

export default ConfigBeaconList;
