import cubejs from '@cubejs-client/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MonacoEditor from '@monaco-editor/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import AdminModal from '../../../components/AdminModal';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import {
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '../../../components/AdminTable';
import AdminChart from '../../../components/reporting/AdminChart';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
  sourceText: {
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '.15px',
  },
}));

const GranularityRangeOptions = [
  {
    label: 'Past 5 minutes',
    value: {
      DateRangeValue: 'from 5 minutes ago to now',
      GranularityValue: 'minute',
    },
  },
  {
    label: 'Past 30 minutes',
    value: {
      DateRangeValue: 'from 30 minutes ago to now',
      GranularityValue: 'minute',
    },
  },
  {
    label: 'Today',
    value: {
      DateRangeValue: 'from 24 hours ago to now',
      GranularityValue: 'hour',
    },
  },
  {
    label: 'This Week',
    value: {
      DateRangeValue: 'from 7 days ago to now',
      GranularityValue: 'day',
    },
  },
  {
    label: 'This Month',
    value: {
      DateRangeValue: 'from 1 month ago to now',
      GranularityValue: 'day',
    },
  },
  {
    label: 'This Year',
    value: {
      DateRangeValue: 'from 1 year ago to now',
      GranularityValue: 'month',
    },
  },
];

const ActivityDebuggerChart = ({
  title,
  timeWindow,
  timeZone,
  beacon,
  refreshWindow,
}) => {
  const { config, adminActivityApi } = useBlueprintAdmin();
  const [resultSet, setResultSet] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [selectedActivity, setSelectedActivity] = useState<any>(undefined);
  const classes = useStyles();
  const rangeOption = GranularityRangeOptions.find((obj) => {
    return obj.label === timeWindow;
  })?.value;
  const [uniqueEventNames, setuniqueEventNames] = useState<any>(undefined);
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = async (id) => {
    if (adminActivityApi) {
      const data = await adminActivityApi.getUserActivityById(id);
      if (data) {
        setSelectedActivity(data.data);
      }
      setIsModalOpen(true);
    }
  };

  const activityModalTemplate = () => (
    <>
      {selectedActivity && (
        <MonacoEditor
          theme='vs-dark'
          defaultLanguage='json'
          value={JSON.stringify(selectedActivity, null, 2)}
          height='100vh'
          width='100%'
        />
      )}
    </>
  );

  const currentTimeUTC = new Date();
  currentTimeUTC.setMinutes(
    currentTimeUTC.getMinutes() + currentTimeUTC.getTimezoneOffset()
  );

  useEffect(() => {
    let isMounted = true;
    cubejsApi
      .load({
        measures: [],
        timeDimensions: [
          {
            dimension: 'Activity.sentAt',
            dateRange: rangeOption?.DateRangeValue || '',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            granularity: rangeOption?.GranularityValue,
          },
        ],
        order: {
          'Activity.sentAt': 'desc',
        },
        filters: [
          {
            member: 'Activity.beaconKey',
            operator: 'equals',
            values: [beacon.key],
          },
        ],
        dimensions: [
          'Activity.sentAt',
          'Activity.event',
          'Activity.type',
          'Activity.hostname',
          'Activity.id',
        ],
        limit: 100,
      })
      .then((data) => {
        if (isMounted) {
          setResultSet(data);
          setuniqueEventNames(
            new Set(data.tablePivot().map((value) => value['Activity.event']))
          );
        }
      })
      .catch((err) => console.error(err));
    return () => {
      isMounted = false;
    };
  }, [refreshWindow]);

  return (
    <>
      {resultSet && uniqueEventNames && (
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Typography variant='h6' style={{ paddingBottom: 20 }}>
              {title}
            </Typography>
            <AdminChart
              title='Activity In The Last Hour'
              showViewDetail={false}
              showCard={false}
              vizState={{
                chartType: 'line',
                legend: false,
                query: {
                  measures: ['Activity.count'],
                  timeDimensions: [
                    {
                      dimension: 'Activity.sentAt',
                      dateRange: rangeOption?.DateRangeValue,
                      granularity: rangeOption?.GranularityValue,
                    },
                  ],
                  filters: [
                    {
                      member: 'Activity.beaconKey',
                      operator: 'equals',
                      values: [beacon.key],
                    },
                  ],
                  dimensions: ['Activity.beaconKey'],
                  timezone: timeZone,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Typography variant='h6' style={{ paddingBottom: 20 }}>
              Events
            </Typography>
            {[...uniqueEventNames].map((val, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography style={{ width: '33%', flexShrink: 0 }}>
                    {val}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                      <Typography style={{ color: 'text.secondary' }}>
                        {
                          resultSet
                            .tablePivot()
                            .filter((row) => row['Activity.event'] === val)
                            .length
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '0px' }}>
                      <Typography
                        variant='body2'
                        className={classes.sourceText}
                      >
                        {'Last Received: '}
                        <Moment from={currentTimeUTC}>
                          {
                            resultSet
                              .tablePivot()
                              .filter(
                                (row) => row['Activity.event'] === val
                              )[0]['Activity.sentAt']
                          }
                        </Moment>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Table size='small'>
                    <AdminTableHead>
                      <TableRow>
                        <AdminTableHeadCell key='header-timestamp'>
                          Timestamp
                        </AdminTableHeadCell>
                        <AdminTableHeadCell key='header-event'>
                          Event
                        </AdminTableHeadCell>
                        <AdminTableHeadCell key='header-type'>
                          Type
                        </AdminTableHeadCell>
                        <AdminTableHeadCell key='header-actions'>
                          Actions
                        </AdminTableHeadCell>
                      </TableRow>
                    </AdminTableHead>
                    <TableBody>
                      {resultSet
                        .tablePivot()
                        .filter((row) => row['Activity.event'] === val)
                        .map((row, index) => (
                          <AdminTableRow key={index}>
                            <TableCell key='Activity.sentAt'>
                              <Moment from={currentTimeUTC}>
                                {row['Activity.sentAt']}
                              </Moment>
                            </TableCell>
                            <TableCell key='Activity.event'>
                              {row['Activity.event']}
                            </TableCell>
                            <TableCell key='Activity.type'>
                              {row['Activity.type']}
                            </TableCell>
                            <TableCell key='actions'>
                              <UiLink
                                key='actions.Link'
                                onClick={() => {
                                  openModal(row['Activity.id']).catch((e) => {
                                    console.error(e);
                                  });
                                }}
                              >
                                View
                              </UiLink>
                            </TableCell>
                          </AdminTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
            <AdminModal
              title='Activity Debug'
              body={activityModalTemplate()}
              isOpen={isModalOpen}
              closeModal={closeModal}
              size='xl'
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ActivityDebuggerChart;
