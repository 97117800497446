import AdminAssetViewer from '../../../../components/AdminAssetViewer';
import RadialChart from '../../../../components/reporting/RadialChart';
import {
  approachabilityScore,
  isAheadOrBehind,
  ownershipScore,
  performanceScore,
} from '../../utils';

const SlideOverviewScore = ({
  clientPages,
  client,
  allClients,
  competitors,
}) => {
  return (
    <section data-auto-animate>
      <div
        className='sl-block'
        data-block-type='text'
        data-name='text-6fcc18'
        style={{
          height: 'auto',
          width: '495.199px',
          left: '80px',
          top: '182.278px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 11 }}>
          <p style={{ textAlign: 'left' }}>
            <strong>
              <span style={{ fontSize: '0.5em' }}>
                We scanned key pages on your website 50 times
              </span>
            </strong>
          </p>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        data-name='group-9b3937'
        style={{ height: 'auto' }}
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{
            transformOrigin: '753.334px 239.911px',
            zIndex: 12,
          }}
        >
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '168.129px',
              left: '669.27px',
              top: '214.411px',
            }}
            data-name='text-a0d109'
          >
            <div className='sl-block-content c' style={{ zIndex: 13 }} dir='ui'>
              <h3 style={{ textAlign: 'left' }}>
                <span style={{ fontSize: '20px' }}>Approachability</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '1120px',
          left: '80px',
          top: '72px',
          height: 'auto',
        }}
        data-name='text-104272'
      >
        <div
          className='sl-block-content'
          style={{ textAlign: 'left', zIndex: 14 }}
        >
          <h3>
            <span style={{ color: 'rgb(0, 0, 0)', fontSize: '39px' }}>
              We benchmark across these pillars to provide a diagnostic view on
              where and how to invest
            </span>
          </h3>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='shape'
        data-name='shape-0df86b'
        style={{
          width: '1280px',
          height: '20px',
          left: '0px',
          top: '0px',
        }}
      >
        <div
          className='sl-block-content'
          data-shape-type='rect'
          data-shape-fill-color='rgb(255, 255, 255)'
          data-shape-stretch='true'
          style={{ zIndex: 10 }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            width='100%'
            height='100%'
            preserveAspectRatio='none'
            viewBox='0 0 1280 20'
          >
            <rect
              width={1280}
              height={20}
              rx={0}
              ry={0}
              className='shape-element'
              fill='rgb(255, 255, 255)'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '436.142px',
          left: '669.27px',
          top: '182.278px',
        }}
        data-name='text-005c9c'
      >
        <div className='sl-block-content' style={{ zIndex: 15 }}>
          <p style={{ textAlign: 'left' }}>
            <strong>
              <span style={{ fontSize: '0.5em' }}>
                These are your scores across the pillars of differentiation
              </span>
            </strong>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='line'
        data-name='line-8ac8df'
        style={{
          width: 'auto',
          height: 'auto',
          minWidth: '0px',
          minHeight: '0px',
          left: '669.27px',
          top: '265.411px',
        }}
      >
        <div
          className='sl-block-content'
          data-line-x1={0}
          data-line-y1={0}
          data-line-x2='356.78200000000004'
          data-line-y2={0}
          data-line-color={approachabilityScore}
          data-line-start-type='none'
          data-line-end-type='none'
          style={{ zIndex: 16 }}
          data-line-width='5px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            preserveAspectRatio='xMidYMid'
            width='356.78200000000004'
            height={1}
            viewBox='0 0 356.78200000000004 1'
          >
            <line
              stroke='rgba(0,0,0,0)'
              strokeWidth={15}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
            <line
              className='line-element'
              stroke={approachabilityScore}
              strokeWidth={5}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        style={{ height: 'auto' }}
        data-name='group-cd9e87'
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{
            transformOrigin: '753.334px 400.411px',
            zIndex: 17,
          }}
        >
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '168.129px',
              left: '669.27px',
              top: '374.911px',
            }}
            data-name='text-10ae33'
          >
            <div className='sl-block-content c' style={{ zIndex: 18 }} dir='ui'>
              <h3 style={{ textAlign: 'left' }}>
                <span style={{ fontSize: '20px' }}>Performance</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='line'
        style={{
          width: 'auto',
          height: 'auto',
          minWidth: '0px',
          minHeight: '0px',
          left: '669.27px',
          top: '425.911px',
        }}
        data-name='line-499c87'
      >
        <div
          className='sl-block-content'
          data-line-x1={0}
          data-line-y1={0}
          data-line-x2='356.78200000000004'
          data-line-y2={0}
          data-line-color={performanceScore}
          data-line-start-type='none'
          data-line-end-type='none'
          style={{ zIndex: 19 }}
          data-line-width='5px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            preserveAspectRatio='xMidYMid'
            width='356.78200000000004'
            height={1}
            viewBox='0 0 356.78200000000004 1'
          >
            <line
              stroke='rgba(0,0,0,0)'
              strokeWidth={15}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
            <line
              className='line-element'
              stroke={performanceScore}
              strokeWidth={5}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        style={{ height: 'auto' }}
        data-name='group-91f822'
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{
            transformOrigin: '753.334px 563.068px',
            zIndex: 20,
          }}
        >
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '168.129px',
              left: '669.27px',
              top: '537.568px',
            }}
            data-name='text-5f1558'
          >
            <div className='sl-block-content c' style={{ zIndex: 21 }} dir='ui'>
              <h3 style={{ textAlign: 'left' }}>
                <span style={{ fontSize: '20px' }}>Ownership</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='line'
        style={{
          width: 'auto',
          height: 'auto',
          minWidth: '0px',
          minHeight: '0px',
          left: '669.27px',
          top: '588.568px',
        }}
        data-name='line-9afc3c'
      >
        <div
          className='sl-block-content'
          data-line-x1={0}
          data-line-y1={0}
          data-line-x2='356.78200000000004'
          data-line-y2={0}
          data-line-color={ownershipScore}
          data-line-start-type='none'
          data-line-end-type='none'
          style={{ zIndex: 22 }}
          data-line-width='5px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            preserveAspectRatio='xMidYMid'
            width='356.78200000000004'
            height={1}
            viewBox='0 0 356.78200000000004 1'
          >
            <line
              stroke='rgba(0,0,0,0)'
              strokeWidth={15}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
            <line
              className='line-element'
              stroke={ownershipScore}
              strokeWidth={5}
              x1='0.5'
              y1='0.5'
              x2='357.28200000000004'
              y2='0.5'
            />
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '495.199px',
          left: '80px',
          top: '520px',
        }}
        data-name='text-ed3cd7'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 23, textAlign: 'left' }}
        >
          <strong>
            <p>
              <span style={{ fontSize: '0.4em' }}>Pages Scanned</span>
            </p>
          </strong>
          {clientPages?.map((page) => (
            <p key={page.id}>
              <span style={{ fontSize: '0.3em' }}>{page.name}</span>
            </p>
          ))}
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '450px',
          left: '80px',
          top: '214.411px',
        }}
        data-name='text-fb9e86'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 24, textAlign: 'left', marginTop: '25px' }}
        >
          {clientPages[0].assets && clientPages[0].assets.screenshot && (
            <AdminAssetViewer
              id={clientPages[0].assets.screenshot}
              clickable={false}
            />
          )}
        </div>
      </div>
      {/* Approachability Score */}
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-8c8046'
        style={{
          width: '61.5385px',
          height: '64px',
          left: '691.616px',
          top: '270.5px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div
          id='Approachability_Score'
          className='sl-block-content'
          style={{ zIndex: 25, width: '110px', height: '110px' }}
        >
          <RadialChart
            data={[
              {
                pct: Math.round(client.avgExperienceScore),
                fill: approachabilityScore,
              },
            ]}
          />
        </div>
      </div>
      {/* Performance Score */}
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-3d809b'
        style={{
          width: '61.7186px',
          height: '62.953px',
          left: '691.616px',
          top: '432.071px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div
          id='Performance_Score'
          className='sl-block-content'
          style={{ zIndex: 26, width: '110px', height: '110px' }}
        >
          <RadialChart
            data={[
              {
                pct: Math.round(client.avgPerformanceScore),
                fill: performanceScore,
              },
            ]}
          />
        </div>
      </div>
      {/* Ownership Score */}
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-09a0de'
        style={{
          width: '61.9029px',
          height: '64.379px',
          left: '691.432px',
          top: '595.621px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div
          id='Ownership_Score'
          className='sl-block-content'
          style={{ zIndex: 27, width: '110px', height: '110px' }}
        >
          <RadialChart
            data={[
              {
                pct: Math.round(client.avgOwnershipScore),
                fill: ownershipScore,
              },
            ]}
          />
        </div>
      </div>
      {/* Approachability text */}
      <div
        className='sl-block'
        data-block-type='text'
        data-name='text-d97550'
        style={{
          height: 'auto',
          width: '369px',
          left: '887.912px',
          top: '288px',
        }}
      >
        <div
          className='sl-block-style'
          style={{ zIndex: 29, transform: 'rotate(0deg)' }}
        >
          <div className='sl-block-content' style={{ zIndex: 29 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.5em' }}>
                You are{' '}
                {isAheadOrBehind(
                  client.avgExperienceScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgExperienceScore
                    : allClients.avgExperienceScore
                )}{' '}
                of your competitors
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Performance text */}
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '374.942px',
          left: '888.092px',
          top: '450.047px',
        }}
        data-name='text-2e2a44'
      >
        <div
          className='sl-block-style'
          style={{ zIndex: 30, transform: 'rotate(0deg)' }}
        >
          <div className='sl-block-content' style={{ zIndex: 30 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.5em' }}>
                You are{' '}
                {isAheadOrBehind(
                  client.avgPerformanceScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgPerformanceScore
                    : allClients.avgPerformanceScore
                )}{' '}
                your competitors
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '369.942px',
          left: '888.092px',
          top: '621px',
        }}
        data-name='text-641eb7'
      >
        <div
          className='sl-block-style'
          style={{ zIndex: 31, transform: 'rotate(0deg)' }}
        >
          <div className='sl-block-content' style={{ zIndex: 31 }}>
            <p style={{ textAlign: 'left' }}>
              <span style={{ fontSize: '0.5em' }}>
                You are{' '}
                {isAheadOrBehind(
                  client.avgOwnershipScore,
                  Object.keys(competitors).length !== 0
                    ? competitors.avgOwnershipScore
                    : allClients.avgOwnershipScore
                )}{' '}
                your competitors
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlideOverviewScore;
