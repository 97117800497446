import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AdminCard from '../../../components/AdminCard';
import AdminModal from '../../../components/AdminModal';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import { AdminTableHead, AdminTableRow } from '../../../components/AdminTable';
import { AdminToolTip } from '../../../components/AdminToolTip';
import ActionsModal from '../../../components/triggers/ActionsModal';

const TriggerActionsTemplate = ({ id }) => {
  const { config, adminTriggerApi } = useBlueprintAdmin();
  const [show, setShow] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string>();
  const [trigger, setTrigger] = useState<any>();

  const getTrigger = async () => {
    await adminTriggerApi!.getTrigger(id).then((data) => {
      if (data.success) setTrigger(data.data);
    });
  };

  useEffect(() => {
    getTrigger().catch((e) => {
      console.error(e);
    });
  }, []);

  const handleClose = () => {
    setShow(false);
    getTrigger().catch((e) => {
      console.error(e);
    });
  };

  const handleShow = (action) => {
    setSelectedAction(action);
    setShow(true);
  };

  const actionsModal = () => (
    <ActionsModal
      id={trigger.id}
      selectedAction={selectedAction}
      onSaved={handleClose}
      currentActions={trigger.actions}
      popUpModal
    />
  );

  const addAction = () => (
    <Button
      variant='outlined'
      color='primary'
      onClick={() => handleShow(undefined)}
      style={{ textDecoration: 'none' }}
    >
      New +
    </Button>
  );

  return (
    <div>
      {trigger && (
        <>
          <AdminModal
            title='Configure Action'
            body={actionsModal()}
            isOpen={show}
            closeModal={handleClose}
            size='xl'
          />
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <AdminCard
                title={
                  <>
                    Actions
                    <AdminToolTip
                      message={
                        <Typography variant='caption'>
                          {
                            'Actions are the steps that will be performed on a web experience when conditions are met. Actions are performed when a trigger is fired.'
                          }
                        </Typography>
                      }
                    ></AdminToolTip>
                  </>
                }
                customAction={addAction()}
              >
                <Table>
                  <AdminTableHead>
                    <TableRow>
                      <TableCell>method</TableCell>
                      <TableCell>id</TableCell>
                      <TableCell>properties</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </AdminTableHead>
                  <TableBody>
                    {trigger.actions &&
                      Object.entries(trigger.actions).map(
                        ([key, value]: any, i) => (
                          <AdminTableRow key={i}>
                            <TableCell>{value.method}</TableCell>
                            <TableCell>{value.id}</TableCell>
                            <TableCell>
                              {JSON.stringify(value.properties)}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  handleShow(value);
                                }}
                                data-testid={`${id}-edit-button`}
                              >
                                <EditOutlinedIcon
                                  style={{ color: '#0070eb' }}
                                />
                              </IconButton>
                            </TableCell>
                          </AdminTableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </AdminCard>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default TriggerActionsTemplate;
