import { Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import AdminCard from '../../../../components/AdminCard';
import AdminForm from '../../../../components/AdminForm';
import AdminPage from '../../../../components/AdminPage';
import { useBlueprintAdmin } from '../../../../components/AdminProvider';
import { uiSchema } from '../../../../forms/benchmarkClient';

const BenchmarkProspectNew = () => {
  const { adminUserApi } = useBlueprintAdmin();
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const schema = {
    description: 'Create prospects connected to this benchmark',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        firstName: { title: 'First Name', type: 'string' },
        lastName: { title: 'Last Name', type: 'string' },
        email: {
          title: 'Email',
          type: 'string',
          description: 'The email address of the requestor of this benchmark',
        },
      },
    },
    required: ['firstName', 'lastName', 'email'],
  };

  const submitAction = async ({ formData }, e) => {
    const data = formData;

    for (const record of data) {
      record.external_id = record.email;
      record.external_source = 'manualEntry';
      record.entityType = 'Client';
      record.entityId = clientId;
    }

    await adminUserApi!.createProspects(data);

    history.push(`/benchmarks/clients/${clientId}`);
  };

  return (
    <AdminPage showSidebar={true} title={'Prospect'}>
      <Grid>
        <AdminCard>
          <AdminForm
            isCreate={true}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={submitAction}
          />
        </AdminCard>
      </Grid>
    </AdminPage>
  );
};

export default BenchmarkProspectNew;
