import { IconButton, Tooltip } from '@mui/material';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

export const AdminToolTip = ({ message }) => {
  return (
    <Tooltip placement='right-start' title={message}>
      <IconButton>
        <InfoOutlined style={{ color: '#0070eb' }} />
      </IconButton>
    </Tooltip>
  );
};
