import cubejs, { ResultSet } from '@cubejs-client/core';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useBlueprintAdmin } from '../../../components/AdminProvider';

const DestinationBlockedEvents = ({ formik, beacon }) => {
  const [blockedEvents, setBlockedEvents] = useState<Array<any>>([]);
  const { config } = useBlueprintAdmin();

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const fetchActivities = async () => {
    const resultSet: ResultSet<any> = await cubejsApi.load({
      measures: ['Activity.count'],
      timeDimensions: [
        {
          dimension: 'Activity.sentAt',
        },
      ],
      order: {
        'Activity.count': 'desc',
      },
      filters: [
        {
          member: 'Activity.beaconKey',
          operator: 'equals',
          values: [beacon.key],
        },
      ],
      dimensions: ['Activity.event'],
    });
    const tempBlockedEvents = resultSet.chartPivot().map((item): any => ({
      label: `${item.x} (Occurred ${item['Activity.count']} times)`,
      value: item.x,
    }));
    if (beacon.key !== undefined) {
      setBlockedEvents(tempBlockedEvents);
    }
  };

  useEffect(() => {
    fetchActivities().catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Box marginBottom={2}>
        <Typography variant='h4' style={{ flex: 1 }}>
          Blocked Events
        </Typography>
      </Box>
      <Box marginBottom={2}>
        <Typography variant='subtitle1'>
          This step is optional. Below is a list of events that were collected
          by this beacon. Check the boxes next to the events that should be
          marked as ‘Blocked’ when sending activity to this destination (e.g.
          credit card information):
        </Typography>
      </Box>
      <Grid container>
        {blockedEvents?.map((event, index) => (
          <Grid item key={index} xs={4}>
            <FormControlLabel
              key={index}
              label={
                event.label.length > 60
                  ? event.label.substring(0, 60)
                  : event.label
              }
              control={
                <Checkbox
                  name='blockedEvents'
                  value={event.value}
                  checked={formik.values.blockedEvents.includes(event.value)}
                  onChange={formik.handleChange}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DestinationBlockedEvents;
