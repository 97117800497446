import { useHistory } from 'react-router-dom';
import AdminBaseForm from '../../../components/AdminBaseForm';

const AdminGroupNew = (formSchema: any = {}) => {
  const history = useHistory();

  const onSuccess = () => {
    history.push({
      pathname: `/admins`,
      state: { detail: 'newAdminGroup' },
    });
  };

  return (
    <AdminBaseForm
      url='/admin/groups'
      entityTitle='Admin Group'
      schema={formSchema.formSchema}
      onSuccess={onSuccess}
    />
  );
};

export default AdminGroupNew;
