import { IPageTrackerConfig, pageSettings } from '@deloitte/blueprint-js';
import { Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormInputRadio } from '../../form-components/FormInputRadio';
import { FormInputText } from '../../form-components/FormInputText';
import {
  beaconadvancedDropDown,
  DefaultBeaconOptions,
} from '../TrackerConstants';

export const setPageTrackerPrevConfig = (savedData, name) => {
  const pageTrackerSettings: IPageTrackerConfig = savedData;
  const form_data_obj: any = {
    ...DefaultBeaconOptions.find((element) => {
      return element.id === name;
    })?.form_data,
  };

  if (pageTrackerSettings.settings) {
    form_data_obj.defaultSelected = 'advancedSettings';
    if (pageTrackerSettings.settings.pageAutoTrackDelay) {
      form_data_obj.pageAutoTrackDelay =
        pageTrackerSettings.settings.pageAutoTrackDelay;
    }
    if (pageTrackerSettings.settings.pageMetaPreferenceEvent) {
      form_data_obj.pageMetaPreferenceEvent =
        pageTrackerSettings.settings.pageMetaPreferenceEvent;
    }
    if (pageTrackerSettings.settings.pageMetaPreferenceEntity) {
      form_data_obj.pageMetaPreferenceEntity =
        pageTrackerSettings.settings.pageMetaPreferenceEntity;
    }
    if (pageTrackerSettings.settings.pageMetaPreferenceEntityId) {
      form_data_obj.pageMetaPreferenceEntityId =
        pageTrackerSettings.settings.pageMetaPreferenceEntityId;
    }
    if (pageTrackerSettings.settings.pageMetaPreferenceProperties) {
      form_data_obj.pageMetaPreferenceProperties =
        pageTrackerSettings.settings.pageMetaPreferenceProperties;
    }
  }
  return form_data_obj;
};

export const userPageSettingFormChange = (form_data) => {
  const setting: pageSettings = {};
  if (form_data.pageAutoTrackDelay) {
    setting.pageAutoTrackDelay = form_data.pageAutoTrackDelay;
  }
  if (form_data.pageMetaPreferenceEvent) {
    setting.pageMetaPreferenceEvent = form_data.pageMetaPreferenceEvent;
  }
  if (form_data.pageMetaPreferenceEntity) {
    setting.pageMetaPreferenceEntity = form_data.pageMetaPreferenceEntity;
  }
  if (form_data.pageMetaPreferenceEntityId) {
    setting.pageMetaPreferenceEntityId = form_data.pageMetaPreferenceEntityId;
  }
  if (form_data.pageMetaPreferenceProperties) {
    setting.pageMetaPreferenceProperties =
      form_data.pageMetaPreferenceProperties;
  }

  return setting;
};

export const setPageOnSubmit = (form_data, updatedData) => {
  form_data.defaultSelected = updatedData.advancedRadio;
  form_data.pageAutoTrackDelay = updatedData.pageAutoTrackDelay;
  form_data.pageMetaPreferenceEvent = updatedData.pageMetaPreferenceEvent;
  form_data.pageMetaPreferenceEntity = updatedData.pageMetaPreferenceEntity;
  form_data.pageMetaPreferenceEntityId = updatedData.pageMetaPreferenceEntityId;
  form_data.pageMetaPreferenceProperties =
    updatedData.pageMetaPreferenceProperties;
};

export const PageTrackerForm = (control, setValue, onSubmit, form_data) => {
  const advancedDropDown = beaconadvancedDropDown;
  function RenderFollowup({ control }) {
    const FollowupSelector = useWatch({
      control,
      name: 'defaultSelected',
      defaultValue: 'defaultSettings',
    });

    if (FollowupSelector === 'advancedSettings') {
      return (
        <>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='pageAutoTrackDelay'
              control={control}
              label='Time (ms): ex 500'
              setValue={setValue}
              defaultValue={form_data.pageAutoTrackDelay}
              submitfunction={onSubmit}
            />
          </Grid>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='pageMetaPreferenceEvent'
              control={control}
              label="ex: 'title'"
              setValue={setValue}
              defaultValue={form_data.pageMetaPreferenceEvent}
              submitfunction={onSubmit}
            />
          </Grid>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='pageMetaPreferenceEntity'
              control={control}
              label="ex: 'entity'"
              setValue={setValue}
              defaultValue={form_data.pageMetaPreferenceEntity}
              submitfunction={onSubmit}
            />
          </Grid>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='pageMetaPreferenceEntityId'
              control={control}
              label="ex: 'entity-id'"
              setValue={setValue}
              defaultValue={form_data.pageMetaPreferenceEntityId}
              submitfunction={onSubmit}
            />
          </Grid>
          <Grid item spacing={1} sm={12}>
            <FormInputText
              name='pageMetaPreferenceProperties'
              control={control}
              label="ex: 'props'"
              setValue={setValue}
              defaultValue={form_data.pageMetaPreferenceProperties}
              submitfunction={onSubmit}
            />
          </Grid>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item spacing={1} sm={12}>
        <FormInputRadio
          name={'defaultSelected'}
          control={control}
          label={''}
          setValue={setValue}
          optionsList={advancedDropDown}
          defaultValue={form_data.defaultSelected}
          submitfunction={onSubmit}
        />
      </Grid>
      <RenderFollowup control={control} />
    </Grid>
  );
};
